<template>
  <div>
    <table class="table table-sm ">
      <thead>
      <tr class="bg-primary text-white">
        <th class="w-40 fw-bold text-center">Day of Week</th>
        <th>Total Devices</th>
        <th>Unique Devices</th>
        <th>Frequency</th>
        <th>{{ homePage }} Visits</th>
        <th>{{ homePage }} Visitation Rate</th>
        <th v-if="comparisonData">Confidence</th>
        <th v-if="productPage">{{ productPage }} Visits</th>
        <th v-if="productPage">{{ productPage }} Visitation Rate</th>
        <th v-if="checkoutPage">{{ checkoutPage }} Visits</th>
        <th v-if="checkoutPage">{{ checkoutPage }} Visitation Rate</th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="dayOfWeek in daysOfWeek">
        <td class="w-40">{{ getDayLabel(dayOfWeek) }}</td>
        <td>{{ attributionData[dayOfWeek].totalDevices.toLocaleString() }}</td>
        <td>{{ attributionData[dayOfWeek].uqDevices.toLocaleString() }}</td>
        <td>{{ getFrequency(dayOfWeek) }}</td>
        <td>{{ getAttributionPageVisits(dayOfWeek, homePage) }}</td>
        <td>{{ getPageVisitationRate(dayOfWeek, homePage) }}</td>
        <td v-if="comparisonData">{{ getConfidenceInterval(dayOfWeek, homePage) }}</td>
        <td v-if="productPage">{{ getAttributionPageVisits(dayOfWeek, productPage) }}</td>
        <td v-if="productPage">{{ getPageVisitationRate(dayOfWeek, productPage) }}</td>
        <td v-if="checkoutPage">{{ getAttributionPageVisits(dayOfWeek, checkoutPage) }}</td>
        <td v-if="checkoutPage">{{ getPageVisitationRate(dayOfWeek, checkoutPage) }}</td>
      </tr>

      </tbody>
    </table>
  </div>
</template>

<script>
import StatsUtil from "@/utils/StatsUtil";

export default {
  name: 'DayOfWeekAttributionData',
  props: ['attributionData', 'homePage', 'productPage', 'checkoutPage', 'comparisonData'],
  data() {
    return {
      model: streetmetrics.model,
      daysOfWeek: [0, 1, 2, 3, 4, 5, 6],
      statsUtil: new StatsUtil()
    }
  },
  mounted() {
  },
  methods: {
    getAttributionPageVisits(dayOfWeek, page) {
      return this.getPageVisits(dayOfWeek, page, this.attributionData)
    },

    getConfidenceInterval(dayOfWeek, page) {
      let exposedVisits = this.getPageVisits(dayOfWeek, page, this.attributionData)
      let controlVisits = this.getPageVisits(dayOfWeek, page, this.comparisonData)
      let controlDevices = this.comparisonData[dayOfWeek].uqDevices
      let exposedDevices = this.attributionData[dayOfWeek].uqDevices
      let confidenceInterval = this.statsUtil.calculateConfidence(controlDevices, exposedDevices, controlVisits, exposedVisits)
      return this.statsUtil.formatConfidenceInterval(confidenceInterval)
    },

    getPageVisitationRate(dayOfWeek, page) {
      const visits = this.getPageVisits(dayOfWeek, page, this.attributionData)
      const dayData = this.attributionData[dayOfWeek]
      const rate = visits / dayData.uqDevices
      return rate.toLocaleString(undefined, {style: 'percent', minimumFractionDigits: 2})
    },

    getFrequency(dayOfWeek) {
      const frequency = this.attributionData[dayOfWeek].totalDevices / this.attributionData[dayOfWeek].uqDevices
      return frequency.toFixed(2);
    },

    getDayLabel(dayOfWeek) {
      return streetmetrics.utils.app.getDayLabel(dayOfWeek)
    },

    formatPercent(number) {
      return (number / 100).toLocaleString(undefined, {style: 'percent', minimumFractionDigits: 2})
    },

    getPageVisits(dayOfWeek, page, dayOfWeekData) {
      console.log('getPageVisits')
      console.log(dayOfWeek)
      console.log(page)
      console.log(dayOfWeekData)
      const pageData = dayOfWeekData[dayOfWeek].pages[page]

      if (pageData) {
        return pageData.visits
      }

      return 0
    },

  }
}
</script>

<style>

</style>
