<template>
  <div class="d-flex justify-content-center">
    <svg :id="chartId" class="chart"></svg>
  </div>
</template>

<script>
import * as d3 from "d3";

export default {
  name: 'BarChart',
  props: {
    chartData: Object,
    chartLabel: String,
    chartId: String,
    chartWidth: {
      type: Number,
      default: 220
    },
    chartHeight: {
      type: Number,
      default: 220
    },
    valueFormat: {
      type: String,
      default: 'percent'
    },
    barColors: {
      type: Array,
      default: ['#1ab5bb', '#062e49']
    }
  },

  data() {
    return {
      model: streetmetrics.model
    }
  },
  mounted() {
      // console.log('Bar mounted')
      // console.log(this.chartWidth)
      // console.log(typeof this.chartWidth)

    const margin = ({top: 0, right: 30, bottom: 20, left: 30})
    const width = this.chartWidth
    const height = this.chartHeight
    let data = this.chartData
    const yScale = d3.scaleLinear()
        .range([height - margin.bottom, margin.top])
        .domain([0, d3.max(data, d => d.count) + 0.5])
        .interpolate(d3.interpolateRound)
    const xScale = d3.scaleBand()
        .range([0, width - margin.left])
        .domain(data.map((d) => d.name))
        .padding(0.35)
    const chart = d3.select('#' + this.chartId)
        .attr("width", width + margin.left + margin.right)
        .attr("height", height + margin.top + margin.bottom)
        .append("g")
        .attr("transform", "translate(" + margin.left + "," + margin.bottom + ")");

    chart.append('g')
        .attr('class', 'grid')
        .attr('transform', `translate(${margin.right}, 0)`)
        .call(d3.axisLeft()
            .ticks(5)
            .scale(yScale)
            .tickSize(-width, 0, 0)
            .tickFormat(''))
        .call(g => g.select("path").remove())
    chart.append('g')
        .attr('transform', `translate(${margin.right}, 0)`)
        .call(d3.axisLeft(yScale).ticks(5).tickFormat((d) => {
          if(this.valueFormat === 'percent') {
            return this.formatPercent(d)
          }

          return d
        }))
        .call(g => g.select(".domain").remove())
    chart.append('g')
        .attr('transform', `translate(${margin.right}, ${height - margin.bottom})`)
        .call(d3.axisBottom(xScale))
        .call(g => g.select(".domain").remove())
    chart.selectAll()
        .data(data)
        .enter()
        .append('rect')
        .attr('fill', (s, i) => {
          console.log('fill, s: ' + s + ', i: ' + i);
          return this.barColors[i]
        })
        .attr('x', (s) => xScale(s.name) + margin.right)
        .attr('y', (s) => yScale(s.count))
        .attr('height', (s) => height - margin.bottom - yScale(s.count))
        .attr('width', xScale.bandwidth())


    const labelOffset = this.valueFormat === 'percent' ? -4 : 10

    data.map(d => {
      chart.append('text')
          .attr('x', xScale(d.name) + margin.left + margin.right + labelOffset)
          .attr('y', yScale(d.count) - 10)
          .attr('text-anchor', 'middle')
          .attr('font-size', 'small')
          .attr('font-weight', 'bold')
          .text(this.valueFormat === 'percent' ? d.count + '%' : d.count)
    })
    chart.append('text')
        .attr('x', -(height / 2))
        .attr('y', -20)
        .attr('transform', 'rotate(-90)')
        .attr('text-anchor', 'middle')
        .attr('font-size', 'x-small')
        .text(this.chartLabel)
  },
  methods: {
    formatPercent(value) {
      if (value === 0) {
        return '0.00%'
      } else if (value.toString().indexOf('.') > -1) {
        return value + '0%'
      }
      return value + '.00%'
    }
  }
}
</script>

<style>

</style>
