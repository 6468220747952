<template>
  <div>
    <loading-spinner :loading="loading"></loading-spinner>
    <router-view></router-view>
    <toast v-if="model.toast.message" :text="model.toast.message"></toast>
  </div>
</template>

<script>
import StateManager from './StateManager';
import LoadingSpinner from './components/LoadingSpinner';
import Toast from './components/Toast';

export default {
  name: 'App',
  components: { Toast, LoadingSpinner },
  data() {
    return {
      model: streetmetrics.model,
    };
  },
  computed: {
    loading() {
      return this.model.loading;
    },
  },
  created() {
    const stateManager = new StateManager(this.model);
    streetmetrics.stateManager = stateManager;
  },
};
</script>
