import RestService from "./RestService";

export default class CampaignService extends RestService {

  /*
  campaignId: 3485
  campaignRef: "Hisey McDermott-232227"
  customerId: 218
  customerName: "Pattison"
   */

  constructor() {
    super('campaigns')
  }

  updateCustomerCampaigns(customerId) {
    const url = this.baseUrl + '/v3/api/campaigns/update-customer-campaigns/' + customerId;
    return this.executeGet(url);
  }

  getActiveCampaigns() {
    const url = this.baseUrl + '/v3/api/campaigns/active-campaigns';
    return this.executeGet(url);
  }

  getCampaignDetails(campaign) {
    const url = this.baseUrl + `/v3/api/campaigns/${campaign.campaignId}/admin-summary/${campaign.customerId}`;
    return this.executeGet(url);
  }
}
