<template>
  <div class="mt-3 pe-3 customer-container">

    <ag-grid-vue v-if="vehicles.length > 0"
                 class="ag-theme-alpine w-100-pct h-100-pct"
                 :sideBar="sideBarOptions"
                 :columnDefs="columnDefinitions"
                 :defaultColDef="defaultColumnDef"
                 :rowData="vehicles"
                 @cell-clicked="onCellClicked"
                 @grid-ready="onGridReady">
    </ag-grid-vue>

    <div v-else class="my-4 alert alert-warning">
      <strong>This customer does not have any vehicles.</strong>
    </div>

  </div>
</template>

<script>
import CustomerService from "../services/CustomerService";
import MediaService from "../services/MediaService";
import { AgGridVue } from "ag-grid-vue3"
import BadgeCellRenderer from "../components/grid/BadgeCellRenderer";
import IconCellRenderer from "../components/grid/IconCellRenderer";
import RestService from "@/services/RestService";

export default {
  name: 'Vehicles',
  props: ['customer', 'viewModel'],
  components: {
    'ag-grid-vue': AgGridVue,
    badgeCellRenderer: BadgeCellRenderer,
    iconCellRenderer: IconCellRenderer
  },
  data() {
    return {
      model: streetmetrics.model,
      vehicles: [],
      overviewData: {
        active: 0,
        inactive: 0,
        providers: 0,
        retired: 0,
        total: 0,
      },
      providers: [],
      owners: [],
      classifications: [],
      customerService: new CustomerService(),
      mediaService: new MediaService(),
      columnDefinitions: [
        { headerName: "Processing", field: "assetStatus", cellRenderer: "badgeCellRenderer" },
        { headerName: "Status", field: "extraStatus", valueGetter: (params) => params.data?.extraStatus ?? 'In Service',
          cellRenderer: "badgeCellRenderer" },
        { headerName: "Paired", field: "paired", cellRenderer: 'iconCellRenderer' },
        { headerName: "ID", field: "assetRef" },
        { headerName: "Display Name", field: "displayName" },
        { headerName: "Type", field: "assetType" },
        { headerName: "Class", field: "classRef" },
        { headerName: "Owner", field: "classRef" },
        { headerName: "GPS Provider", field: "providerName" },
        { headerName: "Group", field: "group" },
      ],
      gridApi: null,
      sideBarOptions: {
        toolPanels: [
          'columns',
          'filters'
        ],
      },
      defaultColumnDef: {
        sortable: true,
        filter: true,
        flex: 1
      }
    }
  },
  mounted() {
    this.loadData()
  },
  methods: {
    onCellClicked(params) {
      console.log('Vehicle grid clicked')
      console.log(params)
      let assetId = params.data.assetRef
      let providerName = params.data.providerName

      let service = new RestService('')
      let url = service.baseUrl + `/v3/api/assets/health-check`
      service.post(url, {assetId: assetId, providerName: providerName}).then( (response) => {
        console.log('Health check')
        console.log(response)
      }).catch((error) => {
        console.log('Error performing health check for vehicle: ' + assetId)
        console.log(error)
      });

    },
    loadData() {
      this.model.loading = true

      Promise.all([
        this.customerService.getVehicles(this.customer.customerId),
        this.customerService.getProviders(this.customer.customerId),
        this.mediaService.getOwners(),
        this.mediaService.getClassifications()]).then((responses) => {
        const vehicleData = responses[0]
        this.vehicles = vehicleData.data.data
        this.overviewData = vehicleData.data.panelData
        this.providers = responses[1].data
        this.owners = responses[2].data
        this.classifications = responses[3].data
        this.model.loading = false
      }).catch((error) => {
        console.log('Error loading vehicles data')
        console.log(error)
        this.model.loading = false
      });


      // this.customerService.getVehicles(this.customer.customerId).then((response) => {
      //   console.log('Vehicles loaded for customer')
      //   console.log(response)
      //   this.model.loading = false
      //   this.vehicles = response.data
      // }).catch((error) => {
      //   console.log('Error loading vehicles for customer')
      //   console.log(error)
      //   this.model.loading = false
      // })
    },

    onGridReady(params) {
      this.gridApi = params.api
      this.gridApi.sizeColumnsToFit()
    }
  }
}
</script>

<style>

</style>
