<template>
  <div class="table-responsive table-container scrollable-container">
    <table class="table">
      <thead class="bg-gray-500 text-uppercase">
      <tr>
        <th>ID</th>
        <th>Status</th>
        <th>Type</th>
        <th>User</th>
        <th>Link</th>
        <th>Date</th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="job in jobs">
        <td>
          {{ job.id }}
        </td>
        <td>
          <div class="d-flex align-items-center my-1">
            <div class="border rounded py-1 px-2 font-xsm" :class="statusClasses(job.status)">
              {{ getStatus(job.status) }}
            </div>
          </div>
        </td>
        <td>
          <div>{{ getJobType(job.jobType) }}</div>
          <div v-if="isDeviceJob(job.jobType) && job.metaData.requestInfo" class="font-sm gray-500">
            <div class="gray-600">
              <strong>{{ getCustomer(job.metaData.requestInfo.customerId) }}</strong>
            </div>
            <div>
              Campaigns:
              <span v-for="(campaignId, index) in job.metaData.requestInfo.campaignIds">
                {{ getCampaign(campaignId) }}<span
                  v-if="index < job.metaData.requestInfo.campaignIds.length - 1">, </span>
              </span>
            </div>
            <div>
              <strong>{{ getDates(job.metaData.requestInfo.mainRange) }}</strong>
            </div>
          </div>
          <div v-else-if="isPixelJob(job.jobType) && job.metaData.requestInfo" class="font-sm gray-500">
            <div v-if="displayAll && job.metaData.requestInfo.customerId" class="gray-600">
              <strong>{{ getCustomer(job.metaData.requestInfo.customerId) }}</strong>
            </div>
            <div v-if="displayAll && job.metaData.requestInfo.campaignId">
              Campaign:
              <span>
                {{ getCampaign(job.metaData.requestInfo.campaignId) }}
              </span>
            </div>
            <div>
              <strong>{{ getPixelDates(job.metaData.requestInfo) }}</strong>
            </div>
          </div>
        </td>
        <td>{{ job.email }}</td>
        <td>
          <div v-if="job.result && job.status === 2">
            <a v-for="fileKey in getDownloadFileKeys(job)" :href="getDownloadUrl(job, fileKey)" target="_blank" class="text-decoration-none">
              <div v-if="getDownloadFileKeys(job).length < 2">
                Download
              </div>
              <div v-else>
                {{fileKey}}
              </div>
              <div class="font-xsm gray-500">
                expires {{ validUntil(job, fileKey) }}
              </div>
            </a>
          </div>
        </td>
        <td>
          <div>{{ getDateLabel(job.createdAt) }}</div>
          <div v-if="isAttributionReport(job.jobType) && job.result && job.status === 2" class="my-2">
            <button class="btn btn-sm btn-primary" @click="onOpenClick(job)">View report</button>
          </div>
          <div v-if="job.result && job.status === 2" class="my-2">
            <button class="btn btn-sm btn-primary" @click="onRefreshClick(job)">Refresh</button>
          </div>
        </td>
      </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import ArrayUtil from "../utils/ArrayUtil";
import JobUtil from "@/utils/JobUtil";
import * as dayjs from "dayjs";
import JobService from "@/services/JobService";

export default {
  name: 'JobsTable',
  props: ['jobs', 'displayAll'],
  data() {
    return {
      model: streetmetrics.model,
      jobUtil: new JobUtil()
    }
  },
  mounted() {
  },
  methods: {
    isAttributionReport(jobType) {
      return jobType === 'JOB_QUEUE__ATTRIBUTION_PROCESS'
    },
    isDeviceJob(jobType) {
      return jobType === 'JOB_QUEUE__DEVICES_UNIQUE' || this.isAttributionReport(jobType)
    },
    isPixelJob(jobType) {
      return jobType === 'JOB_QUEUE__PIXELS_FILTER'
    },
    getJobType(jobType) {
      if (jobType === 'JOB_QUEUE__DEVICES_UNIQUE') {
        return 'Devices'
      } else if (jobType === 'JOB_QUEUE__PIXELS_FILTER') {
        return 'Pixels'
      } else if (jobType === 'JOB_QUEUE__ATTRIBUTION_IMPORT') {
        return 'Attribution Export'
      } else if (jobType === 'JOB_QUEUE__ATTRIBUTION_PROCESS') {
        return 'Attribution Results'
      }

      return 'Unknown (' + jobType + ')'
    },
    getStatus(status) {
      if (status === 'PENDING' || status === 0) {
        return 'Pending'
      } else if (status === 'SUCCESS' || status === 2) {
        return 'Completed'
      } else if (status === 'ERROR' || status === 1) {
        return 'Error'
      }

      return 'Unknown'
    },
    statusClasses(status) {
      if (status === 'SUCCESS' || status === 2) {
        return 'border-success bg-green-100 green-700'
      } else if (status === 'ERROR' || status === 1) {
        return 'border-danger bg-red-100 red-600'
      }

      return 'border-warning bg-yellow-100 yellow-600'
    },
    getDateLabel(dateTime) {
      const dateFormatOptions = {year: "numeric", month: "short", day: "numeric"}
      const jobDate = new Date(Date.parse(dateTime))
      return jobDate.toLocaleDateString('en-us', dateFormatOptions) + ", " + jobDate.toLocaleTimeString()
    },
    getCustomer(customerId) {
      const arrayUtil = new ArrayUtil()
      const customer = arrayUtil.find(this.model.customers, customerId, 'customerId')
      return customer ? customer.customerName : ''
    },
    getCampaign(campaignId) {
      const arrayUtil = new ArrayUtil()
      const campaign = arrayUtil.find(this.model.campaigns, Number(campaignId), 'campaignId')
      return campaign ? campaign.campaignRef : ''
    },
    getDates(range) {
      const dateFormatOptions = {year: "numeric", month: "short", day: "numeric"}
      const start = new Date(range[0] * 1000)
      const end = new Date(range[1] * 1000)
      return start.toLocaleDateString('en-us', dateFormatOptions) + " to " + end.toLocaleDateString('en-us', dateFormatOptions)
    },
    getPixelDates(pixelRequest) {
      const start = pixelRequest.startDate.substring(0, pixelRequest.startDate.indexOf(' '))
      const end = pixelRequest.endDate.substring(0, pixelRequest.endDate.indexOf(' '))
      return start + " to " + end
    },
    getDownloadUrl(job, fileKey) {
      // const files = Object.keys(job.result)
      // return job.result[files[0]]
      return job.result[fileKey]
    },
    validUntil(job, fileKey) {
      return this.jobUtil.getValidUntilLabel(job, fileKey)
    },
    getDownloadFileKeys(job) {
      return Object.keys(job.result)
    },
    onOpenClick(job) {
      this.model.loading = true
      const jobService = new JobService()
      jobService.getJob(job.id).then((response) => {
        const loadedJob = response.data
        this.model.loading = false
        this.model.attributionReportData = loadedJob
        this.$router.push({name: 'attribution-report', params: {id: loadedJob.id}})
      }).catch((error) => {
        console.log('Error loading job for attribution study')
        console.log(error)
        this.model.toast.background = 'bg-orange'
        this.model.toast.message = 'Error loading job for attribution study.'
        this.model.loading = false
      })
    },
    onRefreshClick(job) {
      this.model.loading = true
      const jobService = new JobService()
      jobService.getJob(job.id).then((response) => {
        console.log('Job refreshed')
        console.log(response)
        this.model.loading = false
        console.log('refreshJobsEvent')
        this.$emit('refreshJobsEvent')
      }).catch((error) => {
        console.log('Error loading job')
        console.log(error)
        this.model.toast.background = 'bg-orange'
        this.model.toast.message = 'Error loading job.'
        this.model.loading = false
      })
    },
  }
}
</script>

<style>

</style>
