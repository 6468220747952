<template>
  <div id="providerFormModal" class="modal" tabindex="-1">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-body">

          <div class="d-flex align-items-center p-3">
            <div class="centered-circle flex-shrink-0 bg-secondary text-white h-10 w-10">
              <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  class="feather feather-truck small-icon"
              >
                <rect x="1" y="3" width="15" height="13"></rect>
                <polygon points="16 8 20 8 23 11 23 16 16 16 16 8"></polygon>
                <circle cx="5.5" cy="18.5" r="2.5"></circle>
                <circle cx="18.5" cy="18.5" r="2.5"></circle>
              </svg>
            </div>
            <div class="ms-3">
              <h3 class="fs-5 spacing-close">{{actionLabel}} Provider</h3>
            </div>
          </div>

          <div v-if="errorMessage" class="alert alert-warning mt-1 mb-3" role="alert">
            {{errorMessage}}
          </div>

          <form>
            <div class="mb-3">
              <label for="customerNameInput" class="form-label">Name</label>
              <input v-model="provider.providerName" type="text" class="form-control" id="customerNameInput">
            </div>

            <div class="mb-3">
              <label class="form-label">Customer</label>
              <select class="form-select mb-2" v-model="provider.customerId">
                <option v-for="customer in model.customers" :value="customer.customerId">{{customer.customerName}}</option>
              </select>
            </div>

            <div class="mb-3">
              <label class="form-label">Handler Type</label>
              <select class="form-select mb-2" v-model="provider.providerHandler">
                <option v-for="handlerType in handlerTypes" :value="handlerType">{{handlerType}}</option>
              </select>
            </div>

            <div class="mb-3 form-check">
              <input class="form-check-input" type="checkbox" v-model="provider.isActive">
              <label class="form-check-label">
                Active?
              </label>
            </div>

            <div class="mb-3 form-check">
              <input class="form-check-input" type="checkbox" v-model="provider.isPush">
              <label class="form-check-label">
                Push?
              </label>
            </div>


            <div class="d-flex align-items-center mb-1 font-xbold">
              <div>Provider Config</div>
              <div @click="onAddConfigKeyClick()" class="ms-2 pointer">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                </svg>
              </div>
            </div>

            <div v-for="configKey in configKeys" class="mb-3">

              <div class="d-flex mb-2">
                <div>
                  {{ configKey }}
                </div>
                <div @click="onDeleteConfigClick(configKey)" class="ms-2 pointer">
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0" />
                  </svg>
                </div>
              </div>

              <input v-model="provider.providerConfig[configKey]" type="text" class="form-control">
            </div>

            <div v-if="newConfigKey !== null" class="mb-3">
              <label class="form-label">New Config Field Name</label>
              <input v-model="newConfigKey" type="text" class="form-control" @blur="onFieldAdded">
            </div>

            <div v-if="newConfigKey !== null" class="mb-3">
              <label class="form-label">New Config Field Value</label>
              <input v-model="newConfigValue" type="text" class="form-control">
            </div>


          </form>

        </div>

        <div class="modal-footer">
          <button type="button" class="btn btn-secondary font-sm" style="min-width: 150px;" @click="onSaveClick">Save
          </button>
          <button type="button" class="btn btn-outline-primary font-sm" data-bs-dismiss="modal"
                  style="min-width: 150px;">Cancel
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SelectDropdown from "./SelectDropdown";
import CustomerService from "../services/CustomerService";
import RestService from "@/services/RestService";

export default {
  name: 'ProviderModal',
  components: {SelectDropdown},
  props: {
    yesEvent: Function,
    provider: Object,
    handlerTypes: Array
  },
  data() {
    return {
      model: streetmetrics.model,
      currentModal: null,
      modalElement: null,
      // channelOptions: ["AGENCY", "BIKESHARE", "BUS", "CAR", "ECONOMIC_DEVELOPMENT", "MOBILE_BILLBOARD", "PEDI_CAB",
      //   "RETAIL", "TRUCK_SIDE", "OTHER", "MULTI_CHANNEL"],
      // selectableMarkets: [],
      service: new RestService('providers'),
      errorMessage: null,
      newConfigKey: null,
      newConfigValue: '',
    }
  },
  computed: {
    actionLabel() {
      return this.provider.providerId ? 'Edit' : 'New'
    },

    configKeys() {
      return Object.keys(this.provider.providerConfig);
    },
  },
  mounted() {
    this.modalElement = document.getElementById('providerFormModal')
    this.currentModal = new bootstrap.Modal(this.modalElement)

    this.modalElement.addEventListener('hidden.bs.modal', () => {
      this.$emit('noEvent')
    })

    this.currentModal.show()

    // this.selectableMarkets = this.model.markets.map((item) => {
    //   item.selected = this.customer.customerId ? this.customer.markets.includes(item.marketId) : false
    //   return item
    // })
  },
  beforeDestroy() {
    this.modalElement.removeEventListener('hidden.bs.modal')
  },
  methods: {
    onSaveClick() {
      console.log('Implement onSaveClick')
      console.log(this.provider)

      let request = {
        providerName: this.provider.providerName,
        customerId: this.provider.customerId,
        providerHandler: this.provider.providerHandler,
        isActive: this.provider.isActive,
        isPush: this.provider.isPush,
        providerConfig: this.provider.providerConfig,
      }

      this.model.loading = true
      let promise = this.provider.providerId ? this.service.update(this.provider.providerId, request) : this.service.create(request)

      promise.then((response) => {
        console.log('provider saved')
        console.log(response)
        streetmetrics.stateManager.loadCustomers()
        this.model.loading = false
        this.yesHandler (request)
        this.model.toast.message = 'Provider saved'
      }).catch((error) => {
        console.log('error saving provider')
        console.log(error)
        this.errorMessage = error.message
        this.model.loading = false
      })
    },

    noHandler(e) {
      // hide the modal
      this.currentModal.hide()
    },
    yesHandler(provider) {
      // call the parent's "yesEvent"
      if (this.yesEvent) {
        this.yesEvent(provider)
      }
      // hide the modal
      this.noHandler()
    },

    onDeleteConfigClick(configKey) {
      delete this.provider.providerConfig[configKey]
    },

    onFieldAdded() {
      if(this.newConfigKey) {
        this.provider.providerConfig[this.newConfigKey] = ''
        this.newConfigKey = null
        this.newConfigValue = ''
      }
    },

    onAddConfigKeyClick() {
      this.newConfigKey = ''
    }
  }
}
</script>
