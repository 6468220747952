<template>
  <div class="d-flex align-items-center my-1">
    <div class="border rounded py-1 px-2 font-xsm" :class="badgeClasses">
      {{ cellValue }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'BadgeCellRenderer',
  data() {
    return {
      cellValue: ''
    }
  },
  computed: {
    badgeClasses() {

      if(this.cellValue === 'ACTIVE') {
        return 'border-success bg-green-100 green-700'
      }
      else if(this.cellValue === 'RETIRED') {
        return 'border-danger bg-red-100 red-600'
      }

      return 'bg-gray-100 gray-700'
    }
  },
  beforeMount() {
    // this.params contains the cell & row information and is made available to this component at creation time
    // see ICellRendererParams below for more details
    this.cellValue = this.params.valueFormatted ? this.params.valueFormatted : this.params.value;
  },
  methods: {
    importantMethodHere() {
    }
  }
}
</script>
