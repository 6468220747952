<template>
  <div class="p-4">

    <div class="card w-84 my-2 shadow-sm">
      <div class="card-body">
        <h2 class="card-title mb-3">User Profile</h2>
        <p class="card-text">
          <strong>Company: </strong> {{model.user.company}}
        </p>
        <p class="card-text">
          <strong>Name: </strong> {{model.user.displayName}}
        </p>
        <p class="card-text">
          <strong>Email: </strong> {{model.user.email}}
        </p>
      </div>
    </div>

    <div class="mt-3">
      <router-link :to="{ name: 'dashboard' }" class="btn btn-primary">
        Return to Dashboard
      </router-link>
      <button class="btn btn-secondary mx-2" @click="onLogoutClick">Logout</button>
    </div>

  </div>
</template>

<script>

export default {
  name: 'Profile',

  data () {
    return {
      model: streetmetrics.model,
    }
  },
  methods: {
    onLogoutClick () {
      streetmetrics.stateManager.resetState()
      window.localStorage.setItem('auth.token', null)
      this.$router.push({name: 'sign-in'})
    }
  }
}
</script>
