<template>
  <div class="my-1 py-1 px-2 font-xsm">

    <div class="d-flex align-items-center">

      <div :class="badgeClasses">

        <!-- stop -->
        <svg v-if="isDanger" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
             stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
             class="feather feather-alert-circle small-icon">
          <circle cx="12" cy="12" r="10"></circle>
          <line x1="12" y1="8" x2="12" y2="12"></line>
          <line x1="12" y1="16" x2="12.01" y2="16"></line>
        </svg>

        <!-- check -->
        <svg v-if="isCheck" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
             stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
             class="feather feather-check-circle small-icon">
          <path d="M22 11.08V12a10 10 0 1 1-5.93-9.14"></path>
          <polyline points="22 4 12 14.01 9 11.01"></polyline>
        </svg>

        <!-- triangle -->
        <svg v-if="isWarning" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
             stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
             class="feather feather-alert-triangle small-icon">
          <path d="M10.29 3.86L1.82 18a2 2 0 0 0 1.71 3h16.94a2 2 0 0 0 1.71-3L13.71 3.86a2 2 0 0 0-3.42 0z"></path>
          <line x1="12" y1="9" x2="12" y2="13"></line>
          <line x1="12" y1="17" x2="12.01" y2="17"></line>
        </svg>
      </div>

      <div class="ms-1 font-xsm gray-700">
        {{ cellValue }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'IconCellRenderer',
  data() {
    return {
      cellValue: ''
    }
  },
  computed: {

    isCheck() {
      return this.cellValue === 'CONFIRMED'
    },

    isDanger() {
      return this.cellValue === 'NOT_FOUND'
    },

    isWarning() {
      return this.cellValue === 'NEEDS_ATTENTION'
    },

    badgeClasses() {

      if (this.isCheck) {
        return 'green-500'
      }
      else if (this.isDanger) {
        return 'red-600'
      }
      else if (this.isWarning) {
        return 'yellow-500'
      }

      return 'gray-700'
    }
  },
  beforeMount() {
    // this.params contains the cell & row information and is made available to this component at creation time
    // see ICellRendererParams below for more details
    this.cellValue = this.params.valueFormatted ? this.params.valueFormatted : this.params.value;
  },
  methods: {
    importantMethodHere() {
    }
  }
}
</script>
