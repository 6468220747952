<template>
  <div class="card shadow mt-3 h-100-pct">
    <div class="card-body">
      <h5 class="card-title text-info">Ad Groups</h5>

      <div v-if="adGroups.length > 0" class="table-responsive table-container mt-3">
        <table class="table">
          <thead class="bg-gray-500 text-uppercase">
          <tr>
            <th>Name</th>
            <th>Vehicles</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="adGroup in adGroups">
            <td>
              <div>
                <div class="fw-bold gray-700">{{ adGroup.flightRef }}</div>
                <div class="font-sm gray-500 my-1">{{getDataLabel(adGroup.activeFrom, adGroup.activeTo)}}</div>
                <div class="font-sm gray-500">ID {{adGroup.flightId}}</div>
              </div>

            </td>
            <td>{{ adGroup.assets.length }}</td>
          </tr>
          </tbody>
        </table>

      </div>

      <div v-else class="my-4 alert alert-warning">
        <strong>This campaign does not have any ad group.</strong>
      </div>

    </div>
  </div>
</template>

<script>
import AdGroupService from "../services/AdGroupService";

export default {
  name: 'AdGroupsCard',
  props: ['campaign', 'viewModel'],
  data() {
    return {
      model: streetmetrics.model,
      adGroupService: new AdGroupService(),
      adGroups: []
    }
  },
  mounted() {
    this.loadAdGroups()
  },
  methods: {
    loadAdGroups() {
      this.adGroupService.getCampaignAdGroups(this.campaign.customerId, this.campaign.campaignId).then((response) => {
        this.adGroups = response.data
      }).catch((error) => {
        console.log('Error loading Ad groups for campaign: ' + this.campaign.campaignId)
        console.log(error)
      })
    },
    getDataLabel(activeFrom, activeTo) {
      const start = new Date(Date.parse(activeFrom))
      const end = new Date(Date.parse(activeTo))
      const dateFormatOptions = { year:"numeric", month:"short", day:"numeric"}
      return start.toLocaleDateString('en-us', dateFormatOptions) + ' - ' + end.toLocaleDateString('en-us', dateFormatOptions)
    },
  }
}
</script>

<style>

</style>
