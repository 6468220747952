<template>
  <div class="mb-3">
    <div class="d-flex align-items-center px-2">
      <label class="me-2 form-label flex-grow-0" for="customerId"
        >Select Customer ID:</label
      >
      <select
        class="form-select"
        v-model="selectedCustomerId"
        @change="loadPage"
      >
        <option
          v-for="customer in model.customers"
          :key="customer.customerId"
          :value="customer.customerId"
        >
          {{ customer.customerName }}
        </option>
      </select>
    </div>
    <div v-if="frames.length === 0 && selectedCustomerId !== null" class="py-3">
      <p>This customer either has no frames or no more frames.</p>
    </div>
    <div v-if="frames.length > 0" class="table-responsive table-container mt-2">
      <table class="table">
        <thead class="bg-gray-500 text-uppercase">
          <tr>
            <th>Frame ID</th>
            <th>Frame Ref</th>
            <th>Site ID</th>
            <th>Site Ref</th>
            <th>Site Name</th>
          </tr>
        </thead>
        <tbody>
          <tr
            class="pointer table-row"
            v-for="frame in frames"
            :key="frame.frameId"
            @click="openModal(frame)"
          >
            <td>{{ frame.frameId }}</td>
            <td>{{ frame.frameRef }}</td>
            <td>{{ frame.site.siteId }}</td>
            <td>{{ frame.site.siteRef }}</td>
            <td>{{ frame.site.siteName }}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div v-if="frames.length > 0">
      <button
        class="btn btn-primary mt-2 me-2"
        @click="loadPreviousPage"
        :disabled="loading || offset === 0"
      >
        Previous Page
      </button>
      <button class="btn btn-primary mt-2" @click="loadNextPage" :disabled="loading">
        Next Page
      </button>
    </div>
    <frame-modal
      v-if="isModalVisible"
      :selectedFrame="selectedFrame"
      @noEvent="isModalVisible = false"
    ></frame-modal>
  </div>
</template>

<script>
import FrameService from "@/services/FrameService";
import FrameModal from "@/components/FrameModal.vue";

export default {
  name: "FramesList",
  components: {
    FrameModal,
  },
  data() {
    return {
      model: streetmetrics.model,
      frames: [],
      loading: false,
      limit: 10,
      offset: 0,
      selectedCustomerId: null,
      isModalVisible: false,
      selectedFrame: null,
    };
  },
  mounted() {
    this.loadPage();
  },
  methods: {
    loadPage() {
      this.loading = true;
      const service = new FrameService();
      if (!this.selectedCustomerId) {
        this.loading = false;
        return;
      }
      service
        .list(this.selectedCustomerId, this.limit, this.offset)
        .then((response) => {
          this.frames = response.data;
          this.loading = false;
        })
        .catch((message) => {
          console.log("error loading frames");
          console.log(message);
          this.loading = false;
        });
    },
    loadNextPage() {
      this.offset += this.limit;
      this.loadPage();
    },
    loadPreviousPage() {
      if (this.offset > 0) {
        this.offset -= this.limit;
        this.loadPage();
      }
    },
    openModal(frame) {
      this.selectedFrame = frame;
      this.isModalVisible = true;
    },
    closeModal() {
      this.isModalVisible = false;
    },
    onOkModalClick() {
      this.closeModal();
      alert("Great job, you clicked OK!");
    },
  },
};
</script>

<style scoped>
.form-label {
  flex-basis: 25%;
}

.table-row:hover {
  background-color: lightgray;
}
</style>
