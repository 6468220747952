import RestService from "./RestService";
import http from "superagent";
import AppUtil from "../utils/AppUtil";
import { camelCase } from 'lodash';
import { isString } from "lodash";

export default class MediaUploadService {

  constructor() {
    this.authToken = streetmetrics.model.authToken
    this.http = http
    this.baseUrl = new AppUtil().getBaseUrl()
  }

  uploadStationary(stationaryList, customerId) {
    const url = this.baseUrl + `/v3/api/frames/admin-bulk?customerId=${customerId}`;
    const transformedStationaryList = this.transformStationaryList(stationaryList)
    return new Promise((resolve, reject) => {
      this.http.post(url)
        .send(transformedStationaryList)
        .set('Accept', 'application/json')
        .set('Authorization', 'Bearer ' + this.authToken)
        .end((error, response) => {
          if (response.status === 200 || response.status === 201) {
            resolve(JSON.parse(response.text))
          } else {
            reject(JSON.parse(response.text))
          }
        })
    })
  }

  uploadAssets(uploadType, assetList) {
    const url = this.baseUrl + '/v3/internal/template/' + uploadType

    return new Promise((resolve, reject) => {
      this.http.post(url)
        .send(assetList)
        .set('Accept', 'application/json')
        .set('Authorization', 'Bearer ' + this.authToken)
        .end((error, response) => {

          if (response.status === 200 || response.status === 201) {
            resolve(JSON.parse(response.text))
          } else {
            reject(JSON.parse(response.text))
          }
        })
    })
  }
  
  transformStationaryList(stationaryList) {
    return stationaryList.map((stationary) => {
      let newStationary = {}
      for (let key in stationary) {
        if (isString(stationary[key]) && key !== 'frameRef') {
          newStationary[camelCase(key)] = stationary[key].toLowerCase()
        } else  {
          newStationary[camelCase(key)] = stationary[key];
        }
      }
      return newStationary
    })
  }
}
