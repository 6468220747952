<template>
  <div>
    <apexchart :series="chartData" :options="chartOptions"></apexchart>
  </div>
</template>

<script>
export default {
  name: 'ApexBarChart',
  props: ['chartData', 'axisData'],
  data() {
    return {
      model: streetmetrics.model,
    }
  },
  computed: {
    chartOptions() {
      return {
        chart: {
          type: 'bar',
          height: 350
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: '55%',
            endingShape: 'rounded'
          },
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          show: true,
          width: 2,
          colors: ['transparent']
        },
        xaxis: {
          categories: this.axisData,
        },
        yaxis: {
          title: {
            text: 'Impressions'
          }
        },
      }
    }
  },
  mounted() {
    console.log('ApexBarChart mounted')
    console.log(this.chartData)
    console.log(this.chartOptions)
  },
  methods: {
    importantMethodHere() {
    }
  }
}
</script>

<style>

</style>
