<template>
  <div class="p-4">
    <h2>Media | Asset Upload</h2>

    <div class="my-2">
      <form>
        <div class="mb-3">
          <input
            class="form-control"
            type="file"
            id="formFile"
            @change="onAssetMediaFileSelected"
          />
          <div
            v-if="
              isFileValid && (uploadType === 'asset' || uploadType === 'media')
            "
            class="form-text mt-2 orange-500 font-xbold"
          >
            Upload type: {{ uploadType }} | Total Items:
            {{ assetList.length.toLocaleString() }}
          </div>
        </div>
      </form>
    </div>
  </div>
  <div class="p-4">
    <h2>Stationary Upload</h2>
    <div class="my-2">
      <form>
        <div class="mb-3">
          <input
            class="form-control"
            type="file"
            id="formFile"
            @change="onStationaryFileSelected"
          />
          <div
            v-if="isFileValid && uploadType === 'stationary'"
            class="form-text mt-2 orange-500 font-xbold"
          >
            Upload type: {{ uploadType.toUpperCase() }} | Total Items:
            {{ stationaryList.length.toLocaleString() }}
          </div>
        </div>
      </form>
    </div>
  </div>
  <div class="px-4 pb-4">
    <div v-if="isFileValid" class="mb-3">
      <label class="form-label">Customer</label>
      <select
        class="form-select"
        v-model="selectedCustomerId"
        @change="onCustomerSelected"
      >
        <option
          v-for="customer in model.customers"
          :value="customer.customerId"
        >
          {{ customer.customerName }}
        </option>
      </select>
    </div>

    <div
      v-if="uploadType === 'invalid'"
      class="alert alert-warning mb-3"
      role="alert"
    >
      Uh oh! Looks like that upload is invalid. Please verify you uploaded the a
      file with the correct format.
    </div>

    <div v-if="isFileValid && selectedCustomerId">
      <button
        type="submit"
        class="btn btn-primary btn-wide"
        @click.prevent="onUploadClick"
      >
        Upload
      </button>
    </div>

    <div v-if="errorMessage" class="alert alert-warning my-3" role="alert">
      <h4 class="alert-heading">Error uploading data</h4>
      <hr />
      <p>{{ errorMessage }}</p>
    </div>

    <div v-if="successMessage" class="alert alert-success my-3" role="alert">
      <h4 class="alert-heading">{{ startCase(uploadType) }} upload complete</h4>
      <hr />
      <p>{{ successMessage }}</p>
    </div>
  </div>
</template>

<script>
import StringUtil from "../utils/StringUtil";
import MediaUploadService from "../services/MediaUploadService";

export default {
  name: "MediaUpload",
  data() {
    return {
      // eslint-disable-next-line no-undef
      model: streetmetrics.model,
      uploadType: "",
      selectedCustomerId: null,
      assetList: [],
      stationaryList: [],
      errorMessage: null,
      successMessage: null,
    };
  },
  computed: {
    isFileValid() {
      return (
        this.uploadType === "asset" ||
        this.uploadType === "media" ||
        this.uploadType === "stationary"
      );
    },
  },
  mounted() {},
  methods: {
    uploadStationary() {
      let service = new MediaUploadService();
      return service
        .uploadStationary(this.stationaryList, this.selectedCustomerId)
        .then((response) => {
          console.log("Upload complete: " + this.uploadType);
          console.log(response);
          this.model.loading = false;
          this.successMessage =
            this.stationaryList.length.toLocaleString() +
            " items successfully uploaded.";
        })
        .catch((error) => {
          console.log("Error uploading data: " + this.uploadType);
          console.log(error);
          this.errorMessage = error.message;
          this.model.loading = false;
        });
    },
    uploadMediaAssets() {
      let service = new MediaUploadService();
      return service
        .uploadAssets(this.uploadType, this.assetList)
        .then((response) => {
          console.log("Upload complete: " + this.uploadType);
          console.log(response);
          this.model.loading = false;
          this.successMessage =
            this.assetList.length.toLocaleString() +
            " items successfully uploaded.";
        })
        .catch((error) => {
          console.log("Error uploading data: " + this.uploadType);
          console.log(error);
          this.errorMessage = error.message;
          this.model.loading = false;
        });
    },
    onUploadClick() {
      this.model.loading = true;
      this.resetStateData();
      if (this.uploadType === "stationary") {
        return this.uploadStationary();
      } else {
        return this.uploadMediaAssets();
      }
    },
    onCustomerSelected() {
      let customerId = this.selectedCustomerId;
      if (this.uploadType === "asset" || this.uploadType === "media") {
        this.assetList = this.assetList.map((entry) =>
          Object.assign(entry, { customerId })
        );
      }
    },
    onStationaryFileSelected(event) {
      console.log("onStationaryFileSelected");
      this.model.loading = true;
      this.uploadType = "stationary";
      this.resetStateData();
      let file = event.target.files[0];
      let reader = new FileReader();
      reader.readAsText(file);

      reader.onerror = function () {
        console.log("reader.onerror");
        console.log(reader.error);
        this.model.loading = false;
      };

      reader.onloadend = () => {
        this.stationaryList = this.readCSVData(reader.result);
        this.model.loading = false;
      };
    },
    onAssetMediaFileSelected(event) {
      console.log("onAssetMediaFileSelected");
      this.model.loading = true;
      this.uploadType = "";
      this.resetStateData();
      let file = event.target.files[0];
      let reader = new FileReader();
      reader.readAsText(file);

      reader.onerror = function () {
        console.log("reader.onerror");
        console.log(reader.error);
        this.model.loading = false;
      };

      reader.onloadend = () => {
        this.assetList = this.readCSVData(reader.result);
        this.model.loading = false;
      };
    },
    parseUploadType(headers) {
      if (headers.includes("frame_ref")) {
        this.uploadType = "stationary";
      } else {
        this.uploadType = headers.includes("asset_id")
          ? "asset"
          : headers.includes("media_product")
          ? "media"
          : "invalid";
      }
    },
    readCSVData(data) {
      let stringUtil = new StringUtil();
      const rows = data.split("\n");
      const headers = rows[0]
        .split(",")
        .map((header) => stringUtil.toSnakeCase(header).replace("*", ""));
      this.parseUploadType(headers);
      return rows.slice(1).reduce((output, row) => {
        const values = row.split(",");
        const tRow = headers.reduce(
          (accum, header, index) => ({
            ...accum,
            [header]: values[index]?.replace(/\r?\n|\r/, "") || null,
          }),
          {}
        );
        if (Object.values(tRow).some((val) => (val ? true : false)))
          output.push(tRow);
        return output;
      }, []);
    },
    resetStateData() {
      this.successMessage = null;
      this.errorMessage = null;
    },
  },
};
</script>

<style></style>
