<template>
  <div class="dropdown">
    <button class="btn btn-light dropdown-toggle w-100-pct border text-start" type="button" id="dropdownMenuButton1"
            style="overflow: hidden;"
            data-bs-toggle="dropdown" aria-expanded="false">
      <span class="w-100-pct overflow-hidden">{{buttonLabel}}</span>
    </button>
    <ul class="dropdown-menu w-100-pct" style="height: auto; max-height: 320px; overflow-x: hidden;">
      <li class="px-2 mb-2">
        <input type="text" placeholder="Search" class="form-control" v-model="searchString">
      </li>
      <li v-for="item in visibleItems" class="px-2" :key="item[labelField]">
        <div class="form-check">
          <input class="form-check-input" type="checkbox" v-model="item.selected" @change="emitSelectedItems(item)">
          <label class="form-check-label">
            {{ item[labelField] }}
          </label>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'SelectDropdown',
  props: ['list', 'labelField', 'listTitle', 'singleSelect'],
  data () {
    return {
      searchString: ''
    }
  },
  computed: {
    buttonLabel() {
      if (this.selectedItems.length === 0) {
        return 'Select ' + this.listTitle;
      }
      let selectedLabels = this.selectedItems.map(item => item[this.labelField]).join(', ');
      if (selectedLabels.length > 70) {
        return selectedLabels.substring(0, 70) + '...';
      }
      return selectedLabels;
    },
    selectedItems() {
      return this.list.filter(item => item.selected);
    },
    visibleItems() {
      if (this.searchString.trim().length === 0) {
        return this.list;
      }
      return this.list.filter(item => {
        return item[this.labelField].toLowerCase().includes(this.searchString.trim().toLowerCase());
      });
    }
  },
  methods: {
    emitSelectedItems(item) {
      if(this.singleSelect && item.selected) {
        this.list.map( listItem => listItem.selected = listItem === item)
      }

      this.$emit('update:selectedItems', this.selectedItems);
    }
  }
}
</script>

<style>

</style>
