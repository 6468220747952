<template>

  <div class="my-3 d-flex justify-content-between">
    <div class="d-flex align-items-center">
      <h2 class="">{{currentCustomer.customerName}}</h2>
    </div>

    <div>
      <button class="btn btn-secondary" @click="onEditCustomerClick">Edit Customer</button>
    </div>

    <customer-form-modal v-if="showModal" @noEvent="showModal = false" :customer="customerClone"
      :yes-event="onCustomerSave"></customer-form-modal>
  </div>

</template>

<script>
import CustomerFormModal from "./CustomerFormModal";
import ArrayUtil from "../utils/ArrayUtil";
export default {
  name: 'CustomerTitle',
  components: {CustomerFormModal},
  data () {
    return {
      model: streetmetrics.model,
      customerId: -1,
      showModal: false,
      currentCustomer: {
        customerName: ''
      },
      customerClone: {
        customerId: null,
        customerName: '',
        customerChannel: '',
        markets: [],
      },
      onCustomerSave: () => {}
    }
  },
  mounted () {
    this.customerId = Number(this.$route.params.id)
    let customerList = this.model.customers.filter(customer => (customer.customerId === this.customerId))
    this.currentCustomer = customerList[0]
  },
  methods: {
    onEditCustomerClick() {
      this.customerClone = JSON.parse(JSON.stringify(this.currentCustomer))
      this.showModal = true

      this.onCustomerSave = (customer) => {
        this.currentCustomer = customer

        let arrayUtil = new ArrayUtil()
        arrayUtil.updateItem(this.model.customers, customer, 'customerId')
      }
    }
  }
}
</script>

<style>

</style>

