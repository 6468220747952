<template>
  <div class="p-4">
    <div class="row mb-3">
      <!-- Confirm Modal -->
      <confirm-modal
        key="confirmStatusModal"
        v-if="showStatusConfirmModal"
        @noEvent="showStatusConfirmModal = false"
        :yesEvent="onApproveStatusConfirmed"
        text="Are you sure you want to change the status of this attribution study?"
      >
      </confirm-modal>

      <!-- Edit Modal -->
      <attribution-study-edit-modal
        key="editModal"
        v-if="showEditModal"
        @noEvent="showEditModal = false"
        :yesEvent="handleEditYesEvent"
        :study="selectedAttributionStudy"
        :cuts="cuts"
        :cutOptions="cutOptions"
      >
      </attribution-study-edit-modal>

      <!-- Attribution Studies List -->
      <div class="mb-3 d-flex justify-content-between">
        <div class="d-flex align-items-center">
          <h2>Attribution Studies</h2>
        </div>
        <div class="d-flex align-items-center">
          <button class="btn btn-primary ml-auto" @click="loadStudies">
            Refresh Studies
          </button>
        </div>
      </div>

      <label for="filter-text-box" class="col-sm-2 col-form-label font-md"
        >Quick Filter</label
      >
      <div class="col-sm-10">
        <input
          type="text"
          id="filter-text-box"
          placeholder="Filter..."
          v-model="quickFilter"
          class="form-control"
        />
        <div class="text-muted mt-1">
          Showing all {{ filteredStudies.length }} studies
          <template v-if="quickFilter">
            (filtered from {{ studies.length }} total)
          </template>
        </div>
      </div>

      <!-- Loop through studies and display as cards -->
      <div class="row g-4">
        <div
          class="col-md-4"
          v-for="study in filteredStudies"
          :key="study.attributionId"
        >
          <div class="card shadow border-0" style="height: 27rem; box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;">
            <div class="card-body d-flex flex-column">
              <div class="d-flex justify-content-between align-items-start mb-3">
                <h5 class="card-title text-primary text-truncate m-0" :title="study.studyName">{{ study.studyName }}</h5>
                <div class="d-flex gap-2">
                  <button
                    type="button"
                    class="btn btn-sm btn-outline-secondary"
                    @click="logStudy(study)"
                  >
                    <font-awesome-icon :icon="['fas', 'circle-info']" />
                  </button>
                  <button
                    type="button"
                    class="btn btn-sm btn-outline-secondary"
                    @click="openEditModal(study)"
                  >
                    <font-awesome-icon :icon="['fas', 'pen']" />
                  </button>
                </div>
              </div>

              <div class="mb-3">
                <div class="d-flex justify-content-between mb-2">
                  <span class="text-muted">ID:</span>
                  <span>{{ study.attributionId }}</span>
                </div>

                <div class="d-flex justify-content-between mb-2">
                  <span class="text-muted">Customer:</span>
                  <span>{{ study.customerName }}</span>
                </div>

                <div class="d-flex justify-content-between mb-2">
                  <span class="text-muted">Objective:</span>
                  <span>{{ study.objective }}</span>
                </div>

                <div class="d-flex justify-content-between mb-2">
                  <span class="text-muted">Type:</span>
                  <span>{{ study.studyType }}</span>
                </div>

                <div class="d-flex justify-content-between mb-2">
                  <span class="text-muted">Status:</span>
                  <span class="badge bg-secondary">{{ study.status }}</span>
                </div>

                <div class="d-flex justify-content-between mb-2">
                  <span :class="{ 'text-muted': study.campaignId, 'text-danger': !study.campaignId }">Campaign:</span>
                  <span :class="{ 'text-success': study.campaignId, 'text-danger': !study.campaignId }">{{ study.campaignId ? study.campaignId : 'Not Assigned' }}</span>
                </div>
                
                <div v-if="study.objective === 'Website Visitation Analysis'" class="d-flex justify-content-between mb-2">
                  <span :class="{ 'text-muted': study.pixelCount, 'text-danger': !study.pixelCount }">Pixels:</span>
                  <span :class="{ 'text-success': study.pixelCount, 'text-danger': !study.pixelCount }">{{ study.pixelCount || 0 }}</span>
                </div>
              </div>

              <div class="small text-muted mb-3">
                <div class="mb-1">
                  {{ this.formatDate(study.startDate) }} - {{ this.formatDate(study.endDate) }}
                </div>
                <div class="mb-1">
                  Post Campaign: {{ this.formatDate(study.postStudyEndDate) }}
                </div>
                <div>
                  {{ this.getDaysRemainingLabel(study) }}
                </div>
              </div>

              <div class="d-flex flex-wrap gap-2">
                <button
                  v-if="study.status === 'PENDING' || study.status === 'ATTENTION_REQUIRED'"
                  type="button"
                  class="btn btn-sm btn-outline-success"
                  @click="selectStatus('APPROVED', study)"
                >
                  <font-awesome-icon :icon="['fas', 'thumbs-up']" class="me-1" />
                  Approve
                </button>

                <button
                  v-if="isReadyToRequestNearData(study)"
                  type="button"
                  class="btn btn-sm btn-outline-primary"
                  @click="onRequestNearData(study)"
                >
                  Request Near Data
                </button>

                <button
                  v-else-if="isReadyToCheckNearData(study)"
                  type="button"
                  class="btn btn-sm btn-outline-primary"
                  @click="onRequestNearData(study)"
                >
                  Check Near Data
                </button>

                <button
                  v-else-if="isReadyToRun(study)"
                  type="button"
                  class="btn btn-sm btn-outline-primary"
                  @click="onRunStudyClick(study)"
                  :disabled="study.objective === 'Website Visitation Analysis' && (!study.pixelCount || Number(study.pixelCount) === 0)"
                >
                  <font-awesome-icon :icon="['fas', 'arrows-spin']" class="me-1" />
                  Run Study
                </button>

                <button
                  v-if="isReviewAvailable(study)"
                  type="button"
                  class="btn btn-sm btn-outline-info"
                  @click="openStudyLink(study)"
                >
                  Review
                </button>

                <button
                  v-if="isAttentionRequiredAvailable(study)"
                  type="button"
                  class="btn btn-sm btn-outline-warning"
                  @click="selectStatus('ATTENTION_REQUIRED', study)"
                >
                  <font-awesome-icon :icon="['fas', 'magnifying-glass']" class="me-1" />
                  Attn Req'd
                </button>

                <button
                  v-if="isCompleteAvailableByStatus(study)"
                  type="button"
                  class="btn btn-sm btn-outline-success"
                  @click="selectStatus('COMPLETE', study)"
                >
                  <font-awesome-icon :icon="['fas', 'check']" class="me-1" />
                  Complete
                </button>

                <button
                  v-if="isMidCompleteAvailableByStatus(study)"
                  type="button"
                  class="btn btn-sm btn-outline-success"
                  @click="selectStatus('MID_STUDY_COMPLETE', study)"
                >
                  <font-awesome-icon :icon="['fas', 'check']" class="me-1" />
                  Mid Complete
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import AttributionService from "@/services/AttributionService";
import ConfirmModal from "@/components/ConfirmModal";
import CheckIcon from "@/components/CheckIcon";
import * as dayjs from "dayjs";
import DeviceService from "@/services/DeviceService";
import AppUtil from "@/utils/AppUtil";
import CancelIcon from "@/components/CancelIcon";
import PixelService from "../services/PixelService";
import StepProgress from "@/components/StepProgress";
import AttributionStudyEditModal from "@/components/AttributionStudyEditModal";
import { toRaw } from 'vue';
import _ from 'lodash';

export default {
  name: "Attribution",
  components: {
    CancelIcon,
    CheckIcon,
    ConfirmModal,
    StepProgress,
    AttributionStudyEditModal,
  },
  props: ["prop1", "prop2"],
  data() {
    return {
      quickFilter: "",
      selectedStatus: "PENDING",
      currentStudyStep: 0,
      model: streetmetrics.model,
      service: new AttributionService(),
      pixelService: new PixelService(),
      filteredStudies: [],
      studies: [],
      showConfirmModal: false,
      showEditModal: false,
      onApproveStatusConfirmed: function () {},
      showLocationModalModal: false,
      currentAttributionStudy: null,
      showStatusConfirmModal: false,
      pixels: [],
      selectedAttributionStudy: null,
      attributionStudyStatus: {
        ACTIVE: "ACTIVE",
        PENDING: "PENDING",
        APPROVED: "APPROVED",
        "ATTENTION REQUIRED": "ATTENTION REQUIRED",
        UNDER_REVIEW: "UNDER REVIEW",
        COMPLETE: "COMPLETE",
        "MID STUDY COMPLETE": "MID STUDY COMPLETE",
      },
      selectedStudyDetails: [],
      selectedStudy: null,
      editMode: false,
      cuts: [],
    };
  },
  computed: {
    filteredStudies() {
      if (!this.studies || !this.quickFilter) return this.studies || [];
      // Allow user to search by multiple terms separated by spaces
      const searchTerms = this.quickFilter.toLowerCase().split(' ');
      // Filter studies based on whether each study contains all search terms
      return _.filter(this.studies, (study) => {
        const studyValues = _.values(study).join(' ').toLowerCase();
        return _.every(searchTerms, (term) => studyValues.includes(term));
      });
    },
  },
  mounted() {
    this.loadStudies();
  },
  methods: {
    logStudy(study) {
      console.log('Attribution Study:', toRaw(study));
      this.model.toast.background = "bg-info";
      this.model.toast.message = "Study logged to console";
    },
    openStudyLink(attributionStudy) {
      let url = `https://platform.streetmetrics.com/attribution-manager?tab=0&studyId=${attributionStudy.attributionId}`;
      window.open(url, "_blank");
    },
    handleEditYesEvent(study, deliveryDate, notes, cuts) {
      const promises = [
        this.service.update(study.attributionId, {
          ...study,
          deliveryDate,
          notes,
        }),
        this.service.updateAttributionStudyCuts(study.attributionId, study.customerId, cuts),
      ]
      return Promise.all(promises).then(() => {
        this.loadStudies();
      });
    },
    onOpenClick(attributionStudy) {
      this.onRowClicked(attributionStudy);
    },
    openEditModal(study) {
      this.service
        .listAttributionStudyCuts(study.attributionId, study.customerId)
        .then((response) => {
          this.cuts = response.data;
          this.selectedAttributionStudy = study;
          this.showEditModal = true;
        });
    },
    isReadyToRequestNearData(attributionStudy) {
      const statusReady =
        attributionStudy.status === "APPROVED" &&
        attributionStudy.objective.indexOf("Store") > -1;

      if (statusReady) {
        return attributionStudy.reportData.nearResponse === undefined;
      }

      return false;
    },
    isReadyToCheckNearData(attributionStudy) {
      const statusReady =
        attributionStudy.status === "APPROVED" &&
        attributionStudy.objective.indexOf("Store") > -1;

      if (statusReady) {
        return (
          attributionStudy.reportData.nearResponse !== undefined &&
          attributionStudy.reportData.s3Url === undefined
        );
      }

      return false;
    },

    isReadyToRun(attributionStudy) {
      // const attributionStudy = this.selectedStudy
      const isInStore = attributionStudy.objective.indexOf("Store") > -1;

      if (isInStore) {
        return (
          this.isRunAvailableByStatus(attributionStudy) &&
          attributionStudy.reportData.nearResponse !== undefined &&
          attributionStudy.reportData.s3Url !== undefined
        );
      }

      return this.isRunAvailableByStatus(attributionStudy);
    },

    isReviewAvailable(study) {
      return (
        study.status === "UNDER_REVIEW" || study.status.indexOf("COMPLETE") > -1
      );
    },

    isRunAvailableByStatus(study) {
      return (
        study.status === "APPROVED" || study.status.indexOf("COMPLETE") > -1
      );
    },

    isCompleteAvailableByStatus(study) {
      return study.status === "UNDER_REVIEW" && this.isFinished(study);
    },

    isMidCompleteAvailableByStatus(study) {
      return study.status === "UNDER_REVIEW" && !this.isFinished(study);
    },

    isAttentionRequiredAvailable(study) {
      return (
        study.status === "APPROVED" ||
        study.status === "PROCESSING" ||
        study.status === "UNDER_REVIEW" ||
        study.status.indexOf("COMPLETE") > -1
      );
    },

    onRequestNearData(attributionStudy) {
      if (attributionStudy.reportData.nearResponse) {
        this.onProcessNearDataClick(attributionStudy);
        return;
      }

      this.model.loading = true;

      this.service
        .sendNearAPIRequest(attributionStudy.attributionId)
        .then((response) => {
          if (!response.data.succeeded) {
            this.model.toast.background = "bg-orange";
            this.model.toast.message =
              "There was an error requesting the data from Near.";
            this.model.loading = false;
            return;
          }

            attributionStudy.status = 'PROCESSING';
            this.model.loading = false;
            this.loadStudies();
          })
          .catch((error) => {
            this.model.toast.background = 'bg-orange';
            this.model.toast.message = error.message;
            this.model.loading = false;
          });
    },
    loadStudies() {
      this.model.loading = true;
      this.service
        .list()
        .then((response) => {
          return Promise.all([response, this.model.areCustomersLoaded, this.service.getAvailableCuts(), this.pixelService.getPixelCountsForStudies()]);
        })
        .then(([response, _, cutOptions, pixelCountsResponse]) => {
          this.cutOptions = cutOptions.data || [];
          const pixelCountMap = pixelCountsResponse.data.reduce((acc, item) => {acc.set(item.attributionId, item.pixelCount); return acc;}, new Map())
          response.data.forEach(study => {
            if(!pixelCountMap.has(study.attributionId)){
                return
            }
            study.pixelCount = pixelCountMap.get(study.attributionId);
          })

          this.studies = response.data.sort((a, b) =>
            b.attributionId - a.attributionId 
          );

          this.model.toast.background = "bg-info";
          this.model.toast.message = "Fetching and attaching pixel data to studies...";
          this.setCustomerNamesOnStudies();
          this.model.loading = false;
        })
        .catch((error) => {
          console.log("Error loading attribution studies");
          console.log(error);
          this.model.loading = false;
          this.model.toast.background = "bg-orange";
          this.model.toast.message =
            "There was an error loading attribution studies.";
        });
    },
    onRunStudyClick(study) {
      this.model.toast.message =
      "Kicking off this study...";
      this.service
        .runStudy(study.attributionId)
        .then((response) => {
          this.model.toast.message =
      "Study kicked off successfully!";
          console.log("Running study");
          console.log(response);
          this.loadStudies();
        })
        .catch((message) => {   
          this.model.toast.message =
      "There was an error kicking off this study...";
          console.log("error running study");
          console.log(message);
        });
    },
    selectStatus(newStatus, study) {
      this.selectedStudy = study;
      this.showStatusConfirmModal = true;
      this.onApproveStatusConfirmed = () => {
        if (
          this.selectedStudy.status === "PROCESSING" &&
          newStatus !== "ATTENTION_REQUIRED"
        ) {
          this.model.toast.background = "bg-orange";
          this.model.toast.message =
            "Cannot change status of study that is currently processing.";
          return;
        }
        this.service
          .updateStatus(newStatus, this.selectedStudy.attributionId)
          .then((response) => {
            console.log("Study updated");
            console.log(response);
            this.loadStudies();
          })
          .catch((message) => {
            console.log("error updating study");
            console.log(message);
          });

        this.model.toast.background = "bg-green";
        this.model.toast.message = `Study status changed to ${newStatus}!`;
      };
    },
    setCustomerNamesOnStudies() {
      this.studies.forEach((study) => {
        const foundCustomer = this.model.customers.find(
          (c) => c.customerId === study.customerId
        );
        if (!foundCustomer) return;
        study.customerName = foundCustomer.customerName;
      });
    },
    onProcessNearDataClick(attributionStudy) {
      this.model.loading = true;
      this.service
        .checkNearData(attributionStudy.attributionId)
        .then((response) => {
          this.model.loading = false;
          console.log(
            "Ok... we checked the Near data.  Here is the response..."
          );
          console.log(response);
          const nearResponse = response.data.data;

          if (nearResponse.jobStatus === "COMPLETED") {
            this.model.toast.background = "bg-green";
            this.model.toast.message =
              "The Near data is ready.  You may now run the study.";
          } else {
            this.model.toast.background = "bg-orange";
            this.model.toast.message = "The Near data is not ready yet.";
          }

          this.loadStudies();
        })
        .catch((error) => {
          console.log("Error loading creating attribution study");
          console.log(error);
          this.model.loading = false;
        });
    },
    formatDate(dateValue, withTime = false) {
      if (dateValue.toString().length <= 10) {
        dateValue = +dateValue * 1000;
      }
      const date = new Date(Number(dateValue));
      const formatString = withTime ? "M/D/YY HH:mm:ss" : "MM-DD-YYYY";
      return dayjs(date).format(formatString);
    },

    isFinished(study) {
      const differenceInDays = this.getDaysRemaining(study);
      return differenceInDays < 1;
    },

    getDaysRemaining(study) {
      const date1 = dayjs();
      const date2 = dayjs(study.postStudyEndDate * 1000);
      return date2.diff(date1, "day");
    },

    getDaysRemainingLabel(study) {
      const differenceInDays = this.getDaysRemaining(study);
      return differenceInDays > 0
        ? `${differenceInDays} Days Remaining`
        : "The study period has ended";
    },
  },
  watch: {
  studies: {
    handler() {
      this.filteredStudies; // Forces recomputation
    },
      deep: true,
    },
    quickFilter() {
      this.filteredStudies; // Forces recomputation when filter changes
  },
}
};
</script>
