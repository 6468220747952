<template>
  <div class="pt-4 px-4">

    <div class="mb-3 d-flex justify-content-between">
      <div class="d-flex align-items-center">
        <h2>New Pipeline Job</h2>
      </div>
    </div>

    <div>
      <div class="mb-3">
        <label class="form-label">Market</label>
        <select class="form-select" v-model="job.marketId">
          <option v-for="market in markets" :value="market.marketId">
            {{ market.marketName }}
          </option>
        </select>
      </div>

      <div class="mb-3">
        <label for="startDateInput" class="form-label">Start Date</label>
        <input v-model="job.startDate" type="text" class="form-control" id="startDateInput"
          placeholder="yyyy-mm-dd">
      </div>

      <div class="mb-3">
        <label for="endDateInput" class="form-label">End Date</label>
        <input v-model="job.endDate" type="text" class="form-control" id="endDateInput"
               placeholder="yyyy-mm-dd">
      </div>
    </div>

    <div class="mb-3">
      <label class="form-label">Run Type</label>
      <select class="form-select" v-model="job.runType">
        <option value="STATIONARY">Stationary</option>
        <option value="TRANSIT">Transit</option>
      </select>
    </div>

<!--    <div v-if="job.runType === 'TRANSIT'" class="mb-3">-->
<!--      <label class="form-label">Cooldown Period</label>-->
<!--      <select class="form-select" v-model="job.cooldownInSeconds">-->
<!--        <option value="">None</option>-->
<!--        <option value="15">15 seconds</option>-->
<!--        <option value="60">1 minute</option>-->
<!--        <option :value="15 * 60">15 minutes</option>-->
<!--        <option :value="30 * 60">30 minutes</option>-->
<!--        <option :value="60 * 60">1 hour</option>-->
<!--      </select>-->
<!--    </div>-->

    <div class="mb-3">
      <label class="form-label">Customer (optional)</label>
      <select class="form-select" v-model="job.customerId">
        <option v-for="customer in model.customers" :value="customer.customerId">{{ customer.customerName }}
        </option>
      </select>
    </div>

    <div v-if="job.customerId" class="mb-3">
      <label class="form-label">Campaign (optional)</label>
      <select class="form-select" v-model="job.campaignId">
        <option v-for="campaign in availableCampaigns" :value="campaign.campaignId">{{ campaign.campaignRef }}
        </option>
      </select>
    </div>

    <div class="mb-3">
      <button type="button" class="btn btn-secondary" style="min-width: 150px;" @click="onSaveClick">Save
      </button>

      <router-link :to="{ name: 'pipeline' }" class="btn btn-light ms-2">
        Cancel
      </router-link>
    </div>

  </div>
</template>

<script>
import PipelineService from "@/services/PipelineService";
import AppUtil from "@/utils/AppUtil";
import Stationary from "@/views/pipeline/Stationary.vue";
import dayjs from "dayjs";
import CampaignService from "@/services/CampaignService";
import streetmetrics from "@/model";

export default {
  name: 'PipelineJobForm',
  components: {Stationary},
  props: ['prop1', 'prop2'],
  data () {
    return {
      model: streetmetrics.model,
      service: new PipelineService(),
      campaigns: [],
      job: {
        marketId: null,
        startDate: '',
        endDate: '',
        matchGroup: 'exposed',
        runType: 'STATIONARY',
        customerId: null,
        campaignId: null,
      }
    }
  },
  computed: {
    markets() {
      return this.model.markets.filter(market => (market.metaData && market.metaData.isActive))
    },
    availableCampaigns() {
      if (this.job.customerId) {
        return this.campaigns.filter(campaign => (campaign.customerId === this.job.customerId))
      }

      return []
    },
  },
  mounted () {
    this.loadCampaigns();
  },
  methods: {
    loadCampaigns() {
      this.model.loading = true
      let service = new CampaignService();
      service.list().then((response) => {
        streetmetrics.utils.string.sortByString(response.data, 'campaignRef');
        this.campaigns = response.data;
        this.model.loading = false
      }).catch((error) => {
        console.log('Error loading campaigns');
        console.log(error);
        this.model.loading = false
      });
    },
    onSaveClick () {
      console.log(this.job.marketId)
      const market = this.model.markets.find((market) => market.marketId === this.job.marketId)
      console.log(market)
      // const request = {
      //   market: market.marketHash,
      //   marketId: market.marketId,
      //   startDate: this.job.startDate,
      //   endDate: this.job.endDate,
      //   matchGroup: this.job.matchGroup,
      //   runType: this.job.runType,
      //   cooldownInSeconds: Number(this.job.cooldownInSeconds),
      //   debug: this.job.debug,
      // }

      let dateStrings = [this.job.startDate]

      if(this.job.endDate) {
        dateStrings = streetmetrics.utils.app.getDateStrings(this.job.startDate, this.job.endDate)
      }

      console.log('Date Strings')
      console.log(dateStrings)

      const promises = [];

      for (let i = 0; i < dateStrings.length; i++) {
        const dateString = dateStrings[i];

        const request = {
          market: market.marketHash,
          marketId: market.marketId,
          startDate: dateString,
          matchGroup: this.job.matchGroup,
          runType: this.job.runType,
          runBy: this.model.user.displayName,
          customerId: this.job.customerId,
          campaignId: this.job.campaignId,
        }

        const url = new AppUtil().getPipelineUrl() + '/api/run-market'
        const promise = this.service.post(url, request);
        promises.push(promise);
      }

      Promise.all(promises).then( (response) => {
        this.model.toast.background = 'bg-green';
        this.model.toast.message = response.message;
        this.$router.push({name: 'pipeline'});
      }).catch((error) => {
        this.model.toast.background = 'bg-red';
        this.model.toast.message =
            'There was an error trying to run this job. Please try again later.';
      });
    }
  }
}
</script>
