<template>
  <div class="m-3 pe-3">

    <div class="mt-4">
      <h2>Active Markets ({{markets.length}})</h2>
    </div>

    <div v-if="markets.length > 0" class="table-responsive table-container scrollable-container mt-2">
      <table class="table">
        <thead class="bg-gray-500 text-uppercase">
        <tr>
          <th>ID</th>
          <th>Name</th>
          <th>Population</th>
          <th>Hash</th>
          <th></th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="market in markets">
          <td>{{market.marketId}}</td>
          <td>{{ market.marketName }}</td>
          <td>{{ market.marketHash }}</td>
          <td>{{ Number(market.marketMetrics.marketpop).toLocaleString() }}</td>
          <td>
            <a href="javascript:void(0)" @click="onMarketClick(market)" class="pointer">Edit GeoJSON</a>
          </td>
        </tr>
        </tbody>
      </table>
    </div>

    <market-modal v-if="currentMarket && showMarketModal"
                  @noEvent="showMarketModal = false"
                  :market="currentMarket"></market-modal>
  </div>
</template>

<script>

import MarketFilesCount from "../components/MarketFilesCount";
import MarketModal from "@/components/MarketModal";
import AppUtil from "@/utils/AppUtil";
import streetmetrics from "@/model";

export default {
  name: 'Markets',
  components: {MarketModal, MarketFilesCount},
  data() {
    return {
      // eslint-disable-next-line no-undef
      model: streetmetrics.model,
      showMarketModal: false,
      currentMarket: null
    }
  },
  computed: {
    markets() {
      const marketList = this.model.markets.filter(market => (market.metaData && market.metaData.isActive))
      streetmetrics.utils.market.sortByPopulation(marketList)
      return marketList
    },

    // beforeNoon() {
    //   return new Date().getHours() < 12
    // }
  },
  mounted() {
    // const service = new MarketService()
    // service.loadS3Files()]
    console.log(new AppUtil().getImportantMarkets())
  },

  methods: {
    onMarketClick(market) {
      this.currentMarket = market
      this.showMarketModal = true
    }
  }
}
</script>

<style>

</style>
