<template>
  <span>
    {{formatDateTime}}
  </span>
</template>

<script>
export default {
  name: 'FormattedDateTime',
  props: ['dateTime'],
  computed: {
    formatDateTime () {
      const date = new Date(Date.parse(this.dateTime))
      return date.toLocaleDateString() + ', ' + date.toLocaleTimeString()
    }
  }
}
</script>

<style>

</style>
