import RestService from './RestService';

export default class DeviceService extends RestService {
  constructor() {
    super('devices');
  }

  search(startDate, endDate, customerId, campaignList, email) {
    let queryString = `?email=${email}&deviceGroup=EXPOSED&customerId=${customerId}`;

    if(startDate && endDate) {
      queryString = `?email=${email}&mainRange=${startDate}&mainRange=${endDate}&deviceGroup=EXPOSED&customerId=${customerId}`;
    }

    for (let i = 0; i < campaignList.length; i++) {
      let campaignId = campaignList[i];
      queryString += `&campaignIds=${campaignId}`;
    }

    const url = this.baseUrl + '/v3/api/devices/unique-admin' + queryString;
    return this.executeGet(url);
  }

  createAttributionStudy(
    startDate,
    endDate,
    customerId,
    campaignId,
    email,
    pixelStartDate,
    pixelEndDate,
    studyId,
    cuts,
    timezone,
  ) {
    let queryString = `?email=${email}&mainRange=${startDate}&mainRange=${endDate}&deviceGroup=EXPOSED&customerId=${customerId}`;
    queryString += `&campaignIds=${campaignId}`;
    queryString += `&startDate=${pixelStartDate}&endDate=${pixelEndDate}`;
    queryString += `&timezone=${timezone}`;
    (cuts || []).forEach((cut) => (queryString += `&cuts=${cut}`));
    const url =
      this.baseUrl +
      '/v3/api/attribution/process-study/' +
      studyId +
      queryString;
    return this.executeGet(url);
  }
}
