<template>
  <div class="m-3 pe-3">
    <div class="mt-4 d-flex align-items-center">
      <div>
        <h2>Providers</h2>
      </div>

      <div class="ms-4 form-check">
        <input class="form-check-input" type="checkbox" v-model="showInactive">
        <label class="form-check-label">
          Show Inactive?
        </label>
      </div>

      <div class="ms-auto">
        <button class="btn btn-secondary" @click="onNewProviderClick">New Provider</button>
      </div>
    </div>

    <div
        v-if="providers.length > 0"
        class="table-responsive table-container scrollable-container mt-2"
    >
      <table class="table">
        <thead class="bg-gray-500 text-uppercase">
        <tr>
          <th>ID</th>
          <th>Name</th>
          <th>Handler</th>
          <th>Customer</th>
          <th>Last Process Time</th>
          <th v-if="isDeveloper">Is Locked</th>
          <th v-if="isDeveloper">Lock Exp</th>
          <th v-if="isDeveloper">Is Unlocked Recommended</th>
          <th v-if="isDeveloper"></th>
          <th>Type</th>
          <th>Config</th>
          <th></th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="provider in providerList" :class="getRowClass(provider)">
          <td>{{ provider.providerId }}</td>
          <td @click="onProviderClick(provider)" class="pointer">{{ provider.providerName }}</td>
          <td>{{ provider.providerHandler }}</td>
          <td>{{ getCustomerName(provider) }}</td>
          <td class="text-nowrap">
            {{ formatDate(provider.lastProcessTimestamp) }}
          </td>
          <td v-if="isDeveloper">{{ provider.isLocked }}</td>
          <td v-if="isDeveloper">
            {{
              !!provider.lockExp && provider.lockExp > 0
                  ? formatDate(provider.lockExp * 1000)
                  : provider.lockExp
            }}
          </td>
          <td v-if="isDeveloper">{{ isUnlockRecommended(provider) }}</td>
          <td v-if="isDeveloper">
            <button @click="configureUnlock(provider)">Unlock</button>
          </td>
          <td>
            <span v-if="provider.isPush">push</span>
            <span v-else>pull or s3</span>
          </td>
          <td>
            <div v-for="configKey in getConfigKeys(provider)" class="w-48 text-truncate">
              <strong>{{ configKey }}:</strong>
              {{ provider.providerConfig[configKey] }}
            </div>
          </td>
          <td>
            <button @click="onEditProvider(provider)" class="btn btn-sm btn-light">Edit</button>
            <button @click="onCopyProvider(provider)" class="btn btn-sm btn-light ms-2">Copy</button>
            <button @click="onProviderClick(provider)" class="btn btn-sm btn-light ms-2">Pings</button>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
    <div v-else>No providers found.</div>
  </div>

  <vue-final-modal
      classes="d-flex align-items-center justify-content-center"
      content-class="d-flex flex-column bg-white my-3 px-5 py-4 rounded-3"
      v-model="showPingsModal">

    <h2>Latest Pings for {{ pingProvider.providerName }}</h2>

    <div class="table-container my-3">
      <table class="table">
        <thead class="bg-gray-500 text-uppercase">
        <tr>
          <th>Date</th>
          <th>Pings</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="ping in pingList">
          <td>{{ ping['PING_DATE'] }}</td>
          <td>{{ ping['PINGS'].toLocaleString() }}</td>
        </tr>
        </tbody>
      </table>
    </div>

    <div class="d-flex justify-content-end">
      <div>
        <button class="btn btn-primary w-20" @click="closePingModal()">
          ok
        </button>
      </div>
    </div>
  </vue-final-modal>

  <confirm-modal
      key="confirmAttributionModal"
      v-if="shouldShowUnlockModal"
      @noEvent="shouldShowUnlockModal = false"
      :yesEvent="unlockProvider"
      :text="`Are you sure you want to unlock this provider ${providerToUnlockId}?`"></confirm-modal>

  <provider-modal v-if="showModal" @noEvent="showModal = false" :provider="currentProvider"
                  :yes-event="onProviderSave" :handler-types="handlerTypes"></provider-modal>
</template>

<script>
import RestService from '@/services/RestService';
import AppUtil from '@/utils/AppUtil';
import ArrayUtil from '@/utils/ArrayUtil';
import * as dayjs from 'dayjs';
import {DateTime} from 'luxon';
import ConfirmModal from "@/components/ConfirmModal";
import ProviderModal from "@/components/ProviderModal";

export default {
  name: 'Providers',
  components: {ProviderModal, ConfirmModal},
  data() {
    return {
      // eslint-disable-next-line no-undef
      model: streetmetrics.model,
      providers: [],
      locks: [],
      providerToUnlockId: null,
      shouldShowUnlockModal: false,
      providersService: new RestService('providers'),
      showModal: false,
      showPingsModal: false,
      showInactive: false,
      handlerTypes: [],
      pingProvider: {
        providerName: ''
      },
      pingList: [],
      currentProvider: {
        providerId: null,
        providerName: '',
        customerId: '',
        providerHandler: '',
        providerConfig: null,
        isActive: false,
        isPush: false,
      },
    };
  },
  computed: {
    isDeveloper() {
      return this.model.isLockMaintainer();
    },
    providerList() {
      if (this.showInactive) {
        return this.providers
      }

      return this.providers.filter((provider) => provider.isActive);
    },
  },
  mounted() {
    this.getProvidersAndLocks();
  },
  methods: {
    configureUnlock(provider) {
      this.providerToUnlockId = provider.providerId;
      this.shouldShowUnlockModal = true;
    },
    getProvidersAndLocks() {
      const handlerTypes = {}
      const url = new AppUtil().getBaseUrl() + '/v3/api/providers/locks';
      this.model.loading = true

      this.providersService
          .executeGet(url)
          .then((response) => {
            const providersAndLocks = response.data;

            const flattened = providersAndLocks.map((providerAndLock) => {
              handlerTypes[providerAndLock.provider.providerHandler] = true

              return {
                lockExp: providerAndLock.lockExp,
                isLocked: providerAndLock.isLocked,
                ...providerAndLock.provider,
              };
            });
            this.providers = flattened;
            this.handlerTypes = ['Custom'].concat(Object.keys(handlerTypes))
            this.model.loading = false
          })
          .catch((message) => {
            console.log('error loading locks');
            console.log(message);
            this.model.loading = false
          });
    },
    isUnlockRecommended(provider) {
      const lastProcessTimestamp = provider.lastProcessTimestamp;
      if (!lastProcessTimestamp || !provider.isLocked) {
        return false;
      }

      const utcDateTime = DateTime.fromISO(provider.lastProcessTimestamp, {zone: 'utc'});
      const lastProcessTimestampEpochSeconds = utcDateTime.toSeconds();

      if (
          lastProcessTimestamp + 30 < provider.lockExp ||
          (provider.lockExp === -1 &&
              lastProcessTimestampEpochSeconds + 30 < DateTime.now().toSeconds())
      ) {
        return true;
      }

      return false;
    },
    unlockProvider() {
      const url =
          new AppUtil().getBaseUrl() + `/v3/api/providers/unlock/${this.providerToUnlockId}`;
      this.providersService
          .post(url, null)
          .then((response) => {
            this.getProvidersAndLocks();
          })
          .catch((message) => {
            console.log('error unlocking provider');
            console.log(message);
          });
    },
    getCustomerName(provider) {
      const arrayUtil = new ArrayUtil();
      const customer = arrayUtil.find(this.model.customers, provider.customerId, 'customerId');
      return customer ? customer.customerName : '';
    },
    getConfigKeys(provider) {
      return Object.keys(provider.providerConfig);
    },
    formatDate(dateValue) {
      if (!dateValue) {
        return null;
      }
      return dayjs(dateValue).format('MM/DD/YYYY h:mm A');
    },

    onProviderSave() {
      this.getProvidersAndLocks()
    },

    onEditProvider(provider) {
      this.currentProvider = JSON.parse(JSON.stringify(provider))
      this.showModal = true
    },

    onCopyProvider(provider) {
      this.currentProvider = JSON.parse(JSON.stringify(provider))
      this.currentProvider.providerId = null
      this.currentProvider.providerName = provider.providerName + ' copy'
      this.showModal = true
    },

    getRowClass(provider) {
      if (!provider.isActive) {
        return 'table-danger'
      }

      return provider.lastProcessTimestamp ? '' : 'table-warning'
    },

    onNewProviderClick() {
      this.currentProvider = {
        providerId: null,
        providerName: '',
        customerId: '',
        providerHandler: '',
        providerConfig: {
          url: ''
        },
        isActive: false,
        isPush: false,
      }
      this.showModal = true
    },

    onProviderClick(provider) {
      this.model.loading = true
      this.pingProvider = provider
      const url = new AppUtil().getBaseUrl() + `/v3/api/providers/latest-pings/${provider.providerName}?t=${Date.now()}`;
      this.providersService.executeGet(url).then((response) => {
        console.log('Got some pings for provider')
        console.log(response.data)
        this.pingList = response.data
        this.showPingsModal = true
        this.model.loading = false
      }).catch((message) => {
        console.log('error loading pings');
        console.log(message);
        this.model.loading = false
      });
    },

    closePingModal() {
      this.showPingsModal = false;
    },
  },
};
</script>
