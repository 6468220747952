let streetmetrics = {
  model: {
    authToken: null,
    user: {
      company: null,
      displayName: null,
      email: null,
    },
    customers: [],
    users: [],
    markets: [],
    campaigns: [],
    jobs: [],
    loading: false,
    toast: {
      message: null,
      background: 'bg-green',
    },
    currentAttributionStudy: null,
    attributionReportData: {
      customerId: null,
      email: null,
      id: null,
      jobType: null,
      metaData: {
        requestInfo: null,
      },
      result: {},
      status: null,
      userId: null,
    },
    isLockMaintainer() {
      return streetmetrics.model.user.email ? streetmetrics.model.user.email === 'tyler@streetmetrics.io' : false
    },
    isDeveloper() {
      if (streetmetrics.model.user.email) {
        const developers = [
          'harris@nimblelabs.com',
          'harris@streetmetrics.com',
          'tyler@streetmetrics.io',
          'tyler@streetmetrics.com',
          'ryan@streetmetrics.com',
          'josh@streetmetrics.com',
          'andre@streetmetrics.io',
        ];

        return developers.includes(streetmetrics.model.user.email);
      }

      return false;
    },
  },
  stateManager: null,
  router: null,
  utils: {
    app: null,
    chart: null,
    string: null,
    array: null,
    market: null
  },
  eventDispatcher: null
};

module.exports = streetmetrics;
