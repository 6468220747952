<template>
  <div class="d-flex justify-content-between my-2" v-if="job && job.audits">
    <div class="card w-100-pct">
      <div
        v-for="audit in job.audits"
        :key="audit.auditId"
        class="card-body">
        <div class="fw-bold">{{ audit.jobType }}</div>
        <span v-if="allJobsSuccessful(audit)" class="badge rounded-pill bg-green-500 text-white">
          Success
        </span>
        <span v-else class="badge rounded-pill bg-red-500 text-white important">
          Failure
        </span>
        <div v-if="audit.jobStatus" class="status">
          <div v-for="key in Object.keys(audit.jobStatus)" :key="key" class="d-flex justify-content-between gray-600 font-sm">
            <span class="status-label">{{ startCase(key) }}:</span>
            <span class="status-value">{{ audit.jobStatus[key] }}</span>
          </div>
        </div>
        <div class="created-at">Created At: {{ formatDate(audit.createdAt, 'iso') }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { startCase } from 'lodash';
import { DateTime } from 'luxon';

export default {
  name: 'JobDetails',
  props: ['job'],
  computed: {},
  data() {
    return {
      shouldShowAuditModal: false,
      model: streetmetrics.model,
    };
  },
  mounted() {},
  methods: {
    openAuditModal(job, audit) {
      this.shouldShowAuditModal = true;
    },
    allJobsSuccessful(audit) {
      if(audit && audit.jobStatus) {
        return Object.values(audit.jobStatus).every((status) => !!status);
      }

      return false
    },
    startCase(str) {
      return startCase(str);
    },
    formatDate(dateTime, type) {
      let jobDate;
      switch (type) {
        case 'iso':
          jobDate = DateTime.fromISO(dateTime);
          break;
        case 'epoch':
          jobDate = DateTime.fromSeconds(dateTime);
          break;
      }
      if (!jobDate) return;
      return jobDate.toFormat('MM-dd-yyyy HH:mm:ss a');
    },
  },
};
</script>

<style scoped>
.audit-card {
  border: 1px solid #ccc;
  padding: 1rem;
  margin-bottom: 1rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 0.5rem;
}

.status {
  margin-top: 0.5rem;
}

.status-item {
  display: flex;
  align-items: center;
  margin-top: 0.25rem;
  font-size: 0.75rem;
  color: #666;
}

/*.status-label {*/
/*  width: 100px;*/
/*}*/

/*.status-value {*/
/*  flex: 1;*/
/*}*/

.created-at {
  margin-top: 0.5rem;
  font-size: 0.75rem;
  color: #888;
}

.status-item {
  font-size: 0.875rem;
  color: #666;
  margin-top: 0.25rem;
}
</style>
