<template>
  <div id="jobsByMarketModal" class="modal fade" tabindex="-1">
    <div class="modal-dialog modal-xl">
      <div class="modal-content">
        <div class="modal-body">

          <div class="d-flex align-items-center p-3">
            <div class="centered-circle flex-shrink-0 bg-secondary text-primary h-10 w-10">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                   stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                   class="feather feather-clock small-icon">
                <circle cx="12" cy="12" r="10"></circle>
                <polyline points="12 6 12 12 16 14"></polyline>
              </svg>
            </div>
            <div class="ms-3">
              <h3 class="fs-5 spacing-close">Jobs for {{ market.marketName }}</h3>
            </div>
          </div>

          <div class="d-flex flex-wrap my-2">
            <div v-for="jobDate in jobDates" class="card shadow-sm m-2" style="width: 300px;">
              <div class="card-body">
                <p class="card-text">{{ jobDate }}</p>
                <job-details :job="jobsByDate[jobDate]"></job-details>
              </div>
            </div>
          </div>


<!--          <div v-if="jobs.length > 0" class="table-responsive table-container">-->
<!--            <table class="table">-->
<!--              <thead class="bg-gray-500 text-uppercase">-->
<!--              <tr>-->
<!--                <th>ID</th>-->
<!--                <th>Job Type</th>-->
<!--                <th>Success</th>-->
<!--                <th>Start</th>-->
<!--                <th>End</th>-->
<!--              </tr>-->
<!--              </thead>-->
<!--              <tbody>-->
<!--              <tr v-for="job in jobs">-->
<!--                <td>-->
<!--                  {{ job.jobId }}-->
<!--                </td>-->
<!--                <td>-->
<!--                  <span v-if="hasFirstJobAudit(job) && job.audits[0].jobType">{{ job.audits[0].jobType }}</span>-->
<!--                </td>-->
<!--                <td>-->
<!--            <span v-if="hasFirstJobAudit(job) && job.audits[0].jobStatus">{{-->
<!--                job.audits[0].jobStatus.jobSuccess-->
<!--              }}</span>-->
<!--                  <span v-else>&#45;&#45;</span>-->
<!--                </td>-->
<!--                <td>-->
<!--                  <div v-if="isMetricsPresent(job)">{{ getDateLabel(job.audits[0].jobConfig.startTime) }}</div>-->
<!--                </td>-->
<!--                <td>-->
<!--                  <div v-if="isMetricsPresent(job)">{{ getDateLabel(job.audits[0].jobConfig.endTime) }}</div>-->
<!--                </td>-->
<!--              </tr>-->
<!--              </tbody>-->
<!--            </table>-->
<!--          </div>-->

        </div>

        <div class="modal-footer">
          <button type="button" class="btn btn-outline-primary font-sm" data-bs-dismiss="modal"
                  style="min-width: 150px;">Ok
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import JobService from "@/services/JobService";
import * as dayjs from "dayjs";
import JobDetails from "@/views/jobs/JobDetails";

export default {
  name: 'JobsByMarketModal',
  components: {JobDetails},
  props: {
    market: Object,
  },
  data() {
    return {
      model: streetmetrics.model,
      currentModal: null,
      modalElement: null,
      service: new JobService(),
      jobs: [],
      jobsByDate: {}
    }
  },
  computed: {

    jobDates() {
      return Object.keys(this.jobsByDate).sort().reverse()
    }

  },
  mounted() {
    console.log("Jobs by Market modal mounted")
    this.modalElement = document.getElementById('jobsByMarketModal')
    this.currentModal = new bootstrap.Modal(this.modalElement)

    this.modalElement.addEventListener('hidden.bs.modal', () => {
      this.$emit('noEvent')
    })

    this.currentModal.show()
    this.loadJobs()
  },
  beforeDestroy() {
    this.modalElement.removeEventListener('hidden.bs.modal')
  },
  methods: {

    loadJobs() {
      this.model.loading = true
      let jobService = new JobService()
      jobService.getProcessingJobsByMarket(this.market.marketName).then((response) => {
        this.model.loading = false
        this.jobs = response.data

        for (let i = 0; i < this.jobs.length; i++) {
          let job = this.jobs[i]
          // let startTime = job.audits[0].jobConfig.startTime
          // let dateKey = dayjs(startTime * 1000).format("YYYY-MM-DD");
          let dateTokens = job.createdAt.split('T')
          let dateKey = dateTokens[0]

          if (this.jobsByDate[dateKey] === undefined) {
            job.audits.sort((a, b) => a.auditId > b.auditId ? 1 : -1);
            this.jobsByDate[dateKey] = job
          }

          // if (job.audits.length > 1 && job.audits[1].jobType === 'DEMOGRAPHICS') {
          //   this.jobsByDate[dateKey].demographicJob = job
          // } else if (job.audits[0].jobType === 'IMPRESSIONS') {
          //   this.jobsByDate[dateKey].impressionJob = job
          // }
        }

        console.log('Jobs by date')
        console.log(this.jobsByDate)

      }).catch((error) => {
        console.log('Error loading processing jobs for market: ' + this.market.marketName)
        console.log(error)
        this.model.loading = false
      })
    },
    noHandler(e) {
      // hide the modal
      this.currentModal.hide()
    },
    yesHandler() {
      // call the parent's "yesEvent"
      if (this.yesEvent) {
        this.yesEvent()
      }
      // hide the modal
      this.noHandler()
    },
    // isMetricsPresent(job) {
    //   return job.audits && job.audits.length > 0 && job.audits[0].jobConfig && job.audits[0].jobConfig.startTime
    // },
    // getDateLabel(dateTime) {
    //   const dateFormatOptions = {year: "numeric", month: "short", day: "numeric"}
    //   const jobDate = new Date(dateTime * 1000)
    //
    //   return dayjs(dateTime * 1000).format("YYYY-MM-DD");
    //
    //   //return jobDate.toLocaleDateString('en-us', dateFormatOptions) + ", " + jobDate.toLocaleTimeString()
    // },
    // hasFirstJobAudit(job) {
    //   if (job.audits && job.audits.length > 0) {
    //     return true
    //   }
    //
    //   return false
    // },
    // getDemographicJob(dateKey) {
    //   return this.jobsByDate[dateKey].demographicJob
    // },
    // getImpressionJob(dateKey) {
    //   return this.jobsByDate[dateKey].impressionJob
    // }
  }
}
</script>