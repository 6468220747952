import CustomerService from './services/CustomerService';
import MarketService from './services/MarketService';
import UserService from './services/UserService';
import CampaignService from './services/CampaignService';
import StringUtil from './utils/StringUtil';
import AssetOwnersService from './services/AssetOwnersService';

export default class StateManager {
  constructor(state) {
    this.state = state;
    this.state.areMarketsLoaded = new Promise((resolve, reject) => {
      this.resolveAreMarketsLoaded = resolve;
      this.rejectAreMarketsLoaded = reject;
    });
    this.state.areCustomersLoaded = new Promise((resolve, reject) => {
      this.resolveAreCustomersLoaded = resolve;
      this.rejectAreCustomersLoaded = reject;
    });
    this.customersLoaded = false;
    this.marketsLoaded = false;
    this.usersLoaded = false;
    this.campaignsLoaded = false;
    this.assetOwnersLoaded = false;
    this.stringUtil = new StringUtil();
  }

  init() {
    if (!this.customersLoaded) {
      this.loadCustomers();
    }

    if (!this.marketsLoaded) {
      this.loadMarkets();
    }

    if (!this.usersLoaded) {
      this.loadUsers();
    }

    if (!this.campaignsLoaded) {
      this.loadCampaigns();
    }
  }

  resetState() {
    this.customersLoaded = false;
    this.marketsLoaded = false;
    this.usersLoaded = false;
    this.campaignsLoaded = false;
    this.state.authToken = null;
    this.state.user.company = this.state.user.email = this.state.user.displayName = null;
    this.state.customers = [];
    this.state.markets = [];
  }

  loadCampaigns() {
    let service = new CampaignService();
    service
      .list()
      .then((response) => {
        this.stringUtil.sortByString(response.data, 'campaignRef');
        this.state.campaigns = response.data;
        this.campaignsLoaded = true;
      })
      .catch((error) => {
        console.log('Error loading campaigns');
        console.log(error);
      });
  }

  loadUsers() {
    let service = new UserService();
    service
      .list()
      .then((response) => {
        this.stringUtil.sortByString(response.data, 'displayName');
        this.state.users = response.data;
        this.usersLoaded = true;
      })
      .catch((error) => {
        console.log('Error loading users');
        console.log(error);
      });
  }

  loadCustomers() {
    let service = new CustomerService();
    service
      .list()
      .then((response) => {
        this.state.customers = response.data;
        this.resolveAreCustomersLoaded(true);
        this.customersLoaded = true;
      })
      .catch((error) => {
        this.rejectAreCustomersLoaded(false);
        console.log('Error loading customers');
        console.log(error);
      });
  }

  loadMarkets() {
    let service = new MarketService();
    service
      .list()
      .then((response) => {

        this.stringUtil.sortByString(response.data, 'marketName');
        this.state.markets = response.data;
        this.resolveAreMarketsLoaded(true);
        this.marketsLoaded = true;
      })
      .catch((error) => {
        this.rejectAreMarketsLoaded(false);
        console.log('Error loading markets');
        console.log(error);
      });
  }

  loadAssetOwners() {
    let service = new AssetOwnersService();
    service
      .getAssetOwners()
      .then((response) => {
        this.state.assetOwners = response;
        this.assetOwnersLoaded = true;
      })
      .catch((error) => {
        console.log('Error loading asset owners.');
        console.log(error);
      });
  }
}
