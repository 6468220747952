<template>
  <div class="p-4">
    <div class="mb-3">
      <h2>{{message}}</h2>
    </div>

    <div class="card mb-3">
      <div class="card-body">

        <div class="row w-100-pct">
          <div class="mb-3 col-12 col-md-4">
            <label class="form-label">Customer</label>
            <select class="form-select" v-model="customerId">
              <option v-for="customer in model.customers" :value="customer.customerId">{{ customer.customerName }}
              </option>
            </select>
          </div>

          <div v-if="customerId" class="mb-3 col-12 col-md-4 px-2">
            <label class="form-label">Campaigns</label>
            <select-dropdown :list="availableCampaigns" label-field="campaignRef" :single-select="true"
                             list-title="Campaigns" @update:selectedItems="onCampaignsSelected"></select-dropdown>
          </div>
        </div>
      </div>
    </div>

    <div v-if="campaignDetails" class="mb-3">
      <h3>{{campaignDetails.campaignRef}}</h3>

      <div>Ad Groups</div>

      <div v-for="flight in campaignDetails.flights" class="d-flex">
        <div class="mx-1">{{flight.flightRef}}</div>
        <div class="mx-1">{{flight.inventoryType}}</div>
        <div class="mx-1">Media type: {{flight.metaData.type}}</div>
      </div>

    </div>
  </div>
</template>

<script>
import CampaignExplorerService from "@/services/CampaignExplorerService";
import SelectDropdown from "@/components/SelectDropdown.vue";
import CampaignService from "@/services/CampaignService";

export default {
  name: 'CampaignExplorer',
  components: {SelectDropdown},
  props: ['prop1', 'prop2'],
  data () {
    return {
      model: streetmetrics.model,
      message: '',
      service: new CampaignExplorerService(),
      customerId: null,
      campaigns: [],
      selectableCampaigns: [],
      selectedCampaigns: [],
      campaign: null,
      campaignDetails: null,
    }
  },
  computed: {
    isInvalidRequest() {
      return this.customerId === null || this.selectedCampaigns.length === 0
    },
    availableCampaigns() {
      if (this.customerId) {
        return this.campaigns.filter(campaign => (campaign.customerId === this.customerId))
      }

      return this.campaigns
    },
  },
  created() {
    this.selectableCampaigns = this.model.campaigns.map((item) => {
      item.selected = false
      return item
    })
  },
  mounted() {
    this.loadGreeting()
    this.loadCampaigns();
  },
  methods: {
    loadGreeting () {
      this.service.indexRequest().then( (response) => {
        this.message = response.message
      })
    },
    loadCampaigns() {
      this.model.loading = true
      let service = new CampaignService();
      service.list().then((response) => {
        streetmetrics.utils.string.sortByString(response.data, 'campaignRef');
        this.campaigns = response.data;
        this.model.loading = false
      }).catch((error) => {
        console.log('Error loading campaigns');
        console.log(error);
        this.model.loading = false
      });
    },
    onCampaignsSelected(selectedItems) {
      this.selectedCampaigns = selectedItems;
      console.log(this.selectedCampaigns);
      if(this.selectedCampaigns && this.selectedCampaigns.length > 0) {
        this.campaign = this.selectedCampaigns[0]
        this.loadCampaignDetails()
        this.service.getMileageByDate(this.campaign)
        this.service.getDeviceCountByDate(this.campaign)

      }
    },
    loadCampaignDetails() {
      this.service.getCampaignDetails(this.campaign).then( (response) => {
        this.campaignDetails = response.data
        console.log(this.campaignDetails.markets)
      }).catch((error) => {
        console.log('Error loading campaign details')
        console.log(error)
      });
    },
  }
}
</script>
