<template>
  <div class="my-4 d-flex align-items-center">
    <div>Filters</div>

    <!-- Dropdown filter for markets -->
    <div class="mx-2 w-40">
      <select class="form-select" v-model="marketFilter">
        <option value="All">All Markets</option>
        <option v-for="market in markets" :value="market.marketHash" :key="market.marketHash">
          {{ market.marketName }}
        </option>
      </select>
    </div>

    <div class="mx-2 w-40">
      <select class="form-select" v-model="typeFilter">
        <option value="All">All Types</option>
        <option value="STATIONARY">Stationary</option>
        <option value="TRANSIT">Transit</option>
      </select>
    </div>

    <div v-if="this.marketFilter === 'All' && this.typeFilter === 'All'" class="mx-2">
      Three days of jobs show until a filter is selected.
    </div>

    <div class="mx-2">
      <button v-if="marketFilter !== 'All' || typeFilter !== 'All'" class="btn btn-primary" @click="onClearFilters()">clear filters</button>
    </div>

  </div>

  <div class="my-4">
    Displaying {{availableJobs.length.toLocaleString()}} of {{jobs.length.toLocaleString()}} Jobs
  </div>

  <pipeline-jobs-table v-if="availableJobs.length > 0" :jobs="availableJobs" :markets-hash-table="marketsHashTable" >
  </pipeline-jobs-table>

</template>

<script>
import streetmetrics from "@/model";
import PipelineJobsTable from "@/views/pipeline/PipelineJobsTable.vue";
import dayjs from "dayjs";

export default {
  name: 'PipelineJobsList',
  components: {PipelineJobsTable},
  props: ['jobs', 'marketsHashTable'],
  data () {
    return {
      model: streetmetrics.model,
      marketFilter: 'All', // Initialize the market filter
      typeFilter: 'All', // Initialize the type filter
    }
  },
  computed: {
    markets() {
      if (this.marketsHashTable === null) {
        return [];
      }

      const markets = Object.values(this.marketsHashTable);
      streetmetrics.utils.string.sortByString(markets, 'marketName');
      return markets
    },
    availableJobs() {
      let loadedJobs = this.jobs

      if(this.marketFilter !== 'All') {
        loadedJobs = this.jobs.filter((job) => { return job.marketHash === this.marketFilter })
      }

      if(this.typeFilter !== 'All' ) {
        loadedJobs = loadedJobs.filter((job) => { return job.jobType.toUpperCase() === this.typeFilter})
      }

      if(this.marketFilter === 'All' && this.typeFilter === 'All') {
        const tenDaysAgo = dayjs().subtract(10, 'day').format('YYYY-MM-DD')

        loadedJobs = loadedJobs.filter((job) => {
          // const jobDateString = dayjs(job.processingStartTime).format('YYYY-MM-DD')
          return job.date >= tenDaysAgo
        })
      }

      return loadedJobs.sort((a, b) => a.date.localeCompare(b.date)).reverse();
    },
  },
  methods: {
    formatDuration(duration) {
      return streetmetrics.utils.string.formatDuration(duration)
    },

    onClearFilters() {
      this.marketFilter = this.typeFilter = 'All';
    }
  }
}
</script>
