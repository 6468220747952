<template>
  <nav class="navbar navbar-light bg-white border-bottom shadow-sm-bottom">
    <div class="container-fluid">
      <div class="navbar-brand ms-3 me-auto">
        <router-link :to="{ name: 'dashboard' }">
          <streetmetrics-logo></streetmetrics-logo>
        </router-link>
      </div>

      <router-link v-if="state.user.displayName" :to="{ name: 'profile' }" class="navbar-text text-primary">
        {{ greeting }} {{ state.user.displayName }}
      </router-link>
    </div>
  </nav>
</template>

<script>
import UserService from "../services/UserService";
import StreetmetricsLogo from "../components/StreetmetricsLogo";

export default {
  name: 'TopNav',
  components: {StreetmetricsLogo},
  data() {
    return {
      state: streetmetrics.model,
    }
  },
  computed: {
    greeting() {
      const hours = new Date().getHours();
      if (hours < 12) return 'Good Morning';
      if (hours < 17) return 'Good Afternoon';
      return 'Good Evening';
    }
  }
}
</script>
