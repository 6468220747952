<template>
  <div class="p-4">
    <div class="my-4 d-flex justify-content-between">
      <div class="d-flex align-items-center"></div>

      <div>
        <button class="btn btn-secondary" @click="shouldShowAddModal = true">
          ADD MULTIPLIER
        </button>
      </div>
    </div>

    <vue-final-modal
      classes="modal-container"
      content-class="modal-content"
      v-model="shouldShowDeleteModal"
    >
      <span class="modal__title">CONFIRM DELETION</span>
      <div class="modal__content">
        <p>
          Are you sure you want to delete this multiplier ID:
          {{ deleteIndex }} ?
        </p>
      </div>

      <div class="modal__action d-flex gap-3">
        <button class="btn btn-danger" @click="deleteMultiplier">
          CONFIRM
        </button>
        <button
          class="btn btn-outline"
          @click="
            deleteIndex = null;
            shouldShowDeleteModal = false;
          "
        >
          CANCEL
        </button>
      </div>
    </vue-final-modal>
    <vue-final-modal
      classes="modal-container"
      content-class="modal-content"
      v-model="shouldShowAddModal"
    >
      <span class="modal__title">ADD MULTIPLIER</span>
      <div class="modal__content">
        <div class="modal__content-container">
          <div>
            <label for="addMultiplierCustomer">Customer:</label>
            <select
              id="addMultiplierCustomer"
              class="form-select"
              v-model="addMultiplierCustomer"
            >
              <option v-for="item in customers || []" :value="item.customerId">
                {{ item.customerName }}
              </option>
            </select>
          </div>
          <div>
            <label for="addMultiplierMarket">Market:</label>
            <select
              id="addMultiplierMarket"
              class="form-select"
              v-model="addMultiplierMarket"
            >
              <option v-for="item in markets || []" :value="item.marketId">
                {{ item.marketName }}
              </option>
            </select>
          </div>
          <div>
            <label for="partitionRangeYear">Year:</label>
            <input
              class="form-control"
              v-model="addMultiplierYear"
              ref="partitionRangeYear"
              id="partitionRangeYear"
              type="number"
              min="2000"
              max="2030"
            />
          </div>
          <div>
            <label for="partitionRangeMonth">Month:</label>
            <select
              id="addMultiplierCustomer"
              class="form-select"
              v-model="addMultiplierMonth"
            >
              <option
                v-for="[name, key] in Object.entries(months) || []"
                :value="key"
              >
                {{ name }}
              </option>
            </select>
          </div>
          <div>
            <label for="addMultiplierLeft">LEFT:</label>
            <input
              class="form-control"
              v-model="multiplierLeft"
              id="addMultiplierLeft"
              type="number"
            />
          </div>
          <div>
            <label for="addMultiplierRight">RIGHT:</label>
            <input
              class="form-control"
              v-model="multiplierRight"
              id="addMultiplierRight"
              type="number"
            />
          </div>
          <div>
            <label for="addMultiplierCenter">CENTER:</label>
            <input
              class="form-control"
              v-model="multiplierCenter"
              id="addMultiplierCenter"
              type="number"
            />
          </div>
        </div>
      </div>
      <div class="modal__action gap-3">
        <button class="btn btn-secondary" @click="save()">SAVE</button>
        <button class="btn btn-outline" @click="closeModal($event)">
          CANCEL
        </button>
      </div>
    </vue-final-modal>

    <div class="card mb-3">
      <div class="card-body">
        <div class="row w-100-pct">
          <div class="mb-3 col-12 col-md-4">
            <label class="form-label">Customer</label>
            <select
              class="form-select"
              v-model="customerSelected"
              @change="onCustomerSelectChange($event)"
            >
              <option v-for="item in customers" :value="item.customerId">
                {{ item.customerName }}
              </option>
            </select>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="multipliers.length > 0 && customers.length > 0"
      class="table-responsive table-container scrollable-container customer-container mt-2"
    >
      <table class="table position-relative">
        <p
          class="text-center end-0 start-0 position-absolute py-3"
          v-if="
            (!multipliersToRender || multipliersToRender.length === 0) &&
            !!this.customerSelected
          "
        >
          There are no multipliers in action
        </p>
        <thead class="bg-gray-500 text-uppercase">
          <tr>
            <th>Id</th>
            <th>CustomerId</th>
            <th>CustomerName</th>
            <th>MarketId</th>
            <th>MarketName</th>
            <th>DateRange</th>
            <th>Multiplier (L,R,F/B)</th>
            <th>Edit</th>
            <th>Delete</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(multiplier, index) in multipliersToRender">
            <td>
              <span>{{ multiplier.multiplerId }}</span>
            </td>
            <td>
              <span>{{ multiplier.customerId }}</span>
            </td>
            <td>
              <span>{{ multiplier.customerName }}</span>
            </td>
            <td>
              <span>{{ multiplier.marketId }}</span>
            </td>
            <td>
              <span>{{ multiplier.marketName }}</span>
            </td>
            <td>
              <span>{{ formatPartitionRange(multiplier.partitionRange) }}</span>
            </td>
            <td>
              <div v-if="isEditing && index === editIndex">
                <label>LEFT:</label>
                <input
                  class="form-control"
                  type="number"
                  v-model="multiplierLeft"
                  :placeholder="[
                    [getImpMultiplierPart(multiplier.impMultiplier, 'LEFT')],
                  ]"
                />
              </div>
              <div v-if="isEditing && index === editIndex">
                <label>RIGHT:</label>
                <input
                  class="form-control"
                  type="number"
                  v-model="multiplierRight"
                  :placeholder="[
                    [getImpMultiplierPart(multiplier.impMultiplier, 'RIGHT')],
                  ]"
                />
              </div>
              <div v-if="isEditing && index === editIndex">
                <label>FRONT/BACK:</label>
                <input
                  class="form-control"
                  type="number"
                  v-model="multiplierCenter"
                  :placeholder="[
                    [getImpMultiplierPart(multiplier.impMultiplier, 'CENTER')],
                  ]"
                />
              </div>
              <span v-if="!isEditing || index !== editIndex">{{
                multiplier.impMultiplier
              }}</span>
            </td>
            <td
              class="pointer"
              v-if="!isEditing || index !== editIndex"
              @click="configureIsEditing(multiplier, index)"
            >
              <button class="btn btn-info">EDIT</button>
            </td>
            <td
              v-if="!isEditing"
              class="pointer"
              @click="configureDelete(multiplier)"
            >
              <button class="btn btn-danger">DELETE</button>
            </td>
            <td>
              <button
                class="btn btn-secondary"
                v-if="isEditing && index === editIndex"
                @click="update(multiplier)"
              >
                SAVE
              </button>
            </td>
            <td>
              <button
                class="btn btn-outline"
                v-if="isEditing && index === editIndex"
                @click="
                  isEditing = false;
                  editIndex = null;
                "
              >
                CANCEL
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import { toRaw } from 'vue';
import MultiplierService from '../services/MultiplierService';
import CustomerService from '../services/CustomerService';
import MarketService from '../services/MarketService';
import { $vfm, VueFinalModal, ModalsContainer } from 'vue-final-modal';

//NOTE: the id property of a multiplier is spelled wrong in the database, it's `multiplerId`
export default {
  name: 'Multipliers',
  components: {
    VueFinalModal,
    ModalsContainer,
  },
  props: ['customerId', 'viewModel'],
  data() {
    return {
      customerService: new CustomerService(),
      multiplierService: new MultiplierService(),
      marketService: new MarketService(),
      isEditing: false,
      model: streetmetrics.model,
      multipliers: [],
      multipliersToRender: [],
      customers: [],
      markets: [],
      customerMap: {},
      marketMap: {},
      shouldShowAddModal: false,
      shouldShowDeleteModal: false,
      customerSelected: null,
      addMultiplierCustomer: null,
      addMultiplierYear: new Date().getFullYear(),
      addMultiplierMonth: null,
      addMultiplierMarket: null,
      multiplierLeft: 0,
      multiplierRight: 0,
      multiplierCenter: 0,
      editIndex: null,
      deleteIndex: null,
      months: {
        January: '01',
        February: '02',
        March: '03',
        April: '04',
        May: '05',
        June: '06',
        July: '07',
        August: '08',
        September: '09',
        October: '10',
        November: '11',
        December: '12',
      },
    };
  },
  mounted() {
    this.model.loading = true;
    const promises = [
      this.customerService.list(),
      this.multiplierService.list(),
      this.marketService.list(),
    ];
    Promise.all(promises).then(
      ([customerResponse, multiplierResponse, marketResponse]) => {
        this.customers = customerResponse.data;
        this.markets = marketResponse.data;
        this.generateMarketMap(this.markets);
        this.generateCustomerMap(this.customers);
        this.multipliers = this.joinCustomersAndMarketsToMultipliers(
          multiplierResponse.data,
        );
        this.model.loading = false;
      },
    );
  },
  methods: {
    loadMultipliers() {
      this.multiplierService.list().then( (response) => {
        this.multipliers = this.joinCustomersAndMarketsToMultipliers(
            response.data,
        );

        this.filterMultipliersByCustomer()
      })
    },
    getImpMultiplierPart(impMultiplier, partToGet) {
      const parts = toRaw(impMultiplier);
      switch (partToGet) {
        case 'LEFT':
          return parts[0];
        case 'RIGHT':
          return parts[1];
        case 'CENTER':
          return parts[2];
        default:
          return;
      }
    },
    formatPartitionRange(partitionRange) {
      const month = ('' + partitionRange).slice(-2);
      const year = ('' + partitionRange).slice(0, 2);
      return `${month}/${year}`;
    },
    configureDelete(multiplier) {
      this.deleteIndex = toRaw(multiplier).multiplerId;
      this.shouldShowDeleteModal = true;
    },
    configureIsEditing(multiplier, index) {
      const rawData = toRaw(multiplier);
      this.multiplierLeft = rawData.impMultiplier[0];
      this.multiplierRight = rawData.impMultiplier[1];
      this.multiplierCenter = rawData.impMultiplier[2];
      this.editIndex = index;
      this.isEditing = true;
    },
    closeModal() {
      this.shouldShowAddModal = false;
    },
    deleteMultiplier() {
      this.model.loading = true;
      this.multiplierService.delete(this.deleteIndex).then(() => {
        this.optimisticDelete();
        this.deleteIndex = null;
        this.shouldShowDeleteModal = false;
        this.model.loading = false;
      });
    },
    optimisticDelete() {
      const indexToDelete = this.multipliers.findIndex(
        (m) => m.multiplerId === this.deleteIndex,
      );
      if (isNaN(indexToDelete)) return;
      this.multipliers.splice(indexToDelete, 1);
      this.filterMultipliersByCustomer();
    },
    update(data) {
      this.model.loading = true;
      const rawData = toRaw(data);
      this.multiplierService
        .update({
          multiplerId: rawData.multiplerId,
          impMultiplier: [
            this.multiplierLeft,
            this.multiplierRight,
            this.multiplierCenter,
          ],
        })
        .then(() => {
          this.model.loading = false;
          this.isEditing = false;
          this.model.toast.background = 'bg-green';
          this.model.toast.message = 'Updated!';
          this.loadMultipliers()
        })
        .catch(() => {
          this.model.toast.background = 'bg-red';
          this.model.toast.message =
            'There was an error saving your multiplier. Please try again later.';
          this.isEditing = false;
          this.model.loading = false;
        });
    },
    save() {
      this.model.loading = true;
      const partitionRange = `${('' + this.addMultiplierYear).slice(-2)}${
        this.addMultiplierMonth
      }`;
      this.multiplierService
        .add({
          customerId: this.addMultiplierCustomer,
          impMultiplier: [
            this.multiplierLeft,
            this.multiplierRight,
            this.multiplierCenter,
          ],
          marketId: this.addMultiplierMarket,
          partitionRange,
        })
        .then(({ multiplerId }) => {
          this.multipliers.push({
            multiplerId,
            customerName: this.customers[this.addMultiplierCustomer],
            customerId: this.addMultiplierCustomer,
            impMultiplier: [
              this.multiplierLeft,
              this.multiplierRight,
              this.multiplierCenter,
            ],
            marketId: this.addMultiplierMarket,
            marketName: this.markets[this.addMultiplierMarket],
            partitionRange,
          });
          this.filterMultipliersByCustomer();
          this.resetAddMultiplier();
          this.model.loading = false;
          this.shouldShowAddModal = false;
          this.model.toast.background = 'bg-green';
          this.model.toast.message = 'Saved!';
        })
        .catch((err) => {
          this.model.toast.background = 'bg-red';
          this.model.toast.message =
            'There was an error saving your multiplier. Please try again later.';
          this.model.loading = false;
          this.shouldShowAddModal = false;
        });
    },
    resetAddMultiplier() {
      this.addMultiplierCustomer = null;
      this.addMultiplierYear = null;
      this.addMultiplierMonth = null;
      this.addMultiplierMarket = null;
      this.multiplierLeft = 0;
      this.multiplierRight = 0;
      this.multiplierCenter = 0;
    },
    onCustomerSelectChange() {
      this.filterMultipliersByCustomer();
    },
    filterMultipliersByCustomer(customerId) {
      this.multipliersToRender = this.multipliers.filter(
        (multiplier) =>
          multiplier.customerId === (customerId || this.customerSelected),
      );
    },
    generateMarketMap(markets) {
      this.marketMap = markets.reduce((map, market) => {
        map[market.marketId] = market.marketName;
        return map;
      }, {});
    },
    generateCustomerMap(customers) {
      this.customerMap = customers.reduce((map, customer) => {
        map[customer.customerId] = customer.customerName;
        return map;
      }, {});
    },
    joinCustomersAndMarketsToMultipliers(multipliers) {
      return multipliers.map((multiplier) => {
        multiplier.customerName = this.customerMap[multiplier.customerId];
        multiplier.marketName = this.marketMap[multiplier.marketId];
        return multiplier;
      });
    },
  },
};
</script>
<style scoped>
::v-deep .modal-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
::v-deep .modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  max-height: 90%;
  width: 60%;
  margin: 0 1rem;
  padding: 1rem;
  border: 1px solid #e2e8f0;
  border-radius: 0.25rem;
  background: #fff;
}
.modal__title {
  margin: 0 2rem 0 0;
  font-size: 1.5rem;
  font-weight: 700;
}
.modal__content {
  flex-grow: 1;
  overflow-y: auto;
}
.modal__action {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  padding: 1rem 0 0;
}
.modal__close {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
}
</style>

<style scoped>
.dark-mode div::v-deep .modal-content {
  border-color: #2d3748;
  background-color: #1a202c;
}
</style>
