<template>
  <div class="position-fixed p-3 top-0 end-0" style="z-index: 11">
    <div ref="el" class="toast align-items-center text-white" :class="model.toast.background" role="alert" aria-live="assertive" aria-atomic="true">
      <div class="d-flex">
        <div class="toast-body">
          {{ text }}
        </div>
        <button type="button" class="btn-close text-white me-2 m-auto" data-bs-dismiss="toast" aria-label="Close"></button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Toast",
  props: {
    text: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      model: streetmetrics.model
    }
  },
  mounted() {
    const toast = new bootstrap.Toast(this.$refs.el)
    toast.show()

    this.$refs.el.addEventListener('hidden.bs.toast', () => {
      this.model.toast.message = null
      this.model.toast.background = 'bg-green'
    })
  },
}
</script>

<style scoped>

</style>
