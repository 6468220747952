import RestService from "./RestService";

export default class MediaService  extends RestService {

  constructor() {
    super('media')
  }

  getOwners() {
    let url = this.baseUrl + '/v3/api/media/owners'
    return this.executeGet(url)
  }

  getClassifications() {
    let url = this.baseUrl + '/v3/api/media/classifications'
    return this.executeGet(url)
  }

}
