<template>
  <div class="mt-3 pe-3">

    <div v-if="campaigns.length > 0" class="table-responsive table-container customer-container mt-2">
      <table class="table">
        <thead class="bg-gray-500 text-uppercase">
        <tr>
          <th>ID</th>
          <th>Name</th>
<!--          <th>Impressions</th>-->
<!--          <th>Miles Driven</th>-->
<!--          <th>Impr / Mile</th>-->
<!--          <th>Markets</th>-->
<!--          <th>Ad Groups</th>-->
<!--          <th>Pixels</th>-->
        </tr>
        </thead>
        <tbody>
        <tr v-for="campaign in campaigns">
          <td>{{ campaign.campaignId }}</td>
          <td>
            <div class="fw-bold gray-700 mb-2">
              <a href="javascript:void(0)" @click="onCampaignClick(campaign)">{{
                  campaign.campaignRef
                }}</a>
            </div>
            <div class="d-flex align-items-center font-sm gray-500">
              <div class="me-4">{{ getDataLabel(campaign.activeFrom, campaign.activeTo) }}</div>
<!--              <div class="border rounded py-1 px-2 font-xsm"-->
<!--                   :class="campaign.type === 'STATIC' ? 'border-warning text-warning bg-yellow-100' : 'border-info text-info bg-teal-100'">-->
<!--                {{ campaign.type }}-->
<!--              </div>-->
            </div>
          </td>
<!--          <td>{{ campaign.overallMetrics.impressions.toLocaleString() }}</td>-->
<!--          <td>{{ roundNumber(campaign.overallMetrics.totalDistance).toLocaleString() }}</td>-->
<!--          <td>{{ roundNumber(campaign.overallMetrics.impPerMile) }}</td>-->
<!--          <td>-->
<!--            <div v-for="market in campaign.markets">-->
<!--              <span>{{ market }}</span><br>-->
<!--            </div>-->
<!--          </td>-->
<!--          <td>{{ roundNumber(campaign.flights.length) }}</td>-->
<!--          <td>-->
<!--            <pixel-count :campaign-id="campaign.campaignId"></pixel-count>-->
<!--          </td>-->
        </tr>

<!--        <tr v-for="campaign in missingCampaigns">-->
<!--          <td>{{ campaign.campaignId }}</td>-->
<!--          <td>-->
<!--            <div class="fw-bold gray-700 mb-2">-->
<!--              <a href="javascript:void(0)" @click="onCampaignClick(campaign)">{{-->
<!--                  campaign.campaignRef-->
<!--                }}</a>-->
<!--            </div>-->
<!--          </td>-->
<!--          <td></td>-->
<!--          <td></td>-->
<!--          <td></td>-->
<!--          <td></td>-->
<!--          <td></td>-->
<!--          <td>-->
<!--            <pixel-count :campaign-id="campaign.campaignId"></pixel-count>-->
<!--          </td>-->
<!--        </tr>-->
        </tbody>
      </table>
    </div>

    <div v-else-if="campaignsLoaded && campaigns.length === 0" class="my-4 alert alert-warning">
      <strong>This customer does not have any campaigns.</strong>
    </div>

  </div>
</template>

<script>
import CustomerTabs from "../components/CustomerTabs";
import CustomerHeader from "../components/CustomerHeader";
import CustomerService from "../services/CustomerService";
import PixelService from "../services/PixelService";
import PixelCount from "../components/PixelCount";

export default {
  name: 'Campaigns',
  components: {PixelCount, CustomerHeader, CustomerTabs},
  props: ['customerId', 'viewModel'],
  data() {
    return {
      // eslint-disable-next-line no-undef
      model: streetmetrics.model,
      campaigns: [],
      missingCampaigns: [],
      campaignsLoaded: false
    }
  },
  mounted() {
    this.model.loading = true
    let service = new CustomerService()
    service.listCampaigns(this.customerId).then((response) => {
      this.campaigns = response.data
      this.model.loading = false
      this.campaignsLoaded = true
    }).catch((error) => {
      console.log('Error loading campaigns')
      console.log(error)
      this.model.loading = false
      this.model.toast.background = 'bg-orange'
      this.model.toast.message = 'There was an error loading campaigns.'
    })

    // let campaignIdMap = {}
    //
    // for( let i = 0; i < this.campaigns; i++ ) {
    //   const item = this.campaigns[i]
    //   campaignIdMap[item.campaignId] = 'ok'
    // }
    //
    // this.missingCampaigns = this.model.campaigns.filter((campaign) => {
    //   return campaign.customerId === this.customerId && !campaignIdMap[campaign.campaignId]
    // });

  },
  methods: {
    getDataLabel(activeFrom, activeTo) {
      const startDate = new Date(Number(activeFrom) * 1000)
      const endDate = new Date(Number(activeTo) * 1000)
      const dateFormatOptions = {year: "numeric", month: "short", day: "numeric"}
      return startDate.toLocaleDateString('en-us', dateFormatOptions) + ' - ' + endDate.toLocaleDateString('en-us', dateFormatOptions)
    },
    roundNumber(value) {
      return Math.round((Number(value) + Number.EPSILON) * 100) / 100
    },
    onCampaignClick(campaign) {
      this.viewModel.currentCampaignId = campaign.campaignId
      this.viewModel.childView = 'campaign-details'
    }
  }
}
</script>

<style>

</style>
