<template>
  <div class="pt-4 px-4">

    <div class="mb-3 d-flex justify-content-between">
      <div class="d-flex align-items-center">
        <h2>Customers</h2>
      </div>

      <div>
        <button class="btn btn-secondary" @click="onNewCustomerClick">New Customer</button>
      </div>
    </div>

    <div class="table-responsive table-container scrollable-container">
      <table class="table">
        <thead class="bg-gray-500 text-uppercase">
        <tr>
          <th>ID</th>
          <th>Name</th>
          <th>Channel</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="customer in model.customers">
          <td>{{ customer.customerId }}</td>
          <td>
            <router-link :to="{ name: 'customer-details', params: { customerUrlFriendlyId: customer.slug } }">
              {{ customer.customerName }}
            </router-link>
          </td>
          <td>{{ customer.customerChannel }}</td>
        </tr>
        </tbody>
      </table>
    </div>

    <customer-form-modal v-if="showModal" @noEvent="showModal = false" :customer="newCustomer"></customer-form-modal>
  </div>
</template>

<script>
import CustomerFormModal from "../components/CustomerFormModal";

export default {
  name: 'CustomerList',
  components: {CustomerFormModal},
  data() {
    return {
      // eslint-disable-next-line no-undef
      model: streetmetrics.model,
      showModal: false,
      newCustomer: {
        customerId: null,
        customerName: '',
        customerChannel: '',
        markets: [],
      }
    }
  },
  mounted() {
  },
  methods: {
    onNewCustomerClick() {
      this.newCustomer = {
        customerId: null,
        customerName: '',
        customerChannel: '',
        markets: [],
      }
      this.showModal = true
    }
  }
}
</script>

<style>

</style>
