<template>
  <div id="userFormModal" class="modal fade" tabindex="-1">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-body">

          <div class="d-flex align-items-center p-3">
            <div class="centered-circle flex-shrink-0 bg-secondary text-primary h-10 w-10">
              <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 24 24"
                   class="small-icon" fill="currentColor">
                <g data-name="Layer 2">
                  <g data-name="award">
                    <rect width="24" height="24" opacity="0"></rect>
                    <path
                        d="M19 20.75l-2.31-9A5.94 5.94 0 0 0 18 8 6 6 0 0 0 6 8a5.94 5.94 0 0 0 1.34 3.77L5 20.75a1 1 0 0 0 1.48 1.11l5.33-3.13 5.68 3.14A.91.91 0 0 0 18 22a1 1 0 0 0 1-1.25zM12 4a4 4 0 1 1-4 4 4 4 0 0 1 4-4zm.31 12.71a1 1 0 0 0-1 0l-3.75 2.2L9 13.21a5.94 5.94 0 0 0 5.92 0L16.45 19z"></path>
                  </g>
                </g>
              </svg>
            </div>
            <div class="ms-3">
              <h3 class="fs-5 spacing-close">{{actionLabel}} User</h3>
            </div>
          </div>

          <div v-if="errorMessage" class="alert alert-warning mt-1 mb-3" role="alert">
            {{errorMessage}}
          </div>

          <form>
            <div class="mb-3">
              <label for="userNameInput" class="form-label">Full Name</label>
              <input v-model="user.displayName" type="text" class="form-control" id="userNameInput">
            </div>

            <div class="mb-3">
              <label for="emailInput" class="form-label">Email</label>
              <input v-model="user.auth.authEmail" type="text" class="form-control" id="emailInput">
            </div>

            <div class="mb-3">
              <label for="pwInput" class="form-label">Password</label>
              <input v-model="user.auth.authPassword" type="text" class="form-control" id="pwInput">
            </div>

          </form>

        </div>

        <div class="modal-footer bg-">
          <button type="button" class="btn btn-secondary font-sm" style="min-width: 150px;" @click="onSaveClick">Save
          </button>
          <button type="button" class="btn btn-outline-primary font-sm" data-bs-dismiss="modal"
                  style="min-width: 150px;">Cancel
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import UserService from "../services/UserService";

export default {
  name: 'UserFormModal',
  props: {
    yesEvent: Function,
    user: Object,
    customerId: Number
  },
  data() {
    return {
      model: streetmetrics.model,
      currentModal: null,
      modalElement: null,
      service: new UserService(),
      errorMessage: null
    }
  },
  computed: {
    actionLabel() {
      return this.user.userId ? 'Edit' : 'New'
    }
  },
  mounted() {
    this.modalElement = document.getElementById('userFormModal')
    this.currentModal = new bootstrap.Modal(this.modalElement)

    this.modalElement.addEventListener('hidden.bs.modal', () => {
      this.$emit('noEvent')
    })

    this.currentModal.show()

    if(!this.user.userId) {
      this.user.auth.authEmail = ""
      this.user.auth.authPassword = ""
    }
  },
  beforeDestroy() {
    this.modalElement.removeEventListener('hidden.bs.modal')
  },
  methods: {
    onSaveClick() {

      let request = {
        displayName: this.user.displayName,
        customerId: this.customerId,
        auth: {
          authEmail: this.user.auth.authEmail,
          authPassword: this.user.auth.authPassword
        }
      }

      let id = this.user.userId

      if (id) {
        request.userId = id
      }

      this.model.loading = true
      let promise = id ? this.service.update(id, request) : this.service.create(request)

      promise.then((response) => {
        console.log('User saved')
        console.log(response)
        streetmetrics.stateManager.loadUsers()
        this.model.loading = false
        this.noHandler()
        this.model.toast.message = 'User saved'
      }).catch((error) => {
        console.log('error saving user')
        console.log(error)
        this.errorMessage = error.message
        this.model.loading = false
      })
    },

    noHandler(e) {
      // hide the modal
      this.currentModal.hide()
    }
  }
}
</script>

<style>

</style>
