<template>
  <div>

    <div class="d-flex justify-content-between">

      <div class="d-flex align-items-end">
        <h4 class="font-xbold text-uppercase text-primary mb-3">{{ title }}</h4>
      </div>

      <div class="border rounded p-2" style="width: fit-content;">
        <div v-for="(color, index) in lineColors" class="d-flex align-items-center">
          <div class="h-1 w-20" :style="{ backgroundColor: color }"></div>
          <div class="ms-2">{{ yLabels[index] }}</div>
        </div>
      </div>

    </div>

    <svg :id="chartId" class="chart"></svg>
  </div>
</template>

<script>
import * as d3 from "d3";

export default {
  name: 'LineChart',
  props: ['title', 'chartData', 'chartId', 'xField', 'yFields', 'yLabels', 'lineColors'],
  data() {
    return {
      model: streetmetrics.model,
      lines: []
    }
  },
  mounted() {
    // set the dimensions and margins of the graph
    let margin = {top: 20, right: 20, bottom: 30, left: 50},
        width = 960 - margin.left - margin.right,
        height = 500 - margin.top - margin.bottom;

    // set the ranges
    let x = d3.scaleLinear().range([0, width]);
    let y = d3.scaleLinear().range([height, 0]);

    this.lines = []

    // define the lines
    for (let i = 0; i < this.yFields.length; i++) {
      let yField = this.yFields[i]
      let line = d3.line()
          .x((d) => {
            return x(d[this.xField]);
          })
          .y((d) => {
            return y(d[yField]);
          })
          .curve(d3.curveMonotoneX);

      this.lines.push(line)
    }

    const svg = d3.select('#' + this.chartId)
        .attr("width", width + margin.left + margin.right)
        .attr("height", height + margin.top + margin.bottom)
        .append("g")
        .attr("transform",
            "translate(" + margin.left + "," + margin.top + ")");

    // Scale the range of the data
    x.domain(d3.extent(this.chartData, (dataItem) => {
      return dataItem[this.xField];
    }));

    let maxY = d3.max(this.chartData, (dataItem) => {
          let allValues = []

          for (let i = 0; i < this.yFields.length; i++) {
            let yField = this.yFields[i]
            allValues.push(dataItem[yField])
          }

          return Math.max(...allValues);
        }
    )
    y.domain([0, maxY]);

    // Add the X Axis
    svg.append("g")
        .attr('class', 'grid')
        .attr("transform", "translate(0," + height + ")")
        .call(d3.axisBottom(x)
            .ticks(5)
            .tickSize(-height, 0, 0))

    // Add the Y Axis
    svg.append("g")
        .attr('class', 'grid')
        .call(d3.axisLeft(y)
            .ticks(6)
            .tickSize(-width, 0, 0));

    // Add the lines to a path.
    for (let i = 0; i < this.lines.length; i++) {
      let line = this.lines[i]

      svg.append("path")
          .data([this.chartData])
          .attr("class", "line")
          .style('stroke', this.lineColors[i])
          .attr("d", line);
    }
  },
  methods: {
    importantMethodHere() {
    }
  }
}
</script>

<style>

</style>
