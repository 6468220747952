import RestService from "./RestService";

export default class FrameService extends RestService {
  constructor() {
    super("frames");
  }

  list(customerId, limit, offset) {
    return super.executeGet(
      this.baseUrl +
        `/v3/api/frames/admin-list?customerId=${customerId}&offset=${offset}&limit=${limit}`
    );
  }

  listByMarket(marketId) {
    return super.executeGet(
        this.baseUrl +
        `/v3/api/frames/admin-list-by-market?marketId=${marketId}`
    );
  }
}
