<template>
  <div id="marketModal" class="modal fade" tabindex="-1">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-body">

          <div class="d-flex align-items-center p-3">
            <div class="centered-circle flex-shrink-0 bg-secondary text-primary h-10 w-10">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                   stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                   class="feather feather-code small-icon">
                <polyline points="16 18 22 12 16 6"></polyline>
                <polyline points="8 6 2 12 8 18"></polyline>
              </svg>
            </div>
            <div class="ms-3">
              <h3 class="fs-5 spacing-close">Edit Geo Json for {{market.marketName}}</h3>
            </div>
          </div>

          <div v-if="validationMessage" class="alert mt-1 mb-3" :class="validationMessage.indexOf('invalid') > 0 ? 'alert-danger' : 'alert-success'" role="alert">
            {{ validationMessage }}
          </div>

          <form>

            <div class="mb-3">
              <label for="geoJsonInput" class="form-label">Geo Json</label>
              <textarea v-model="currentMarket.geoJson" class="form-control" id="geoJsonInput"></textarea>
            </div>

            <div class="mb-3">
              <button type="button" class="btn btn-secondary font-sm" style="min-width: 150px;" @click="validateGeoJson">Validate Geo Json
              </button>
            </div>

          </form>

        </div>

        <div class="modal-footer">
          <button type="button" class="btn btn-secondary font-sm" style="min-width: 150px;" @click="onSaveClick">Save
          </button>
          <button type="button" class="btn btn-outline-primary font-sm" data-bs-dismiss="modal"
                  style="min-width: 150px;">Cancel
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>


import AttributionService from "@/services/AttributionService";
import MarketService from "@/services/MarketService";

export default {
  name: 'MarketModal',
  props: {
    yesEvent: Function,
    market: Object,
  },
  data() {
    return {
      model: streetmetrics.model,
      currentModal: null,
      modalElement: null,
      validationMessage: null,
      currentMarket: {
        geoJson: ''
      },
      service: new MarketService(),
    }
  },
  computed: {


  },
  mounted() {
    console.log("Market modal mounted")
    this.modalElement = document.getElementById('marketModal')
    this.currentModal = new bootstrap.Modal(this.modalElement)

    this.modalElement.addEventListener('hidden.bs.modal', () => {
      this.$emit('noEvent')
    })

    this.currentModal.show()

    if(this.market.geoJson && this.market.geoJson.features && this.market.geoJson.features.length > 0) {
      this.currentMarket.geoJson =JSON.stringify(this.market.geoJson, undefined, 4);
    }

  },
  beforeDestroy() {
    this.modalElement.removeEventListener('hidden.bs.modal')
  },
  methods: {

    validateGeoJson() {
      let geoJson = null
      this.validationMessage = null
      try {
        geoJson = JSON.parse(this.currentMarket.geoJson)

        if(geoJson && geoJson.features && geoJson.features.length > 0) {
          this.validationMessage = 'Geo Json is valid.'
        }
        else {
          this.validationMessage = 'Geo Json is invalid.'
        }
      }
      catch (e) {
        this.validationMessage = 'Geo Json is invalid.'
      }

      return geoJson
    },

    prettyPrint() {
      const obj = JSON.parse(document.getElementById('geoJsonInput').value);
      document.getElementById('geoJsonInput').value = JSON.stringify(obj, undefined, 4);
    },

    onSaveClick() {
      this.validationMessage = null
      let geoJson = this.validateGeoJson()

      if(geoJson === null) {
        return
      }

      this.market.geoJson = geoJson
      this.service.update(this.market.marketId, this.market).then((response) => {
        console.log('Geo Json saved')
        console.log(response)
        this.yesHandler()

        // TODO: reload the markets
        streetmetrics.stateManager.loadMarkets()

      }).catch((error) => {
        console.log('error saving geo json')
        console.log(error)
        // this.errorMessage = error.message
        // this.model.loading = false
      })

    },

    noHandler(e) {
      // hide the modal
      this.currentModal.hide()
    },
    yesHandler() {
      // call the parent's "yesEvent"
      if (this.yesEvent) {
        this.yesEvent()
      }
      // hide the modal
      this.noHandler()
    }
  }
}
</script>