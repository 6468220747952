<template>
    <div id="frameModal" class="modal fade" tabindex="-1">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-body">
  
            <div class="d-flex align-items-center p-3">
              <div class="centered-circle flex-shrink-0 bg-secondary text-primary h-10 w-10">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                     stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                     class="feather feather-code small-icon">
                  <polyline points="16 18 22 12 16 6"></polyline>
                  <polyline points="8 6 2 12 8 18"></polyline>
                </svg>
              </div>
              <div class="ms-3">
                <h3 class="fs-5 spacing-close">Frame and Site Details</h3>
              </div>
            </div>
            <div>
            <div v-if="selectedFrame">
              <p><strong>Frame ID:</strong> {{ selectedFrame.frameId }}</p>
              <p><strong>Frame Ref:</strong> {{ selectedFrame.frameRef }}</p>
              <div>
                <h3>Frame Metadata</h3>
                <ul>
                  <li v-for="(value, key) in selectedFrame.metaData" :key="key">
                    <strong>{{ key }}:</strong> {{ value }}
                  </li>
                </ul>
              </div>
              <div v-if="selectedFrame.site">
                <h3>Site Details</h3>
                <p><strong>Site ID:</strong> {{ selectedFrame.site.siteId }}</p>
                <p><strong>Site Ref:</strong> {{ selectedFrame.site.siteRef }}</p>
                <p>
                  <strong>Site Name:</strong> {{ selectedFrame.site.siteName }}
                </p>
                <div>
                  <h4>Site Address</h4>
                  <ul>
                    <li
                      v-for="(value, key) in selectedFrame.site.siteAddress"
                      :key="key"
                    >
                      <strong>{{ key }}:</strong> {{ value }}
                    </li>
                  </ul>
                </div>
                <div>
                  <h4>Site Inventory</h4>
                  <ul>
                    <li
                      v-for="(value, key) in selectedFrame.site.siteInventory"
                      :key="key"
                    >
                      <strong>{{ key }}:</strong> {{ value }}
                    </li>
                  </ul>
                </div>
                <div>
                  <h4>Site Metadata</h4>
                  <ul>
                    <li
                      v-for="(value, key) in selectedFrame.site.metaData"
                      :key="key"
                    >
                      <strong>{{ key }}:</strong> {{ value }}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>  
          </div>
  
          <div class="modal-footer">
            <button type="button" class="btn btn-outline-primary font-sm" data-bs-dismiss="modal"
                    style="min-width: 150px;">Close
            </button>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'FrameModal',
    props: {
      yesEvent: Function,
        selectedFrame: Object,
    },
    data() {
      return {
        model: streetmetrics.model,
        currentModal: null,
        modalElement: null,
        errorMessage: null,
      }
    },
    computed: {
  
  
    },
    mounted() {
      this.modalElement = document.getElementById('frameModal')
      this.currentModal = new bootstrap.Modal(this.modalElement)
  
      this.modalElement.addEventListener('hidden.bs.modal', () => {
        this.$emit('noEvent')
      })
  
      this.currentModal.show()
    },
    beforeDestroy() {
      this.modalElement.removeEventListener('hidden.bs.modal')
    },
    methods: {
      onSaveClick() {
        this.errorMessage = null
      },
  
      noHandler(e) {
        // hide the modal
        this.currentModal.hide()
      },
      onOkClick() {
        // call the parent's "yesEvent"
        if (this.yesEvent) {
          this.yesEvent()
        }
        // hide the modal
        this.noHandler()
      }
    }
  }
  </script>
  
  <style>
  
  </style>
  