<template>
  <div id="attribution-study-edit-modal" class="modal fade" tabindex="-1">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-body">
          <div class="d-flex align-items-center p-3">
            <div class="centered-circle flex-shrink-0 bg-secondary text-primary h-10 w-10">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="feather feather-code small-icon"
              >
                <polyline points="16 18 22 12 16 6"></polyline>
                <polyline points="8 6 2 12 8 18"></polyline>
              </svg>
            </div>
            <div class="ms-3">
              <h3 class="fs-5 spacing-close">Edit Attribution Study</h3>
            </div>
          </div>

          <!-- Content Here -->
          <form>
            <div class="mb-3">
              <label for="deliveryDate" class="form-label">Delivery Date</label>
              <input
                v-model="deliveryDate"
                type="date"
                class="form-control"
                id="deliveryDate"
              />
            </div>

            <div class="mb-3">
              <label for="notes" class="form-label">Notes</label>
              <textarea
                v-model="notes"
                class="form-control"
                id="notes"
              ></textarea>
            </div>

            <p>Custom Cuts</p>
            <div v-for="(cut, index) in cuts" :key="index" class="mb-3">
              <div class="d-flex align-items-center">
                <div class="me-2 flex-grow-1">
                  <select class="form-select" v-model="cut.cutOrderOne">
                    <option value="" disabled selected>Select Cut 1</option>
                    <option v-for="cutOption in cutOptions" :value="cutOption">
                      {{ cutOption }}
                    </option>
                  </select>
                </div>
                <div class="me-2 flex-grow-1">
                  <select class="form-select" v-model="cut.cutOrderTwo">
                    <option value="" disabled selected>Select Cut 2</option>
                    <option value=""></option>
                    <option v-for="cutOption in cutOptions" :value="cutOption">
                      {{ cutOption }}
                    </option>
                  </select>
                </div>
                <button
                  type="button"
                  class="btn btn-danger btn-sm"
                  @click="removeCut(index)"
                >
                  <font-awesome-icon :icon="['fas', 'minus']" />
                </button>
              </div>
            </div>

            <button type="button" class="btn btn-secondary" @click="addCut()">
              <font-awesome-icon :icon="['fas', 'plus']" class="pe-1" /> Add
              Custom Cut
            </button>
          </form>
          <!-- end content -->
        </div>

        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-secondary font-sm"
            style="min-width: 150px"
            @click="onOkClick"
          >
            Ok
          </button>
          <button
            type="button"
            class="btn btn-outline-primary font-sm"
            data-bs-dismiss="modal"
            style="min-width: 150px"
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as dayjs from "dayjs";

export default {
  name: "AttributionStudyEditModal",
  props: {
    yesEvent: Function,
    study: Object,
    cuts: {
      type: Array,
      default: () => [{ cutOrderOne: "", cutOrderTwo: "" }],
    },
    cutOptions: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      model: streetmetrics.model,
      currentModal: null,
      modalElement: null,
      errorMessage: null,
      notes: "",
      deliveryDate: "",
    };
  },
  computed: {},
  mounted() {
    this.modalElement = document.getElementById(
      "attribution-study-edit-modal"
    );
    this.currentModal = new bootstrap.Modal(this.modalElement);

    this.modalElement.addEventListener("hidden.bs.modal", () => {
      this.$emit("noEvent");
    });

    this.currentModal.show();
  },
  beforeDestroy() {
    this.modalElement.removeEventListener("hidden.bs.modal");
  },
  watch: {
    study: {
      immediate: true,
      handler(newValue) {
        this.deliveryDate = dayjs(newValue.deliveryDate * 1000).format(
          "YYYY-MM-DD"
        );
        this.notes = newValue.notes;
      },
    },
  },
  methods: {
    addCut() {
      this.cuts.push({ cutOrderOne: "", cutOrderTwo: "" });
    },
    removeCut(index) {
      this.cuts.splice(index, 1);
    },
    onSaveClick() {
      this.errorMessage = null;
    },

    noHandler(e) {
      // hide the modal
      this.currentModal.hide();
    },
    onOkClick() {
      // call the parent's "yesEvent"
      if (this.yesEvent) {
        const formattedDate = dayjs(this.deliveryDate).unix();
        this.yesEvent(this.study, formattedDate, this.notes, this.cuts);
      }
      // hide the modal
      this.noHandler();
    },
  },
};
</script>

<style></style>
