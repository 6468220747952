<template>
  <div class="p-4">
    <div class="mb-3 d-flex justify-content-between">
      <div class="d-flex align-items-center">
        <h2>Attribution Jobs</h2>
      </div>

      <div>
        <router-link :to="{ name: 'attribution' }" class="btn btn-secondary">
          Back to Attribution Studies
        </router-link>
      </div>
    </div>

    <jobs-table :key="jobsTableKey" :jobs="attributionJobs" :display-all="true" @refreshJobsEvent="loadJobs"></jobs-table>
  </div>
</template>

<script>
import Datepicker from 'vue3-date-time-picker';
import 'vue3-date-time-picker/dist/main.css'
import AppUtil from "../utils/AppUtil";
import DeviceService from "../services/DeviceService";
import JobService from "../services/JobService";
import JobsTable from "../components/JobsTable";

export default {
  name: 'Attribution',
  components: {JobsTable, Datepicker},
  data() {
    return {
      model: streetmetrics.model,
      attributionJobs: [],
      jobsTableKeyIndex: 0
    }
  },
  computed: {
    jobsTableKey() {
      return 'attributionJobsTable' + this.jobsTableKeyIndex
    }
  },
  mounted() {
    this.loadJobs()
  },
  methods: {
    loadJobs() {
      console.log('Attribution loadJobs')
      this.model.loading = true
      let service = new JobService()
      service.getAttributionJobs().then((response) => {
        this.model.loading = false
        this.attributionJobs = response.data
        this.$forceUpdate()
        this.jobsTableKeyIndex += 1
      }).catch((error) => {
        console.log('Error loading jobs')
        console.log(error)
        this.model.loading = false
      })
    }
  }
}
</script>

<style>

</style>
