<template>
  <div class="px-4 pt-4">

    <div class="d-flex">
      <div class="pt-1">
        <strong>Campaign Details:</strong> {{ campaign.campaignRef }}
      </div>

      <div class="ps-3">
        <button class="btn btn-sm btn-info" @click="onCustomerClick()">Back to Campaigns</button>
      </div>
    </div>

    <div class="row w-100-pct">
      <div class="col-12 col-md-6">
        <pixel-requests-card v-if="campaign.campaignId" :campaign="campaign" :customer="customer"
                             :view-model="viewModel"></pixel-requests-card>
      </div>
      <div class="col-12 col-md-6">
        <ad-groups-card v-if="campaign.campaignId" :campaign="campaign" :view-model="viewModel"></ad-groups-card>
      </div>
    </div>
  </div>
</template>

<script>
import ArrayUtil from "../utils/ArrayUtil";
import PixelService from "../services/PixelService";
import AdGroupService from "../services/AdGroupService";
import FormattedDateTime from "../components/FormattedDateTime";
import PixelRequestsCard from "../components/PixelRequestsCard";
import AdGroupsCard from "../components/AdGroupsCard";
import CampaignService from "../services/CampaignService";

export default {
  name: 'CampaignDetails',
  components: {AdGroupsCard, PixelRequestsCard, FormattedDateTime},
  props: ['customer', 'viewModel'],
  data() {
    return {
      model: streetmetrics.model,
      campaign: {
        campaignId: null,
        campaignRef: "",
        customerId: -1,
        customerName: ""
      }
    }
  },
  mounted() {
    const campaignId = this.viewModel.currentCampaignId
    const arrayUtil = new ArrayUtil()
    this.campaign = arrayUtil.find(this.model.campaigns, campaignId, 'campaignId')
  },
  methods: {
    onCustomerClick() {
      this.viewModel.childView = 'campaigns'
    }
  }
}
</script>

<style>

</style>
