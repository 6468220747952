import RestService from "@/services/RestService";

export default class CampaignExplorerService  extends RestService {

    constructor() {
        super('campaign-explorer')
    }

    indexRequest() {
        let url = this.baseUrl + '/v3/api/campaign-explorer'
        return this.executeGet(url)
    }

    getCampaignDetails(campaign) {
        let url = this.baseUrl + `/v3/api/campaign-explorer/campaign/${campaign.campaignId}/${campaign.customerId}`
        return this.executeGet(url)
    }

    getMileageByDate(campaign) {
        let url = this.baseUrl + `/v3/api/campaign-explorer/mileage-by-date/${campaign.campaignId}/${campaign.customerId}`
        return this.executeGet(url)
    }

    getDeviceCountByDate(campaign) {
        let url = this.baseUrl + `/v3/api/campaign-explorer/device-count-by-date/${campaign.campaignId}/${campaign.customerId}`
        return this.executeGet(url)
    }
}