<template>
    <div id="assetOwnerModal" class="modal fade" tabindex="-1">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-body">

                    <div class="d-flex align-items-center p-3">
                        <div class="centered-circle flex-shrink-0 bg-secondary text-primary h-10 w-10">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                                stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                                class="feather feather-code small-icon">
                                <polyline points="16 18 22 12 16 6"></polyline>
                                <polyline points="8 6 2 12 8 18"></polyline>
                            </svg>
                        </div>
                        <div class="ms-3">
                            <h3 class="fs-5 spacing-close">Edit {{ assetOwner.name }}</h3>
                        </div>
                    </div>
                    <form>
                        <div class="mb-3">
                            <label class="form-label">Default Market</label>
                            <select class="form-select mb-2" v-model="selectedMarket">
                                <option v-for="(marketOption) in marketOptions" :key="marketOption.marketId"
                                    :value="marketOption">
                                    {{ marketOption.marketName }}
                                </option>
                            </select>
                        </div>
                    </form>

                </div>

                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary font-sm" style="min-width: 150px;"
                        @click="onSaveClick">Save
                    </button>
                    <button type="button" class="btn btn-outline-primary font-sm" data-bs-dismiss="modal"
                        style="min-width: 150px;">Cancel
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import MarketService from "@/services/MarketService";
import RestService from "@/services/RestService";
import _ from "lodash"

export default {
    name: 'AssetOwnerModal',
    props: {
        yesEvent: Function,
        assetOwner: Object,
    },
    data() {
        return {
            model: streetmetrics.model,
            currentModal: null,
            modalElement: null,
            validationMessage: null,
            marketOptions: [],
            service: new RestService("asset-owners"),
            selectedMarket: {},
            currentAssetOwner: {
                defaultMarketId: null
            }
        }
    },
    mounted() {
        console.log("AssetOwner modal mounted")
        const marketService = new MarketService()
        marketService.list()
            .then((response) => {
                const activeMarkets = response.data
                    .filter(market => (market.metaData && market.metaData.isActive))
                this.marketOptions = _.sortBy(activeMarkets, "marketName")
                const defaultMarketValue = this.marketOptions
                    .find((marketOption) => marketOption.marketId === this.assetOwner.defaultMarketId);
                this.selectedMarket = defaultMarketValue || {};
            })
            .catch((error) => {
                console.log('Error fetching market options:', error);
            });

        this.modalElement = document.getElementById('assetOwnerModal')
        this.currentModal = new bootstrap.Modal(this.modalElement)
        this.modalElement.addEventListener('hidden.bs.modal', () => {
            this.$emit('noEvent')
        })
        this.currentModal.show()
    },
    beforeDestroy() {
        this.modalElement.removeEventListener('hidden.bs.modal')
    },
    methods: {
        onSaveClick() {
            this.currentAssetOwner.defaultMarketId = this.selectedMarket.marketId
            this.service.update(this.assetOwner.assetOwnerId, this.currentAssetOwner)
                .then((response) => {
                    this.yesHandler()
                    streetmetrics.stateManager.loadAssetOwners()
                }
                ).catch((error) => {
                    console.log(error)
                })
        },

        noHandler(e) {
            // hide the modal
            this.currentModal.hide()
        },
        yesHandler() {
            // call the parent's "yesEvent"
            if (this.yesEvent) {
                this.yesEvent()
            }
            // hide the modal
            this.noHandler()
        }
    }
}
</script>