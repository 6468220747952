<template>
  <div class="m-3 pe-3">

    <div class="mt-4">
      <h4>Select Places for {{ attributionStudy.studyName }} Attribution Study</h4>

      <button class="btn btn-secondary mt-3" @click="onSaveClick">Save Selected Locations</button>
    </div>

    <div v-if="places.length > 0" class="table-responsive table-container scrollable-container mt-2">
      <table class="table">
        <thead class="bg-gray-500 text-uppercase">
        <tr>
          <th></th>
          <th>Name</th>
          <th>Address</th>
          <th>City</th>
          <th>Region</th>
          <th>Postal Code</th>
          <th>Country</th>
          <th>Geo Type</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="place in places">
          <td>
            <input type="checkbox" id="checkbox" v-model="place.selected" @change="onPlaceSelected(place)">
          </td>
          <td>
            <router-link :to="{ name: 'place-details', params: {id: place.placeId} }">
              {{ place.placeRef }}
            </router-link>
          </td>
          <td>{{ place.placeAddress }}</td>
          <td>{{ place.placeCity }}</td>
          <td>{{ place.placeRegion }}</td>
          <td>{{ place.placePostal }}</td>
          <td>{{ place.placeIsoCode }}</td>
          <td>{{ place.geometryType }}</td>
        </tr>
        </tbody>
      </table>
    </div>

  </div>
</template>

<script>

import PlaceService from "@/services/PlaceService";
import AttributionService from "@/services/AttributionService";

export default {
  name: 'SelectAttributionLocations',
  data() {
    return {
      // eslint-disable-next-line no-undef
      model: streetmetrics.model,
      service: new AttributionService(),
      attributionStudy: {
        attributionId: null,
        studyName: '',
        metaData: null
      },
      existingSelections: {},
      places: []
    }
  },
  computed: {
    selectedLocations() {
      return this.places.filter((place) => {
        return place.selected
      })
    }
  },
  mounted() {
   let attributionId = this.$route.params.id
    this.service.get(attributionId).then((response) => {
      this.attributionStudy = response.data

      console.log('Loaded attribution study')
      console.log(this.attributionStudy.metaData.locations)

      if (this.attributionStudy.metaData.locations && this.attributionStudy.metaData.locations.length > 0) {
        this.initExistingLocationsTable() // this will make loading existing selections fast
      }

      console.log(this.attributionStudy.studyName)
      this.loadPlaces()
    }).catch((message) => {
      console.log('error loading attribution study')
      console.log(message)
    })
  },

  methods: {
    loadPlaces() {
      const service = new PlaceService()
      service.list().then((response) => {
        let existingPlaces = response.data

        for (let i = 0; i < existingPlaces.length; i++) {
          existingPlaces[i].selected = !!this.existingSelections[existingPlaces[i].placeId]
          console.log(existingPlaces[i].placeRef + ', selected? ' + existingPlaces[i].selected)
        }

        this.places = existingPlaces
      }).catch((message) => {
        console.log('error loading places')
        console.log(message)
      })
    },
    onPlaceSelected(place) {
      console.log('Place clicked')
      console.log(place.placeRef + ', selected? ' + place.selected)
    },

    onSaveClick() {
      this.model.loading = true
      this.attributionStudy.metaData.locations = this.selectedLocations
      //this.attributionStudy.metaData.locations = []
      this.service.update(this.attributionStudy.attributionId, this.attributionStudy).then((response) => {
        console.log('Locations saved for attribution study')
        console.log(response)
        this.model.loading = false
        this.$router.push({name: 'attribution'})
      }).catch((error) => {
        console.log('error saving locations')
        console.log(error)
        this.model.loading = false
      })
    },

    initExistingLocationsTable() {
      for (let i = 0; i < this.attributionStudy.metaData.locations.length; i++) {
        let existingLocation = this.attributionStudy.metaData.locations[i]
        this.existingSelections[existingLocation.placeId] = existingLocation
      }

      console.log('initExistingLocationsTable')
      console.log(this.existingSelections)
    }
  }
}
</script>

<style>

</style>
