import dayjs from "dayjs";

export default class AppUtil {

  getBaseUrl() {
    if (process.env.NODE_ENV === "development") {
      return "http://localhost:3000";
    }

    if (location.hostname === "admin-test.streetmetrics.io") {
      return "https://helios-dev.streetmetrics.io";
    }
    
    return "https://dashboard.streetmetrics.io";
  }

  getPipelineUrl() {
    if (process.env.NODE_ENV === "development") {
      return "http://localhost:8080";
    }

    return "https://process.streetmetrics.io";
  }

  formatStartAndEndDates(startDate, endDate) {
    const dateFormatOptions = {year: "numeric", month: "2-digit", day: "2-digit"}
    let startFilter = startDate.toLocaleDateString('en-CA', dateFormatOptions).replaceAll('/', '-')
    let endFilter = endDate.toLocaleDateString('en-CA', dateFormatOptions).replaceAll('/', '-')
    return {start: startFilter, end: endFilter};
  }

  getDayLabel(dayOfWeek) {
    if (dayOfWeek === 0) {
      return 'Sunday'
    } else if (dayOfWeek === 1) {
      return 'Monday'
    } else if (dayOfWeek === 2) {
      return 'Tuesday'
    } else if (dayOfWeek === 3) {
      return 'Wednesday'
    } else if (dayOfWeek === 4) {
      return 'Thursday'
    } else if (dayOfWeek === 5) {
      return 'Friday'
    } else if (dayOfWeek === 6) {
      return 'Saturday'
    }
  }

  getDateStrings(startDateString, endDateString) {
    const dateStrings = []
    const startDate = dayjs(startDateString)
    const endDate = dayjs(endDateString)

    let currentDate = startDate

    while (!currentDate.isAfter(endDate)) {
      dateStrings.push(currentDate.format('YYYY-MM-DD'));
      currentDate = currentDate.add(1, 'day');
    }

    return dateStrings
  }

  getFilterDates(filterType) {
    const start = new Date();
    start.setHours(0, 0, 0, 0);
    const end = new Date();
    end.setHours(23, 59, 59, 999);

    let days = 1

    if (filterType === 'last7') {
      days = 7
    } else if (filterType === 'last30') {
      days = 30
    } else if (filterType === 'last3') {
      days = 3
    }

    start.setDate(start.getDate() - days);
    end.setDate(end.getDate() - 1);
    return {start: start, end: end};
  }

  getImportantMarkets() {
    let importantMarkets = ['Boston', 'Detroit', 'Flint-Saginaw-Bay City', 'Lansing', 'Las Vegas', 'Miami-Ft. Lauderdale',
      'Phoenix', 'SAN FRANCISCO-OAK-SAN JOSE', 'Washington, DC', 'Atlanta', 'Chicago', 'Los Angeles', 'MINNEAPOLIS-ST. PAUL',
      'New York', 'PHILADELPHIA', 'Portland, OR', 'SEATTLE-TACOMA']

    let matches = []

    for( let i = 0; i < streetmetrics.model.markets.length; i++ ) {
      for( let j = 0; j < importantMarkets.length; j++ ) {

        if(streetmetrics.model.markets[i].marketName === importantMarkets[j].toUpperCase()) {
          matches.push(streetmetrics.model.markets[i])
          continue
        }
      }
    }

    return matches
  }
}
