import RestService from "./RestService";

export default class JobService  extends RestService {

  constructor() {
    super('queue')
  }

  getJobs() {
    let url = this.baseUrl + '/v3/internal/queue'
    return this.executeGet(url)
  }

  getJob(id) {
    let url = this.baseUrl + '/v3/internal/queue/' + id
    return this.executeGet(url)
  }

  getAttributionJobs() {
    const url = this.baseUrl + '/v3/api/attribution/jobs'
    return this.executeGet(url)
  }

  getProcessingJobs() {
    let url = this.baseUrl + '/v3/api/jobs/index'
    return this.executeGet(url)
  }

  getProcessingJobsByMarket(marketName) {
    let url = this.baseUrl + `/v3/api/jobs/market-name/${marketName}`
    return this.executeGet(url)
  }
}
