import { createApp } from 'vue';
import App from './App.vue';
import './assets/css/street-metrics.css';
import { vfmPlugin } from 'vue-final-modal';

require('dotenv').config();

import 'ag-grid-enterprise';
import { LicenseManager } from 'ag-grid-enterprise';

import VueApexCharts from 'vue3-apexcharts';

import { MapboxMap, MapboxMarker, MapboxNavigationControl, MapboxSource, MapboxLayer } from '@studiometa/vue-mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';

if (process.env.VUE_APP_AG_GRID_LICENSE) {
  LicenseManager.setLicenseKey(process.env.VUE_APP_AG_GRID_LICENSE);
}

console.log('Hello from Streetmetrics!');

import * as bootstrap from './assets/js/bootstrap';
window.bootstrap = bootstrap;

import streetmetrics from './model';
import AppUtil from '@/utils/AppUtil';
import ChartUtil from '@/utils/ChartUtil';
import router from './router';
import { library } from '@fortawesome/fontawesome-svg-core';
import ICONS from './icon.constants';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import StringUtil from "@/utils/StringUtil";
import ArrayUtil from "@/utils/ArrayUtil";
import mitt from "mitt";
import MarketUtil from "@/utils/MarketUtil";

window.streetmetrics = streetmetrics;
streetmetrics.utils.app = new AppUtil();
streetmetrics.utils.chart = new ChartUtil();
streetmetrics.utils.string = new StringUtil();
streetmetrics.utils.array = new ArrayUtil();
streetmetrics.utils.market = new MarketUtil();
window.streetmetrics.router = router;

streetmetrics.eventDispatcher = mitt()

library.add(...ICONS);

const app = createApp(App);
app.use(router);
app.use(VueApexCharts);
app.use(vfmPlugin);
app.component('font-awesome-icon', FontAwesomeIcon);
app.component('MapboxMap', MapboxMap);
app.component('MapboxMarker', MapboxMarker);
app.component('MapboxNavigationControl', MapboxNavigationControl);
app.component('MapboxSource', MapboxSource);
app.component('MapboxLayer', MapboxLayer);
app.mount('#app');
