import http from 'superagent'
import RestService from "./RestService";
import AppUtil from "../utils/AppUtil";

export default class UserService extends RestService {
  constructor() {
    super('users')
    this.baseUrl = new AppUtil().getBaseUrl()
  }

  currentUser() {
    return new Promise((resolve, reject) => {
      http.get(this.baseUrl + '/v3/auth/me')
        .set('Accept', 'application/json')
        .set('Authorization', 'Bearer ' + streetmetrics.model.authToken)
        .end((error, response) => {

          if (response.status === 200 || response.status === 201) {
            const user = JSON.parse(response.text);
            resolve(user)
          } else {
            reject(JSON.parse(response.text))
          }

        })
    })
  }

  login(email, password) {
    const model = {
      email: email,
      password: password
    };

    return new Promise((resolve, reject) => {
      http.post(this.baseUrl + '/v3/auth/authenticate')
        .send(model)
        .set('Accept', 'application/json')
        .end((error, response) => {

          if (response.status === 200 || response.status === 201) {
            const user = JSON.parse(response.text);
            resolve(user)
          } else {
            reject(JSON.parse(response.text))
          }

        })
    })
  }
}
