export default class ChartUtil {

    formatHourlyPixelData(data, homePage, productPage, checkoutPage) {
        let formattedResults = []
        let days = Object.keys(data)

        days.forEach((day) => {
            let visitData = {
                day: Number(day),
                homePageVisits: data[day].pages[homePage] ? data[day].pages[homePage].visits : 0,
                productPageVisits: productPage && data[day].pages[productPage] ? data[day].pages[productPage].visits : 0,
                checkoutPageVisits: checkoutPage && data[day].pages[checkoutPage] ? data[day].pages[checkoutPage].visits : 0
            }
            formattedResults.push(visitData)
        });

        return formattedResults
    }

    formatHourlyPixelDataForApex(data, homePage, productPage, checkoutPage) {
        let seriesData = []
        let hours = Object.keys(data)
        let homePageData = []
        let productPageData = []
        let checkoutPageData = []


        hours.forEach((hour) => {
            const hourOfDay = Number(hour)
            let homePageVisits = data[hourOfDay].pages[homePage] ? data[hourOfDay].pages[homePage].visits : 0
            let productPageVisits = productPage && data[hourOfDay].pages[productPage] ? data[hourOfDay].pages[productPage].visits : 0
            let checkoutPageVisits = checkoutPage && data[hourOfDay].pages[checkoutPage] ? data[hourOfDay].pages[checkoutPage].visits : 0
            homePageData.push(homePageVisits)
            productPageData.push(productPageVisits)
            checkoutPageData.push(checkoutPageVisits)
        });


        seriesData.push( { name: checkoutPage, data: checkoutPageData })
        seriesData.push( { name: productPage, data: productPageData })
        seriesData.push( { name: homePage, data: homePageData })
        return seriesData
    }

    homePageVisitsByDayChartData(attributionData, page) {
        let chartData = []
        let daysOfWeek = [0, 1, 2, 3, 4, 5, 6]

        daysOfWeek.forEach((dayOfWeek) => {
            let visits = this.getPageVisits(attributionData, dayOfWeek, page)
            let weekDay = streetmetrics.utils.app.getDayLabel(dayOfWeek)
            chartData.push({name: weekDay, count: visits})
        });

        console.log('ChartUtil.homePageVisitsByDayChartData')
        console.log(chartData)
        return chartData
    }

    getPageVisits(attributionData, dayOfWeek, page) {
        const pageData = attributionData[dayOfWeek].pages[page]

        if (pageData) {
            return pageData.visits
        }

        return 0
    }

}