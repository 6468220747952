<template>
  <div>
    <span v-if="fileCount === 0" class="badge bg-danger">No Files</span>
    <span v-else class="badge bg-green-700">{{fileCount}} files</span>
  </div>
</template>

<script>
import MarketService from "../services/MarketService";

export default {
  name: 'MarketFilesCount',
  props: ['marketHash'],
  data () {
    return {
      model: streetmetrics.model,
      fileCount: -1
    }
  },
  mounted () {
    let service = new MarketService()
    service.loadS3Files(this.marketHash).then((response) => {
      this.fileCount = response.Contents.length
    }).catch((error) => {
      console.log('Error s3 files for market: ' + this.marketHash)
      console.log(error)
    })
  }
}
</script>

<style>

</style>
