<template>
  <div>
    <h2 class="my-3">Report Explorer - Market: {{marketName}}</h2>
    <div class="d-flex align-items-center">

      <div class="w-40">
        <select class="form-select" v-model="marketIdFilter" @change="loadImpressions()">
          <option v-for="market in markets" :value="market.marketId">
            {{ market.marketName }}
          </option>
        </select>
      </div>
    </div>

    <div v-if="impressionsData" class="my-3 chartContainer">
      <apex-bar-chart :chart-data="seriesData" :axis-data="axisData"></apex-bar-chart>
    </div>
  </div>
</template>

<script>
import ReportingService from "@/services/ReportingService";
import ApexBarChart from "@/charts/ApexBarChart.vue";

export default {
  name: 'ReportExplorer',
  components: {ApexBarChart},
  props: ['prop1', 'prop2'],
  data() {
    return {
      model: streetmetrics.model,
      startDate: '2024-08-17',
      endDate: '2024-08-31',
      impressionsData: null,
      marketIdFilter: 2
    }
  },
  computed: {
    markets() {
      return this.model.markets.filter((market) => {
        return market.metaData && market.metaData.countryCode === 'USA' && market.marketMetrics && Number(market.marketMetrics.marketpop) > 4000000
      })
    },
    marketName() {
      return this.markets.find((market) => {
        return market.marketId === this.marketIdFilter
      }).marketName
    },
    seriesData() {
      return [
        {
          name: 'Athena',
          data: this.impressionsData.map((row) => {
            return row.SUMMED_IMPRESSIONS_OLD
          })
        },
        {
          name: 'OTS (New Pipeline)',
          data: this.impressionsData.map((row) => {
            return row.SUMMED_LTS + row.SUMMED_OTS
          })
        }
      ]
    },
    axisData() {
      return this.impressionsData.map((row) => {
        return row.IMPRESSION_DATE
      })
    }
  },
  mounted() {
    console.log('ReportExplorer mounted')
    console.log(this.model.markets)
    this.loadImpressions()
  },
  methods: {
    loadImpressions() {
      this.model.loading = true
      const service = new ReportingService();
      service.compareImpressions(this.marketIdFilter, this.startDate, this.endDate).then((response) => {
        console.log('Compare Impressions success')
        console.log(response)
        this.impressionsData = response.data
        this.model.loading = false
      }).catch((error) => {
        console.log('Compare Impressions error')
        console.log(error)
        this.model.loading = false
      });
    }
  }
}
</script>
