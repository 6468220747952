export default class MarketUtil {

    getMarketHashTable() {
        const markets = {}

        for (let i = 0; i < streetmetrics.model.markets.length; i++) {
            const market = streetmetrics.model.markets[i];
            markets[market.marketHash] = market
        }

        return markets
    }

    getMarketByName(marketName) {
        for (let i = 0; i < streetmetrics.model.markets.length; i++) {
            const market = streetmetrics.model.markets[i];
            if( market.marketName === marketName) {
                return market
            }
        }

        return null
    }

    sortByPopulation(marketList) {
        marketList.sort(function(market1, market2) {
            let fieldA = Number(market1.marketMetrics.marketpop)
            let fieldB =  Number(market2.marketMetrics.marketpop)

            if(fieldA === fieldB) {
                return 0
            }

            return fieldA < fieldB ? 1 : -1
        })
    }
}