export default class JobUtil {
    validUntil(job, fileKey) {
        const url = this.getDownloadUrl(job, fileKey)
        const expiresString = '&Expires='
        const expiresIndex = url.indexOf(expiresString)
        const queryParameters = url.substring(expiresIndex + expiresString.length)
        let expiresAt = 0

        if (queryParameters.indexOf('&') > 0) {
            expiresAt = queryParameters.substring(0, queryParameters.indexOf('&'))
        } else {
            expiresAt = queryParameters
        }

        const date = new Date(Number(expiresAt) * 1000)
        return date
    }

    getValidUntilLabel(job, fileKey) {
        const date = this.validUntil(job, fileKey)
        const dateFormatOptions = {year: "numeric", month: "short", day: "numeric"}
        return date.toLocaleDateString('en-us', dateFormatOptions)
    }

    getDownloadUrl(job, fileKey) {
        return job.result[fileKey]
    }
}