import RestService from "./RestService";

export default class AdGroupService  extends RestService {

  constructor() {
    super('flights')
  }

  getCampaignAdGroups(customerId, campaignId) {
    let url = this.baseUrl + '/v3/api/flights/ad-groups/' + customerId + '/' + campaignId
    return this.executeGet(url)
  }

}
