import http from 'superagent';
import AppUtil from '../utils/AppUtil';

export default class HttpService {

    executeGet(url) {
        return new Promise((resolve, reject) => {
            http
                .get(url)
                .set('Accept', 'application/json')
                .end((error, response) => {
                    if (response && response.status === 200) {
                        resolve(response.text);
                    } else {
                        if (response) {
                            reject(response.text);
                        } else {
                            reject(error);
                        }
                    }
                });
        });
    }
}
