<template>
  <ul class="nav nav-tabs">
    <li class="nav-item">
      <a href="javascript:void(0)" class="nav-link" :class="isActive('markets') ? 'active' : ''"
         @click="onTabClick('markets')">Markets</a>
    </li>
    <li class="nav-item">
      <a href="javascript:void(0)" class="nav-link" :class="isActive('campaign') ? 'active' : ''"
         @click="onTabClick('campaigns')">Campaigns</a>
    </li>
    <li class="nav-item">
      <a href="javascript:void(0)" class="nav-link" :class="isActive('vehicles') ? 'active' : ''"
         @click="onTabClick('vehicles')">Vehicles</a>
    </li>
    <li class="nav-item">
      <a href="javascript:void(0)" class="nav-link" :class="isActive('users') ? 'active' : ''"
         @click="onTabClick('users')">Users</a>
    </li>
  </ul>
</template>

<script>
export default {
  name: 'CustomerTabs',
  props: ['activeTab', 'viewModel'],
  data() {
    return {
      model: streetmetrics.model
    }
  },
  methods: {
    isActive(tabLabel) {
      return this.viewModel.childView.indexOf(tabLabel) > -1
    },
    onTabClick(tabLabel) {
      this.$emit('tabChangedEvent', tabLabel)
    }
  }
}
</script>

<style>

</style>

