<template>
  <div class="pe-4">

    <nav class="pt-3" aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <a href="javascript:void(0)" @click="onCustomerClick()">{{ customer.customerName }}</a>
        </li>
        <li class="breadcrumb-item">
          <a href="javascript:void(0)" @click="onCampaignClick()">{{ campaign.campaignRef }} Campaign</a>
        </li>
        <li class="breadcrumb-item active" aria-current="page">
          {{ viewModel.currentPixel.metaData.page }} - Latest Pixel Requests <span v-if="pixelRequests.length > 0"
                                                                                   class="badge bg-pink font-sm">{{
            pixelRequests.length.toLocaleString()
          }}</span>
        </li>
      </ol>
    </nav>

    <div class="card mb-3">
      <div class="card-body">

        <div class="row w-100-pct">

          <div class="mb-3 col-12 col-md-6 px-2">

            <div class="d-flex">
              <div class="w-32 mt-2">
                <label>Date Filter</label>
              </div>
              <div class="me-2 w-48">
                <select class="form-select mb-2" v-model="dateFilter" @change="getFilterDates">
                  <option value="yesterday">Yesterday</option>
                  <option value="last3">Last 3 Days</option>
                  <option value="last7">Last 7 Days</option>
                  <option value="last30">Last 30 Days</option>
                  <option value="custom">Custom</option>
                </select>
                <!--                <div v-if="dateFilter !== 'custom' && startDate && endDate" class="form-text text-secondary font-xbold">-->
                <!--                  {{ startDate.toLocaleDateString("en-US") }} - {{ endDate.toLocaleDateString("en-US") }}-->
                <!--                </div>-->
              </div>

              <div v-if="dateFilter === 'custom'" class="w-100-pct">
                <Datepicker class="mb-2" v-model="dates" :range="true" :clearable="false"
                            :enableTimePicker="false" @closed="onDateClosed"
                            @blur="onDateSelected"></Datepicker>
                <!--                <div v-if="startDate && endDate" class="form-text text-secondary font-xbold">-->
                <!--                  {{ startDate.toLocaleDateString("en-US") }} - {{ endDate.toLocaleDateString("en-US") }}-->
                <!--                </div>-->
                <div v-if="dateFilter === 'custom' && (startDate === null || endDate === null)"
                     class="form-text text-danger font-xbold">
                  Please select a start and end date.
                </div>
              </div>
            </div>

          </div>

          <div class="col-12 col-md-4">
            <button class="btn btn-secondary btn-wide mx-1" @click="onGetPixelsClick">
              Get Pixels
            </button>

            <button class="btn btn-secondary btn-wide mx-1" @click="onRunPixelJobClick">
              Run Pixels Job
            </button>

          </div>

        </div>

      </div>
    </div>

    <div class="mt-3">

      <ul class="nav nav-tabs">
        <li class="nav-item">
          <a href="javascript:void(0)" class="nav-link" :class="currentTab === 'jobs' ? 'active' : ''"
             @click="onTabClick('jobs')">Jobs</a>
        </li>
        <li v-if="pixelRequests.length > 0" class="nav-item">
          <a href="javascript:void(0)" class="nav-link" :class="currentTab === 'pixels' ? 'active' : ''"
             @click="onTabClick('pixels')">Pixel Requests</a>
        </li>
      </ul>

      <jobs-table v-if="currentTab === 'jobs'" :jobs="jobs"></jobs-table>

      <div v-else-if="currentTab === 'pixels'" class="table-responsive table-container user-container mt-2">
        <table class="table">
          <thead class="bg-gray-500 text-uppercase">
          <tr>
            <th>Device IP</th>
            <th>Time</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="pixelRequest in pixelRequests">
            <td>{{ pixelRequest.deviceIp }}</td>
            <td>
              <formatted-date-time :date-time="pixelRequest.deviceTime"></formatted-date-time>
            </td>
          </tr>
          </tbody>
        </table>

      </div>

      <!--      <div v-else-if="pixelRequestsLoaded && pixelRequests.length === 0" class="my-4 alert alert-warning">-->
      <!--        <strong>This pixel has not been triggered within the specified date filter.</strong>-->
      <!--      </div>-->

    </div>
  </div>
</template>

<script>
import PixelService from "../services/PixelService";
import ArrayUtil from "../utils/ArrayUtil";
import FormattedDateTime from "../components/FormattedDateTime";
import AppUtil from "../utils/AppUtil";
import Datepicker from 'vue3-date-time-picker';
import 'vue3-date-time-picker/dist/main.css'
import JobService from "../services/JobService";
import JobsTable from "../components/JobsTable";

export default {
  name: 'PixelDetails',
  components: {JobsTable, FormattedDateTime, Datepicker},
  props: ['viewModel', 'customer'],
  data() {
    return {
      model: streetmetrics.model,
      pixelService: new PixelService(),
      appUtil: new AppUtil(),
      pixelRequests: [],
      campaign: {
        campaignId: -1,
        campaignRef: ""
      },
      pixelRequestsLoaded: false,
      startDate: null,
      endDate: null,
      dateFilter: 'last3',
      dates: [],
      allJobs: [],
      currentTab: 'jobs'
    }
  },
  computed: {
    jobs() {
      const pixelId = this.viewModel.currentPixel.pixelId
      return this.allJobs.filter(job => (job.jobType === 'JOB_QUEUE__PIXELS_FILTER' && job.email === this.model.user.email
          && pixelId === job.metaData?.requestInfo?.pixelId))
    },
  },
  mounted() {
    const campaignId = this.viewModel.currentCampaignId
    const arrayUtil = new ArrayUtil()
    this.campaign = arrayUtil.find(this.model.campaigns, campaignId, 'campaignId')
    this.loadJobs();
  },
  methods: {
    loadJobs() {
      this.model.loading = true
      let service = new JobService()
      service.getJobs().then((response) => {
        this.model.loading = false
        this.allJobs = response.data
      }).catch((error) => {
        console.log('Error loading jobs')
        console.log(error)
        this.model.loading = false
      })
    },
    loadPixelData() {
      this.model.loading = true
      const pixelId = this.viewModel.currentPixel.pixelId
      let filterDates = this.getFilterDates()
      this.pixelService.loadPixelRequests(pixelId, filterDates.start, filterDates.end).then((response) => {
        this.model.loading = false
        this.pixelRequests = response.data
        this.pixelRequestsLoaded = true
        this.model.toast.background = 'bg-green'
        this.model.toast.message = "Pixels loaded."
        // setTimeout(() => {
        //   this.loadJobs()
        // }, 5000);
      }).catch((error) => {
        console.log('Error loading pixel data for pixel: ' + pixelId)
        console.log(error)
        this.model.loading = false
      })
    },
    loadPixelDataAsync() {
      this.model.loading = true
      const pixelId = this.viewModel.currentPixel.pixelId
      let filterDates = this.getFilterDates()
      this.pixelService.createPixelJob(pixelId, filterDates.start, filterDates.end, this.campaign.customerId, this.campaign.campaignId).then((response) => {
        this.model.loading = false
        // this.pixelRequests = response.data
        this.pixelRequestsLoaded = true
        this.model.toast.background = 'bg-green'
        this.model.toast.message = "Processed pixel download request."
        setTimeout(() => {
          this.loadJobs()
        }, 5000);
      }).catch((error) => {
        console.log('Error loading pixel data for pixel: ' + pixelId)
        console.log(error)
        this.model.loading = false
      })
    },
    onDateClosed() {
      this.onDateSelected()
    },
    onDateSelected() {
      console.log('onDateSelected')
      console.log(this.dates)
      this.startDate = this.dates[0]
      this.endDate = this.dates[1]
    },
    getFilterDates() {
      if (this.dateFilter !== 'custom') {
        const startAndEndDates = this.appUtil.getFilterDates(this.dateFilter)
        this.startDate = startAndEndDates.start
        this.endDate = startAndEndDates.end
        this.dates = [startAndEndDates.start, startAndEndDates.end]
        return this.formatDateFilters()
      } else if (this.startDate && this.endDate) {
        return this.formatDateFilters()
      }
    },
    onCustomerClick() {
      this.viewModel.childView = 'campaigns'
    },
    onCampaignClick() {
      this.viewModel.childView = 'campaign-details'
    },
    onGetPixelsClick() {
      this.loadPixelData()
    },
    onRunPixelJobClick() {
      this.loadPixelDataAsync()
    },
    formatDateFilters() {
      return this.appUtil.formatStartAndEndDates(this.startDate, this.endDate)
    },
    onTabClick(tabLabel) {
      this.currentTab = tabLabel
    }
  }
}
</script>

<style>

</style>
