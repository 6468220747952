<template>
  <div>
    {{pixelCount}}
  </div>
</template>

<script>
import PixelService from "../services/PixelService";

export default {
  name: 'PixelCount',
  props: ['campaignId'],
  data () {
    return {
      model: streetmetrics.model,
      pixelCount: 0
    }
  },
  mounted () {
    let service = new PixelService()
    service.getPixelCount(this.campaignId).then((response) => {
      this.pixelCount = response.data[0].pixels
    }).catch((error) => {
      console.log('Error loading pixels for campaign: ' + this.campaignId)
      console.log(error)
    })
  }
}
</script>

<style>

</style>
