<template>
  <div class="report-container p-4">

    <div class="mb-4">
      <h4 class="font-xbold text-uppercase text-primary">Campaign Overview</h4>

      <div class="my-3">
        <span class="text-secondary text-uppercase">Customer:</span> <span
          class="font-light text-primary">{{ customer }}</span>
      </div>

      <div class="my-3">
        <span class="text-secondary text-uppercase">Campaign:</span> <span
          class="font-light text-primary">{{ campaign }}</span>
      </div>

      <div class="my-3">
        <span class="text-secondary text-uppercase">Measurement Start Date:</span> <span
          class="font-light text-primary">{{ startDate }}</span>
      </div>

      <div class="my-3">
        <span class="text-secondary text-uppercase">Measurement End Date:</span> <span
          class="font-light text-primary">{{ endDate }}</span>
      </div>

    </div>


    <div v-if="reportData.exposed" class="my-1">

      <h4 class="font-xbold text-uppercase text-primary mb-3">Overview of Results</h4>

      <table class="table table-sm ">
        <thead>
        <tr class="bg-primary text-white">
          <th class="w-40 fw-bold text-center">Group</th>
          <th>Total Devices</th>
          <th>Unique Devices</th>
          <th>Frequency</th>
          <th>{{ homePage }} Visits</th>
          <th>{{ homePage }} Visitation Rate</th>
          <th v-if="reportData.pixels.length > 1">{{ productPage }} Visits</th>
          <th v-if="reportData.pixels.length > 1">{{ productPage }} Visitation Rate</th>
          <th v-if="reportData.pixels.length > 2">{{ checkoutPage }} Visits</th>
          <th v-if="reportData.pixels.length > 2">{{ checkoutPage }} Visitation Rate</th>
        </tr>
        </thead>
        <tbody>
        <tr>
          <td class="w-40">Exposed Group</td>
          <td>{{ reportData.exposed.totalDevices.toLocaleString() }}</td>
          <td>{{ reportData.exposed.uqDevices.toLocaleString() }}</td>
          <td>{{ exposedFrequency }}</td>
          <td>{{ exposedHomePageVisits }}</td>
          <td>{{ this.formatPercent(exposedHomePageVisitationRate) }}</td>
          <td v-if="reportData.pixels.length > 1">{{ exposedProductPageVisits }}</td>
          <td v-if="reportData.pixels.length > 1">{{ this.formatPercent(exposedProductPageVisitationRate) }}</td>
          <td v-if="reportData.pixels.length > 2">{{ exposedCheckoutPageVisits }}</td>
          <td v-if="reportData.pixels.length > 2">{{ this.formatPercent(exposedCheckoutPageVisitationRate) }}</td>
        </tr>
        <tr>
          <td class="w-40">Control Group</td>
          <td>{{ reportData.control.totalDevices.toLocaleString() }}</td>
          <td>{{ reportData.control.uqDevices.toLocaleString() }}</td>
          <td>{{ controlFrequency }}</td>
          <td>{{ controlHomePageVisits }}</td>
          <td>{{ this.formatPercent(controlHomePageVisitationRate) }}</td>
          <td v-if="reportData.pixels.length > 1">{{ controlProductPageVisits }}</td>
          <td v-if="reportData.pixels.length > 1">{{ this.formatPercent(controlProductPageVisitationRate) }}</td>
          <td v-if="reportData.pixels.length > 2">{{ controlCheckoutPageVisits }}</td>
          <td v-if="reportData.pixels.length > 2">{{ this.formatPercent(controlCheckoutPageVisitationRate) }}</td>
        </tr>
        </tbody>
      </table>

      <div id="chartContainer" class="mt-5 row d-flex">

        <div class="rounded-4 shadow col-30pct">
          <div class="card-body">
            <div class="font-xbold text-uppercase text-center text-primary mb-2">{{ homePage }} Lift</div>
            <bar-chart key="homePageLift" :chart-data="homePageChartData" :chart-label="homePage + ' Visitation Rate'"
                       chart-id="homeVisitationChart"></bar-chart>
            <div class="mt-3">
              <donut-chart :chart-data="homePageConfidenceIntervalChartData"
                           :confidence-interval="homePageConfidenceInterval"></donut-chart>
            </div>
          </div>
        </div>

        <div class="rounded-4 shadow col-30pct mx-4" v-if="reportData.pixels.length > 1">
          <div class="card-body">
            <div class="font-xbold text-uppercase text-center text-primary mb-2">{{ productPage }} Lift</div>
            <bar-chart key="productPageLift" :chart-data="productPageChartData"
                       :chart-label="productPage + ' Visitation Rate'"
                       chart-id="productVisitationChart"></bar-chart>
            <div class="mt-3">
              <donut-chart :chart-data="page2ConfidenceIntervalChartData"
                           :confidence-interval="page2ConfidenceInterval"></donut-chart>
            </div>
          </div>
        </div>

        <div class="rounded-4 shadow col-30pct" v-if="reportData.pixels.length > 2">
          <div class="card-body">
            <div class="font-xbold text-uppercase text-center text-primary mb-2">{{ checkoutPage }} Lift</div>
            <bar-chart key="checkoutPageLift" :chart-data="checkoutPageChartData"
                       :chart-label="checkoutPage + ' Visitation Rate'"
                       chart-id="checkoutVisitationChart"></bar-chart>
            <div class="mt-3">
              <donut-chart :chart-data="page3ConfidenceIntervalChartData"
                           :confidence-interval="page3ConfidenceInterval"></donut-chart>
            </div>
          </div>
        </div>

      </div>

      <div v-if="reportData.exposed" class="mt-5">

        <h3 class="font-xbold text-uppercase text-primary my-3">Overview of Results by Day of Week</h3>

        <h4 class="font-xbold text-uppercase text-primary mb-3">Exposed Group</h4>

        <day-of-week-attribution-data :attribution-data="reportData.exposed.dayOfWeek" :home-page="homePage"
                                      :checkout-page="checkoutPage"
                                      :product-page="productPage"
                                      :comparison-data="reportData.control.dayOfWeek"
                                      key="exposedDayOfWeekTable"></day-of-week-attribution-data>

        <div class="my-5">
          <bar-chart key="homePageVisitsDayOfWeek" :chart-data="homePageVisitsByDayChartData"
                     :chart-label="homePage + ' Visits'" value-format="integer"
                     :bar-colors="['#3b88a9', '#31638f', '#93ced3', '#93ced3', '#93ced3', '#3c8ead', '#c3e7dc']"
                     chart-id="homePageVisitsDayOfWeekChart" :chart-width="960" :chart-height="400"></bar-chart>
        </div>

        <h4 class="font-xbold text-uppercase text-primary my-3">Control Group</h4>

        <day-of-week-attribution-data :attribution-data="reportData.control.dayOfWeek" :home-page="homePage"
                                      :checkout-page="checkoutPage"
                                      :product-page="productPage"
                                      key="controlDayOfWeekTable"></day-of-week-attribution-data>

      </div>

      <div v-if="reportData.exposed" class="mt-5">

        <h3 class="font-xbold text-uppercase text-primary my-3">Overview of Results by Hour</h3>

        <div class="rounded-4 shadow">

          <div class="d-flex align-items-end">
            <h4 class="font-xbold text-uppercase text-primary ms-4 my-3">Hour of Exposure</h4>
          </div>

          <div class="my-4">
            <apex-line-chart :chart-data="hourExposureChartDataApex" :line-colors="lineColors"></apex-line-chart>
          </div>

        </div>

        <!--        <line-chart chart-id="hourOfExposureLineChart" :chart-data="hourExposureChartData" x-field="day"-->
        <!--                    :y-fields="lineChartFields" :y-labels="chartLabels" :line-colors="lineColors"-->
        <!--                    title="Hour of Exposure"></line-chart>-->

        <div class="rounded-4 shadow my-4">

          <div class="d-flex align-items-end">
            <h4 class="font-xbold text-uppercase text-primary ms-4 my-3">Hour of Visit</h4>
          </div>

          <div class="my-4">
            <apex-line-chart :chart-data="hourVisitChartData" :line-colors="lineColors"></apex-line-chart>
          </div>

        </div>

<!--        <line-chart chart-id="hourOfVisitLineChart" :chart-data="hourVisitChartData" x-field="day"-->
<!--                    :y-fields="lineChartFields" :y-labels="chartLabels" :line-colors="lineColors"-->
<!--                    title="Hour of Visit"></line-chart>-->
      </div>


    </div>

  </div>
</template>

<script>
import http from 'superagent'
import ArrayUtil from "../utils/ArrayUtil";
import DayOfWeekAttributionData from "../components/DayOfWeekAttributionTable";
import * as dayjs from "dayjs";
import JobUtil from "@/utils/JobUtil";
import BarChart from "@/charts/BarChart";
import LineChart from "@/charts/LineChart";
import ChartUtil from "@/utils/ChartUtil";
import StatsUtil from "@/utils/StatsUtil";
import DonutChart from "@/charts/DonutChart";
import streetmetrics from "@/model";
import ApexLineChart from "@/charts/ApexLineChart";

export default {
  name: 'AttributionReport',
  props: ['job'],
  components: {ApexLineChart, DonutChart, LineChart, BarChart, DayOfWeekAttributionData},
  data() {
    return {
      model: streetmetrics.model,
      reportData: {
        control: null,
        exposed: null,
        pixels: []
      },
      chartOptions: {
        responsive: false
      },
      chartWidth: 300,
      chartHeight: 300,
      // lineColors: ['#00bfc8', '#042f49', '#b1e5d7'],
      chartLabels: [],
      statsUtil: new StatsUtil()
    }
  },
  computed: {
    lineChartFields() {
      const chartFields = ['homePageVisits']

      if (this.productPage) {
        chartFields.push('productPageVisits')
      }

      if (this.checkoutPage) {
        chartFields.push('checkoutPageVisits')
      }

      return chartFields
    },
    lineColors() {
      const chartColors = ['#00bfc8']

      if (this.productPage) {
        chartColors.push('#b1e5d7')
      }

      if (this.checkoutPage) {
        chartColors.push('#242427')
      }

      return chartColors
    },
    hourExposureChartDataApex() {
      const chartUtil = new ChartUtil()
      return chartUtil.formatHourlyPixelDataForApex(this.reportData.exposed.hourOfExposure, this.homePage, this.productPage, this.checkoutPage)
    },
    hourExposureChartData() {
      const chartUtil = new ChartUtil()
      return chartUtil.formatHourlyPixelData(this.reportData.exposed.hourOfExposure, this.homePage, this.productPage, this.checkoutPage)
    },
    hourVisitChartData() {
      const chartUtil = new ChartUtil()
      return chartUtil.formatHourlyPixelDataForApex(this.reportData.exposed.hourOfDay, this.homePage, this.productPage, this.checkoutPage)
      //return chartUtil.formatHourlyPixelData(this.reportData.exposed.hourOfDay, this.homePage, this.productPage, this.checkoutPage)
    },
    homePageVisitsByDayChartData() {
      return streetmetrics.utils.chart.homePageVisitsByDayChartData(this.reportData.exposed.dayOfWeek, this.homePage)
    },
    homePageChartData() {
      return [
        {name: "Control", count: this.controlHomePageVisitationRate},
        {name: "Exposed", count: this.exposedHomePageVisitationRate}
      ]
    },
    productPageChartData() {
      return [
        {name: "Control", count: this.controlProductPageVisitationRate},
        {name: "Exposed", count: this.exposedProductPageVisitationRate}
      ]
    },
    checkoutPageChartData() {
      return [
        {name: "Control", count: this.controlCheckoutPageVisitationRate},
        {name: "Exposed", count: this.exposedCheckoutPageVisitationRate}
      ]
    },
    customer() {
      const customerId = this.model.attributionReportData.metaData.requestInfo.customerId
      const arrayUtil = new ArrayUtil()
      const customer = arrayUtil.find(this.model.customers, customerId, 'customerId')
      return customer ? customer.customerName : ''
    },
    campaign() {
      const campaignId = this.model.attributionReportData.metaData.requestInfo.campaignIds[0]
      const arrayUtil = new ArrayUtil()
      const campaign = arrayUtil.find(this.model.campaigns, Number(campaignId), 'campaignId')
      return campaign.campaignRef
    },
    startDate() {
      const range = this.model.attributionReportData.metaData.requestInfo.mainRange
      const dateFormatOptions = {year: "numeric", month: "short", day: "numeric"}
      const date = new Date(range[0] * 1000)
      return date.toLocaleDateString('en-us', dateFormatOptions)
    },
    endDate() {
      const range = this.model.attributionReportData.metaData.requestInfo.mainRange
      const dateFormatOptions = {year: "numeric", month: "short", day: "numeric"}
      const date = new Date(range[1] * 1000)
      return date.toLocaleDateString('en-us', dateFormatOptions)
    },
    exposedFrequency() {
      const frequency = this.reportData.exposed.totalDevices / this.reportData.exposed.uqDevices
      return frequency.toFixed(2);
    },
    controlFrequency() {
      const frequency = this.reportData.control.totalDevices / this.reportData.control.uqDevices
      return frequency.toFixed(2);
    },
    homePage() {
      // return this.getPage('Home')
      return this.getPage(0)
    },
    productPage() {
      return this.reportData.pixels.length > 1 ? this.getPage(1) : null
      // let page = this.getPage('Product')
      //
      // if (!page) {
      //   page = this.getFallbackProductPage()
      // }
      //
      // return page
    },
    checkoutPage() {
      return this.reportData.pixels.length > 2 ? this.getPage(2) : null
      // let page = this.getPage('Checkout')
      //
      // if (!page) {
      //   page = this.getPage('Conversion')
      // }
      //
      // return page
    },
    exposedProductPageVisitationRate() {
      return this.visitationRate(this.reportData.exposed.days, this.productPage)
    },
    exposedProductPageVisits() {
      return this.totalVisits(this.reportData.exposed.days, this.productPage)
    },
    controlProductPageVisitationRate() {
      return this.visitationRate(this.reportData.control.days, this.productPage)
    },
    controlProductPageVisits() {
      return this.totalVisits(this.reportData.control.days, this.productPage)
    },
    exposedCheckoutPageVisitationRate() {
      return this.visitationRate(this.reportData.exposed.days, this.checkoutPage)
    },
    exposedCheckoutPageVisits() {
      return this.totalVisits(this.reportData.exposed.days, this.checkoutPage)
    },
    controlCheckoutPageVisitationRate() {
      return this.visitationRate(this.reportData.control.days, this.checkoutPage)
    },
    controlCheckoutPageVisits() {
      return this.totalVisits(this.reportData.control.days, this.checkoutPage)
    },
    exposedHomePageVisitationRate() {
      return this.visitationRate(this.reportData.exposed.days, this.homePage)
    },
    exposedHomePageVisits() {
      return this.totalVisits(this.reportData.exposed.days, this.homePage)
    },
    controlHomePageVisitationRate() {
      return this.visitationRate(this.reportData.control.days, this.homePage)
    },
    controlHomePageVisits() {
      return this.totalVisits(this.reportData.control.days, this.homePage)
    },
    homePageConfidenceInterval() {
      return this.statsUtil.calculateConfidence(this.reportData.control.uqDevices, this.reportData.exposed.uqDevices,
          this.controlHomePageVisits, this.exposedHomePageVisits)
    },
    homePageConfidenceIntervalChartData() {
      let confInterval = this.statsUtil.calculateConfidence(this.reportData.control.uqDevices, this.reportData.exposed.uqDevices,
          this.controlHomePageVisits, this.exposedHomePageVisits)
      let firstValue = 1 - confInterval
      return [Number(firstValue.toFixed(2)), confInterval]
    },
    page2ConfidenceInterval() {
      return this.statsUtil.calculateConfidence(this.reportData.control.uqDevices, this.reportData.exposed.uqDevices,
          this.controlProductPageVisits, this.exposedProductPageVisits)
    },
    page2ConfidenceIntervalChartData() {
      let confInterval = this.page2ConfidenceInterval
      let firstValue = 1 - confInterval
      return [Number(firstValue.toFixed(2)), confInterval]
    },
    page3ConfidenceInterval() {
      return this.statsUtil.calculateConfidence(this.reportData.control.uqDevices, this.reportData.exposed.uqDevices,
          this.controlCheckoutPageVisits, this.exposedCheckoutPageVisits)
    },
    page3ConfidenceIntervalChartData() {
      let confInterval = this.page3ConfidenceInterval
      let firstValue = 1 - confInterval
      return [Number(firstValue.toFixed(2)), confInterval]
    }
  },
  mounted() {

    let outputUrl = ''
    let fileKey = ''
    const files = Object.keys(this.model.attributionReportData.result)

    for (let i = 0; i < files.length; i++) {
      const file = files[i]

      if (file.indexOf('output.json') > 0) {
        outputUrl = this.model.attributionReportData.result[file]
        fileKey = file
        break
      }
    }

    let job = this.model.attributionReportData
    const jobUtil = new JobUtil()
    const expirationDate = jobUtil.validUntil(job, fileKey)
    const isExpired = dayjs().isAfter(dayjs(expirationDate))

    if (isExpired) {
      // TODO: load the job!
    }


    http.get(outputUrl).end((error, response) => {

      if (response && response.status === 200) {
        const data = JSON.parse(response.text)
        this.reportData = data
        this.chartLabels = [this.homePage, this.productPage, this.checkoutPage]

        try {
          console.log('Calculating home page confidence')

        } catch (e) {
          console.log('problem calculating confidence interval')
          console.log(e)
        }

      } else {
        console.log('Error')
        console.log(error)
      }
    })
  },
  methods: {
    visitationRate(dayList, page) {
      const days = Object.keys(dayList)
      let visitationRate = 0
      for (let i = 0; i < days.length; i++) {
        const day = days[i]
        const dayData = dayList[day]
        const pageData = dayData.pages.filter(currentPage => currentPage.page === page)[0]

        if (pageData.visits === 0 || dayData.uqDevices === 0) {
          continue
        }

        visitationRate += (pageData.visits / dayData.uqDevices)
      }

      return Number(visitationRate.toFixed(2))
    },

    totalVisits(dayList, page) {
      const days = Object.keys(dayList)
      let visits = 0
      for (let i = 0; i < days.length; i++) {
        const day = days[i]
        const dayData = dayList[day]
        const pageData = dayData.pages.filter(currentPage => currentPage.page === page)[0]
        visits += pageData.visits
      }

      return visits
    },

    formatPercent(number) {
      return (number / 100).toLocaleString(undefined, {style: 'percent', minimumFractionDigits: 2})
    },

    getPage(index) {
      const pages = Object.keys(this.reportData.exposed.pageVisits)
      return pages[index]
    },

    // getPage(pageType) {
    //   const pages = Object.keys(this.reportData.exposed.pageVisits)
    //
    //   for (let i = 0; i < pages.length; i++) {
    //     const page = pages[i]
    //
    //     if (page.indexOf(pageType) > -1) {
    //       return page
    //     }
    //   }
    //
    //   return null
    // },

    getFallbackProductPage() {
      // HACK ... need better way to identify pages
      const pages = Object.keys(this.reportData.exposed.pageVisits)

      for (let i = 0; i < pages.length; i++) {
        const page = pages[i]
        let notHome = page.indexOf('Home') === -1
        let notCheckout = page.indexOf('Checkout') === -1
        let notConversion = page.indexOf('Conversion') === -1

        if (notHome && notCheckout && notConversion) {
          return page
        }
      }

      return null
    }
  }
}
</script>

<style>

</style>
