<template>
  <div class="mt-3">

    <h2>Frames By Market: {{ this.marketName }} ({{ frames.length }})</h2>

    <div class="my2">
      <!--      <div class="m-2">-->
      <!--        <div class="scrollable-container py-2 rounded-2 bg-gray-200 theme-color" style="overflow-y: auto;">-->
      <!--          <div class="ps-3 w-36 pointer" v-for="frame in frames" @click="onFrameSelected(frame)">-->
      <!--            {{ frame.frameRef }}-->
      <!--          </div>-->
      <!--        </div>-->
      <!--      </div>-->

      <!--      <div class="w-100-pct" v-if="selectedFrame">-->
      <!--        <h3 class="my-2">{{ selectedFrame.frameRef }}</h3>-->


            <div v-if="pinData.devices.length > 0" class="d-flex my-2 p-2 rounded-2 bg-gray-200 theme-color">
              <div class="mx-1">Unique Devices: {{ pinData.uniqueDevices }}</div>
              <div class="mx-1">Total Devices: {{ pinData.totalDevices }}</div>

              <div class="mx-1">Unique Devices Displayed: {{ showDeviceCount }}</div>
              <div class="mx-1">Total Devices Displayed: {{ pins.length }}</div>

              <div>
                <button class="btn btn-sm btn-outline-secondary mx-1" @click="showDeviceCount += 10">Show more</button>
                <button class="btn btn-sm btn-outline-secondary mx-1" v-if="showDeviceCount > 0" @click="showDeviceCount -= 10">Show less</button>
              </div>
            </div>

      <div v-if="inMarketDebugJobs.length > 0" class="d-flex my-2 p-2 rounded-2 bg-gray-200 theme-color">
        <div class="mx-1 pointer" v-for="inMarketDebugJob in inMarketDebugJobs" @click="onDateClick(inMarketDebugJob)">
          {{ inMarketDebugJob.date }}
        </div>
      </div>

      <!--      <div class="w-100-pct" v-if="frames.length > 0 && pinData.devices">-->
      <div class="w-100-pct" v-if="frames.length > 0">
        <div class="w-100-pct">
          <MapboxMap :access-token="mapboxKey" :map-style="mapStyle" style="width: 100%; height: 900px"
                     :center="pointLatLong" :zoom="11">
            <MapboxMarker v-for="frame in frames" :lng-lat="getPoint(frame)">
              <div class="rounded-circle bg-primary" style="height: 0.5rem; width: 0.5rem;"></div>
            </MapboxMarker>

            <MapboxSource v-for="frame in frames" :id="'' + frame.frameId"
                          :options="getFrameOptions(frame)"></MapboxSource>

            <MapboxLayer v-for="frame in frames" :id="'geoJsonFrameLayer' + frame.frameId"
                         :options="getFrameLayer(frame)"></MapboxLayer>

            <MapboxMarker v-for="pin in pins" :lng-lat="getPinPoint(pin)">
              <div class="rounded-circle bg-secondary" style="height: 0.5rem; width: 0.5rem;"></div>
            </MapboxMarker>

            <MapboxNavigationControl position="bottom-right"></MapboxNavigationControl>
          </MapboxMap>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import FrameService from "@/services/FrameService";
import MarketUtil from "@/utils/MarketUtil";
import {MapboxMap, MapboxMarker, MapboxNavigationControl, MapboxLayer, MapboxSource} from '@studiometa/vue-mapbox-gl';
import RestService from "@/services/RestService";
import DevService from "@/services/DevService";
import HttpService from "@/services/HttpService";
import PipelineService from "@/services/PipelineService";

export default {
  name: 'FramesByMarket',
  components: [MapboxMap, MapboxMarker, MapboxNavigationControl, MapboxLayer, MapboxSource],
  props: ['prop1', 'prop2'],
  data() {
    return {
      model: streetmetrics.model,
      marketHash: '',
      marketName: '',
      frames: [],
      selectedFrame: null,
      mapboxKey: 'pk.eyJ1Ijoic3RyZWV0bWV0cmljcyIsImEiOiJjamVvbm02aTgwMXowMnFzNGkwMnIxaDdhIn0.sP0hU6UbgVwMSLU_gpJEGg',
      mapStyle: "mapbox://styles/mapbox/streets-v11", // your map style
      pinData: {
        uniqueDevices: 0,
        totalDevices: 0,
        devices: [],
      },
      showDeviceCount: 100,
      debugJobFiles: [],
      debugJobs: []
    }
  },
  mounted() {
    this.marketHash = this.$route.params.id;
    let allMarketsHashTable = new MarketUtil().getMarketHashTable();
    let marketId = allMarketsHashTable[this.marketHash].marketId
    this.marketName = allMarketsHashTable[this.marketHash].marketName

    console.log('Load frames for ' + this.marketHash);
    console.log('Market ID ' + marketId);
    this.loadFrames(marketId);
    this.loadDebugJobs();
    this.testFrames()
    // this.loadPins()
  },
  computed: {
    pointLatLong() {
      this.selectedFrame = this.frames[0];
      const points = [this.selectedFrame.site.siteLon, this.selectedFrame.site.siteLat]
      return points
    },
    inMarketDebugJobs() {
      return this.debugJobs.filter(debugJob => debugJob.marketHash === this.marketHash);
    },
    pins() {
      // return []
      // const deviceIds = Object.keys(this.pinData.devices)
      let visiblePins = []

      for (let i = 0; i < this.pinData.devices.length && i < this.showDeviceCount; i++) {
        // const deviceId = this.pinData.devices[i];
        const pinList = this.pinData.devices

        for (let j = 0; j < pinList.length; j++) {
          const pinListElement = pinList[j];
          visiblePins.push(pinListElement)
        }
      }

      return visiblePins
    },
  },
  methods: {
    loadFrames(marketId) {
      this.model.loading = true;
      let service = new FrameService();
      service.listByMarket(marketId).then((response) => {
        //this.frames = response.data.filter(frame => frame.frameRef.indexOf('SOK') === 0);
        this.frames = response.data
        this.model.loading = false;
      }).catch((message) => {
        console.log("error loading frames");
        console.log(message);
        this.model.loading = false;
      });
    },

    testFrames() {
      const pipelineService = new PipelineService();
      pipelineService.loadFrames()
    },

    loadDebugJobs() {
      const devService = new DevService();
      devService.getPipelineDebugFiles().then((response) => {
        console.log('Got pipeline debug files')
        console.log(response)
        this.debugJobFiles = response.Contents

        for (let i = 0; i < this.debugJobFiles.length; i++) {
          const debugJobFile = this.debugJobFiles[i];
          const fileName = debugJobFile.Key
          const tokens = fileName.split("_")
          this.debugJobs.push({
            date: tokens[0],
            marketHash: tokens[1],
            s3FileName: fileName
          })
        }

        console.log(this.debugJobs);
      }).catch((message) => {
        console.log("error loading pipeline debug files");
        console.log(message);
        this.model.loading = false;
      });
    },

    loadPins() {
      let service = new RestService('local');

      // service.executeGet('/pins.json').then((response) => {
      //       this.pins = response

      // let uniqueDevices = 0;
      // let totalDevices = 0;
      // let deviceMap = {}

      // service.getRawData('/4276113_pin_report.tsv').then((response) => {
      service.executeGet('/pins.json').then((response) => {
        this.pinData = response
        // const rows = response.trim().split('\n');
        // // Split each row by tabs to get individual values
        // rows.map( (row) => {
        //   let tokens = row.split('\t')
        //   let deviceId = tokens[1]
        //
        //   if(deviceMap[deviceId] === undefined) {
        //     deviceMap[deviceId] = []
        //     uniqueDevices ++
        //   }
        //
        //   deviceMap[deviceId].push({
        //     lat: Number(tokens[2]),
        //     long: Number(tokens[3]),
        //     ts: Number(tokens[4]),
        //   })
        //
        //   totalDevices++
        // });
        //
        // const pinData = {
        //   uniqueDevices: uniqueDevices,
        //   totalDevices: totalDevices,
        //   devices: deviceMap,
        // }
        //
        console.log('Uniques: ' + this.pinData.uniqueDevices);
        console.log('Total Devices: ' + this.pinData.totalDevices);
      }).catch((message) => {
        console.log("error loading frames");
        console.log(message);
        this.model.loading = false;
      });


    },

    getPoint(frame) {
      const points = [frame.site.siteLon, frame.site.siteLat]
      return points
    },

    getPinPoint(pin) {
      const points = [pin.long, pin.lat]
      return points
    },

    onFrameSelected(frame) {
      this.selectedFrame = frame
    },

    toRadians(degrees) {
      return degrees * (Math.PI / 180);
    },
    toDegrees(radians) {
      return radians * (180 / Math.PI);
    },

    // Function to calculate the destination point given start lat/long, bearing, and distance
    calculateDestinationPoint(lat, lon, angle, radius) {
      const R = 6371000; // Radius of the Earth in meters
      const bearing = this.toRadians(angle); // Convert bearing to radians
      const distance = radius; // Distance in meters

      const lat1 = this.toRadians(lat); // Current lat point converted to radians
      const lon1 = this.toRadians(lon); // Current lon point converted to radians

      const lat2 = Math.asin(Math.sin(lat1) * Math.cos(distance / R) +
          Math.cos(lat1) * Math.sin(distance / R) * Math.cos(bearing));

      const lon2 = lon1 + Math.atan2(Math.sin(bearing) * Math.sin(distance / R) * Math.cos(lat1),
          Math.cos(distance / R) - Math.sin(lat1) * Math.sin(lat2));

      return {
        latitude: this.toDegrees(lat2),
        longitude: this.toDegrees(lon2)
      };
    },

    getFrameOptions(frame) {
      return {
        type: "geojson",
        data: this.generateSectorGeoJSON(frame)
      };
    },

    getFrameLayer(frame) {
      return {
        id: "geoJsonFillsLayer",
        source: '' + frame.frameId,
        type: "fill",
        paint: {
          "fill-color": "lightblue",
          "fill-opacity": 0.5
        }
      };

    },

    generateSectorGeoJSON(frame) {
      let lat = frame.site.siteLat;
      let lon = frame.site.siteLon;
      let expositionAngle = frame.expositionAngle;
      let actualAngle = frame.expositionWidth;
      let radius = 1000;
      let numPoints = 100

      const coordinates = [[lon, lat]]; // Start with the center point

      const halfAngle = actualAngle / 2;
      const startAngle = expositionAngle - halfAngle; // Start angle
      const endAngle = expositionAngle + halfAngle; // End angle

      // Generate points along the arc
      for (let i = 0; i <= numPoints; i++) {
        const bearing = startAngle + i * ((endAngle - startAngle) / numPoints); // Divide the angle into equal segments
        const point = this.calculateDestinationPoint(lat, lon, bearing, radius);
        coordinates.push([point.longitude, point.latitude]);
      }

      // Closing the sector by adding the center point again
      coordinates.push([lon, lat]);

      // const coordinates = [[lon, lat]]; // Start with the center point
      //
      // // Generate points along the arc
      // for (let i = 0; i <= numPoints; i++) {
      //   const bearing = i * (angle / numPoints); // Divide the angle into equal segments
      //   const point = this.calculateDestinationPoint(lat, lon, bearing, radius);
      //   coordinates.push([point.longitude, point.latitude]);
      // }
      //
      // // Closing the sector by adding the center point again
      // coordinates.push([lon, lat]);

      const geoJSON = {
        "type": "Feature",
        "geometry": {
          "type": "Polygon",
          "coordinates": [coordinates]
        },
        "properties": {
          "center": [lon, lat],
          "angle": expositionAngle,
          "radius": radius
        }
      };

      return {
        "type": "FeatureCollection",
        "features": [geoJSON]
      }

      // geoJSON;
    },

    onDateClick(inMarketDebugJob) {
      console.log('Ok... load debug file here: ' + inMarketDebugJob.s3FileName)

      let service = new HttpService();
      let url = `https://sm-pipeline-debug.s3.us-west-2.amazonaws.com/${inMarketDebugJob.s3FileName}`
      let uniqueDevices = 0;
      let totalDevices = 0;
      let deviceList = []

      service.executeGet(url).then((response) => {
        // this.pins = response

        const rows = response.trim().split('\n');

        rows.map( (row) => {
          let tokens = row.split(',')
          let deviceId = tokens[0]

          // if(deviceMap[deviceId] === undefined) {
          //   deviceMap[deviceId] = []
          //   uniqueDevices ++
          // }

          deviceList.push({
            lat: Number(tokens[2]),
            long: Number(tokens[3]),
            ts: Number(tokens[1]),
          })

          totalDevices++
        });

        this.pinData = {
          uniqueDevices: uniqueDevices,
          totalDevices: totalDevices,
          devices: deviceList,
        }

        console.log(this.pinData)
      })
    }
  }
}
</script>