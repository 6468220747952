<template>
  <div>
    <apexchart :series="chartData" :options="chartOptions"></apexchart>
  </div>
</template>

<script>
export default {
  name: 'DonutChart',
  props: ['chartData', 'confidenceInterval'],
  data () {
    return {
      model: streetmetrics.model,
      chartOptions: {
        chart: {
          type: 'donut'
        },
        dataLabels: {
          enabled: false
        },
        tooltip: {
          enabled: false
        },
        colors: ['#FFFFFF', '#062e49'],
        legend: {
          show: false
        },
        plotOptions: {
          pie: {
            donut: {
              labels: {
                show: true,
                name: {
                  show: true,
                  fontSize: '8px',
                  fontFamily: 'Helvetica, Arial, sans-serif',
                  fontWeight: 400,
                  color: '#062e49',
                  offsetY: 25,
                  formatter: (val) => {
                    return 'Confidence Interval'
                  }
                },
                value: {
                  show: true,
                  fontSize: '42px',
                  fontFamily: 'Helvetica, Arial, sans-serif',
                  fontWeight: 600,
                  color: '#062e49',
                  offsetY: -15,
                  formatter: (val) => {
                    return this.confidenceInterval.toLocaleString(undefined, {style: 'percent', minimumFractionDigits: 0})
                  }
                },
                total: {
                  show: true,
                  label: 'Confidence Interval',
                  formatter: () => this.confidenceInterval.toLocaleString(undefined, {style: 'percent', minimumFractionDigits: 0})
                }
              }
            }
          }
        }
      }
    }
  },
  mounted () {
  },
  methods: {
    importantMethodHere () {
    }
  }
}
</script>

<style>

</style>
