import RestService from "./RestService";

export default class ReportingService extends RestService {

    constructor() {
        super('reporting')
    }

    compareImpressions(marketId, startDate, endDate) {
        let url = this.baseUrl + `/v3/api/reporting/compare-impressions?marketId=${marketId}&startDate=${startDate}&endDate=${endDate}`
        return this.executeGet(url)
    }
}