import RestService from "./RestService";

export default class MarketService extends RestService {

  constructor() {
    super('markets')
  }

  loadS3Files(marketHash) {
    let url = this.baseUrl + '/v3/api/markets/latest-files/' + marketHash
    return this.executeGet(url)
  }
}
