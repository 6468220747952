<template>
  <div class="pt-4 px-4">

    <div class="mb-3 d-flex justify-content-between">
      <div class="d-flex align-items-center">
        <h2>Run Campaign</h2>
      </div>
    </div>


    <div>
      Markets: <span class="bg-gray-700 text-white rounded-3 p-1 font-xsm m-1" v-for="market in campaignDetails.markets">{{market}}</span>
    </div>

    <div>

      <div class="mb-3">
        <label for="startDateInput" class="form-label">Start Date</label>
        <input v-model="job.startDate" type="text" class="form-control" id="startDateInput"
               placeholder="yyyy-mm-dd">
      </div>

      <div class="mb-3">
        <label for="endDateInput" class="form-label">End Date</label>
        <input v-model="job.endDate" type="text" class="form-control" id="endDateInput"
               placeholder="yyyy-mm-dd">
      </div>
    </div>

    <div class="mb-3">
      <button type="button" class="btn btn-secondary" style="min-width: 150px;" @click="onSaveClick">Save
      </button>

      <router-link :to="{ name: 'stationary-pipeline' }" class="btn btn-light ms-2">
        Cancel
      </router-link>
    </div>


  </div>
</template>

<script>
import ArrayUtil from "@/utils/ArrayUtil";
import CampaignService from "@/services/CampaignService";
import AppUtil from "@/utils/AppUtil";
import PipelineService from "@/services/PipelineService";

export default {
  name: 'RunCampaignForm',
  props: ['prop1', 'prop2'],
  data () {
    return {
      model: streetmetrics.model,
      service: new PipelineService(),
      campaignDetails: {
        markets: []
      },
      job: {
        startDate: '',
        endDate: ''
      }
    }
  },
  mounted () {
    let campaignId = this.$route.params.id
    const arrayUtil = new ArrayUtil()
    let campaign = arrayUtil.find(this.model.campaigns, Number(campaignId), 'campaignId')
    this.loadCampaignDetails(campaign)
  },
  methods: {
    loadCampaignDetails (campaign) {
      const service = new CampaignService();
      service.getCampaignDetails(campaign).then( (response) => {
        this.campaignDetails = response.data
        console.log(this.campaignDetails.markets)
      }).catch((error) => {
        console.log('Error loading campaign details')
        console.log(error)
      });
    },

    onSaveClick () {
      console.log(this.job.marketId)
      const markets = []

      for (let i = 0; i < this.model.markets.length; i++) {
        const market = this.model.markets[i];

        for (let j = 0; j < this.campaignDetails.markets.length; j++) {
          const marketName = this.campaignDetails.markets[j];

          if(market.marketName === marketName) {
            markets.push({marketId: market.marketId, marketHash:market.marketHash})
          }
        }
      }

      console.log('TODO: save markets')
      console.log(markets)

      let dateStrings = [this.job.startDate]

      if(this.job.endDate) {
        dateStrings = streetmetrics.utils.app.getDateStrings(this.job.startDate, this.job.endDate)
      }

      const promises = []

      for (let i = 0; i < dateStrings.length; i++) {
        const dateString = dateStrings[i];

        for (let j = 0; j < markets.length; j++) {
          const market = markets[j]

          const request = {
            market: market.marketHash,
            marketId: market.marketId,
            startDate: dateString,
            runType: 'STATIONARY'
          }

          const url = new AppUtil().getPipelineUrl() + '/api/run-market'
          const promise = this.service.post(url, request);
          promises.push(promise);
        }
      }

      Promise.all(promises).then( (response) => {
        this.model.toast.background = 'bg-green';
        this.model.toast.message = response.message;
        this.$router.push({name: 'pipeline'});
      }).catch((error) => {
        this.model.toast.background = 'bg-red';
        this.model.toast.message =
            'There was an error trying to run this job. Please try again later.';
      });
    }
  }
}
</script>
