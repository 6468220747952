import RestService from "./RestService";

export default class AssetOwnersService extends RestService {
  constructor() {
    super("asset-owners");
  }

  getAssetOwners() {
    let url = this.baseUrl + `/v3/api/asset-owners/admin-list`;
    return this.executeGet(url)
      .then((response) => {
        return response.data.sort((a, b) => a.name.localeCompare(b.name));
      })
      .catch((message) => {
        console.log("error loading asset owners");
        console.log(message);
      });
  }
}
