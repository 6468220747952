<template>
  <div class="card shadow-sm m-2" style="width: 300px">
    <div class="card-body">
      <div class="card-text pointer" @click="showMarketModal = true">{{ market.marketName }}</div>
      <job-details :job="mostRecentMarketJob"></job-details>
    </div>

    <jobs-by-market-modal v-if="showMarketModal" @noEvent="showMarketModal = false" :market="market"></jobs-by-market-modal>
  </div>
</template>

<script>
import JobService from '@/services/JobService';
import JobsByMarketModal from '@/views/jobs/JobsByMarketModal';
import JobDetails from '@/views/jobs/JobDetails';

export default {
  name: 'MarketCard',
  components: { JobDetails, JobsByMarketModal },
  props: ['market', 'prop2'],
  data() {
    return {
      model: streetmetrics.model,
      mostRecentMarketJob: [],
      showMarketModal: false,
    };
  },
  mounted() {
    this.mostRecentMarketJob = this.getMostRecentJobByMarket(this.market.marketName);
  },
  methods: {
    getMostRecentJobByMarket(marketName) {
      const marketJobs = this.model.jobs.filter((job) => {
        return job.jobAOI.marketName.toLowerCase() === marketName.toLowerCase();
      });

      let marketJob = marketJobs[0]
      marketJob.audits.sort((a, b) => a.auditId > b.auditId ? 1 : -1);
      return marketJob;
    },
    debugMarketJob() {
      console.log('Missing demographic job or audit');
      console.log(this.demographicJob);
      console.log(this.market.marketName);
      console.log('-----');
      return '';
    },
  },
};
</script>

<style></style>
