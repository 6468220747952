<template>
    <div class="m-3 pe-3">
        <div class="mt-4">
            <h2>Asset Owners</h2>
        </div>

        <div v-if="assetOwners.length > 0" class="table-responsive table-container scrollable-container mt-2">
            <table class="table">
                <thead class="bg-gray-500 text-uppercase">
                    <tr>
                        <th>ID</th>
                        <th>Name</th>
                        <th>Default Market</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="assetOwner in assetOwners">
                        <td>{{ assetOwner.assetOwnerId }}</td>
                        <td @click="onAssetOwner(assetOwner)" class="pointer">{{ assetOwner.name }}</td>
                        <td>{{ assetOwner.defaultMarketId ? getMarketName(assetOwner) : "" }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div v-else>No asset owners found.</div>
        <asset-owner-modal v-if="currentAssetOwner && showAssetOwnerModal" @noEvent="showAssetOwnerModal = false"
            :assetOwner="currentAssetOwner"></asset-owner-modal>
    </div>
</template>

<script>
import RestService from "@/services/RestService";
import AssetOwnersService from "../services/AssetOwnersService";
import AppUtil from "@/utils/AppUtil";
import ArrayUtil from "@/utils/ArrayUtil";
import AssetOwnerModal from "@/components/AssetOwnerModal";

export default {
    name: "AssetOwners",
    components: { AssetOwnerModal },
    data() {
        return {
            // eslint-disable-next-line no-undef
            model: streetmetrics.model,
            assetOwners: [],
            currentAssetOwner: null,
            showAssetOwnerModal: false
        };
    },
    mounted() {
        const service = new AssetOwnersService();
        service.getAssetOwners()
            .then((response) => this.assetOwners = response)
            .catch((message) => {
                console.log(message);
            });

    },
    methods: {
        onAssetOwner(assetOwner) {
            this.currentAssetOwner = assetOwner
            this.showAssetOwnerModal = true
        },

        getMarketName(assetOwner) {
            const arrayUtil = new ArrayUtil();
            const market = arrayUtil.find(
                this.model.markets,
                assetOwner.defaultMarketId,
                "marketId"
            );
            return market ? market.marketName : "";
        },
    }

};
</script>

<style></style>
