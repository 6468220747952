<template>
  <div class="mt-3 pe-3">

    <div class="mt-4">
      <button class="btn btn-secondary" @click="onNewUserClick">New User</button>
    </div>

    <div v-if="users.length > 0" class="table-responsive table-container user-container mt-2">
      <table class="table">
        <thead class="bg-gray-500 text-uppercase">
        <tr>
          <th>Name</th>
          <th>Email</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="user in users">
          <td>
            <a href="javascript:void(0)" @click="onUserClick(user.userId)">{{ user.displayName }}</a>
          </td>
          <td>{{ user.auth?.authEmail }}</td>
        </tr>
        </tbody>
      </table>
    </div>

    <div v-else class="my-4 alert alert-warning">
      <strong>This customer does not have any users.</strong>
    </div>

    <user-form-modal v-if="showModal" :user="currentUser" :customer-id="customerId"
                     @noEvent="showModal = false" ></user-form-modal>
  </div>
</template>

<script>
import CustomerTabs from "../components/CustomerTabs";
import CustomerHeader from "../components/CustomerHeader";
import UserFormModal from "../components/UserFormModal";
import CustomerService from "../services/CustomerService";
import PixelService from "../services/PixelService";
import MediaService from "../services/MediaService";

export default {
  name: 'UserList',
  components: {UserFormModal, CustomerHeader, CustomerTabs},
  props: ['customerId'],
  data() {
    return {
      // eslint-disable-next-line no-undef
      model: streetmetrics.model,
      showModal: false,
      currentUser: {
        auth: {
          authEmail: "",
          authPassword: ""
        },
        customerId: -1,
        displayName: ""
      }
    }
  },
  computed: {
    users() {
      return this.model.users.filter(user => (user.customer.customerId === this.customerId))
    }
  },
  methods: {
    onUserClick(userId) {
      let userList = this.model.users.filter(user => (user.userId === userId))
      let user = userList[0]

      this.currentUser = {
        auth: {
          authEmail: user.auth.authEmail,
          authPassword: ""
        },
        customerId: this.customerId,
        displayName: user.displayName,
        userId: user.userId
      }

      this.showModal = true
    },
    onNewUserClick() {
      this.currentUser = {
        auth: {
          authEmail: "",
          authPassword: ""
        },
        customerId: this.customerId,
        displayName: ""
      }

      this.showModal = true
    }
  }
}
</script>

<style>

</style>
