<template>
  <div class="d-flex">
    <sidebar></sidebar>
    <div class="w-100-pct">
      <top-nav></top-nav>
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import Sidebar from "./SideBar";
import TopNav from "./TopNav";
import UserService from "../services/UserService";

export default {
  name: 'MainContainer',
  components: {
    TopNav,
    Sidebar
  },
  data() {
    return {
      model: streetmetrics.model
    }
  },
  mounted() {
      let service = new UserService()
      service.currentUser().then((response) => {
        this.model.user = response.data
        streetmetrics.stateManager.init()
      }).catch((error) => {
        console.log(error)
        streetmetrics.stateManager.resetState()
        window.localStorage.setItem('auth.token', null)
        this.$router.push({name: 'sign-in'})
      })
  }
}
</script>
