import RestService from './RestService';

export default class AttributionService extends RestService {
  constructor() {
    super('attribution');
  }

  getAvailableCuts() {
    const url = this.baseUrl + '/v3/api/attribution/cut-options';
    return this.executeGet(url);
  }
  
  checkNearData(studyId) {
    const url = this.baseUrl + '/v3/api/attribution/near-report/' + studyId;
    return this.executeGet(url);
  }

  sendNearAPIRequest(studyId) {
    const url = this.baseUrl + '/v3/api/attribution/pull-near-data/' + studyId;
    return this.executeGet(url);
  }

  runStudy(studyId) {
    const url =
      this.baseUrl + `/v3/api/attribution/process-study/${studyId}`;
    return this.post(url);
  }

  update(studyId, body) {
    const url = this.baseUrl + `/v3/api/attribution/${studyId}`;
    return super.update(studyId, body, url);
  }

  updateStatus(status, studyId) {
    const url = this.baseUrl + `/v3/api/attribution/status/${studyId}`;
    return this.put(url, { status });
  }

  updateAttributionStudyCuts(studyId, customerId, cuts) {
    if (!studyId || !customerId || !cuts) {
      return;
    }
    const url = this.baseUrl + `/v3/api/attribution/${studyId}/cuts?customerId=${customerId}`;
    return this.put(url, cuts);
  }

  listAttributionStudyCuts(studyId, customerId) {
    if (!studyId || !customerId) {
      return;
    }

    const url = this.baseUrl + `/v3/api/attribution/${studyId}/cuts?customerId=${customerId}`;
    return this.executeGet(url); 
  }
}

