<template>

  <div class="card shadow mt-3 h-100-pct">
    <div class="card-body">

      <div class="mb-3 d-flex justify-content-between">
        <div class="d-flex align-items-center">
          <h5 class="card-title text-info">Pixels</h5>
        </div>

        <div>
          <button class="btn btn-secondary" @click="onNewPixelClick">New Pixel</button>
        </div>
      </div>


      <div v-if="pixels.length > 0" class="table-responsive table-container mt-3">
        <table class="table">
          <thead class="bg-gray-500 text-uppercase">
          <tr>
            <th>Name</th>
            <!--            <th>Last Triggered</th>-->
            <th>Requests</th>
            <th></th>
<!--            <th></th>-->
          </tr>
          </thead>
          <tbody>

          <!--

          PIXEL_ID: 1717
          PIXEL_METADATA: {displayName: "Homepage", funnelStage: "Visit", page: "Home", url: "https://sheertex.com/"}
          PIXEL_UUID:"361fe96e-c38d-4647-9b5e-3f8db0b3f36d"
          REQUEST_COUNT:581455

          -->

          <tr v-for="pixel in pixels">
            <td>
              <div>
                <div v-if="pixel.metaData">
                  <a v-if="pixel.requestCount > 0" href="javascript:void(0)" @click="onPixelClick(pixel)">
                    {{ pixel.metaData.page }}
                  </a>
                  <span v-else>{{ pixel.metaData.page }}</span>
                </div>

                <div v-if="pixel.metaData.displayName" class="font-sm gray-600">Name: {{ pixel.metaData.displayName }}</div>
                <div v-if="pixel.pixelUUID" class="font-sm gray-500">{{ pixel.pixelUUID }}</div>
                <div class="font-sm gray-500">ID {{ pixel.pixelId }}</div>
              </div>
            </td>
            <!--            <td>-->
            <!--              <formatted-date-time v-if="pixel.lastrequest" :date-time="pixel.lastrequest"></formatted-date-time>-->
            <!--            </td>-->
            <td>{{ roundNumber(pixel.requestCount).toLocaleString() }}</td>
<!--            <td>-->
<!--              <a href="javascript:void(0)" @click="onEditClick(pixel)" class="text-info">-->
<!--                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"-->
<!--                     stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"-->
<!--                     class="feather feather-edit small-icon">-->
<!--                  <path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7"></path>-->
<!--                  <path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z"></path>-->
<!--                </svg>-->
<!--              </a>-->
<!--            </td>-->
<!--            <td>-->
<!--              <a v-if="pixel.pixelUUID" href="javascript:void(0)" @click="onCopyClick(pixel)" class="text-info">-->
<!--                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"-->
<!--                     stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"-->
<!--                     class="feather feather-copy small-icon">-->
<!--                  <rect x="9" y="9" width="13" height="13" rx="2" ry="2"></rect>-->
<!--                  <path d="M5 15H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h9a2 2 0 0 1 2 2v1"></path>-->
<!--                </svg>-->
<!--              </a>-->
<!--            </td>-->
            <td>
              <button @click="onCheckPixelCountClick(pixel)" class="btn btn-sm btn-light">Check</button>
            </td>
          </tr>
          </tbody>
        </table>

      </div>
      <div v-else class="my-4 alert alert-warning">
        <strong>This campaign does not have any pixels.</strong>
      </div>

    </div>

    <pixel-form-modal v-if="showModal" @noEvent="showModal = false" :pixel="currentPixel" :customer="customer"
                      :yes-event="onPixelSave"></pixel-form-modal>
  </div>

</template>

<script>
import PixelService from "../services/PixelService";
import PixelFormModal from "./PixelFormModal";
import {v4 as uuidv4} from 'uuid';
import ArrayUtil from "../utils/ArrayUtil";
import AppUtil from "../utils/AppUtil";

export default {
  name: 'PixelRequestsCard',
  components: {PixelFormModal},
  props: ['campaign', 'viewModel', 'customer'],
  data() {
    return {
      model: streetmetrics.model,
      pixelService: new PixelService(),
      pixels: [],
      showModal: false,
      currentPixel: {
        pixelId: null,
        pixelUUID: uuidv4(),
        campaign: this.campaign.campaignId,
        displayName: '',
        page: ''
      },
      onPixelSave: (pixelId) => {}
    }
  },
  mounted() {
    this.loadPixels()
  },
  methods: {
    loadPixels() {
      this.model.loading = true
      this.pixelService.getCampaignPixels(this.campaign.campaignId).then((response) => {
        this.model.loading = false
        this.pixels = response.data
      }).catch((error) => {
        console.log('Error loading pixels for campaign: ' + this.campaign.campaignId)
        console.log(error)
        this.model.loading = false
      })
    },
    onPixelClick(pixel) {
      this.viewModel.currentPixel = pixel
      this.viewModel.childView = 'campaign-pixel-details'
    },
    roundNumber(value) {
      return Math.round((Number(value) + Number.EPSILON) * 100) / 100
    },
    onNewPixelClick() {
      this.currentPixel = {
        pixelId: null,
        pixelUUID: uuidv4(),
        campaign: this.campaign.campaignId,
        displayName: '',
        page: '',
        funnelStage: '',
        url: ''
      }
      this.showModal = true

      this.onPixelSave = (pixelId) => {
        const newPixel = {
          pixelId: pixelId,
          pixelUUID: this.currentPixel.pixelUUID,
          metaData: {
            page: this.currentPixel.page,
            displayName: this.currentPixel.displayName,
            funnelStage: this.currentPixel.funnelStage,
            url: this.currentPixel.url
          },
          requestcount: 0
        }

        this.pixels.push(newPixel)
      }
    },
    onEditClick(pixel) {
      this.currentPixel = {
        pixelId: pixel.pixelId,
        pixelUUID: pixel.pixelUUID,
        campaign: this.campaign.campaignId,
        displayName: pixel.metaData.displayName,
        page: pixel.metaData.page,
        funnelStage: pixel.funnelStage,
        url: pixel.url
      }

      this.showModal = true

      this.onPixelSave = (pixelId) => {
        this.loadPixels()
      }
    },
    onCopyClick(pixel) {
      const link = "https://pixel.streetmetrics.io/pixel/" + pixel.pixelUUID
      navigator.clipboard.writeText(link).then(() => {
        this.model.toast.message = "Pixel link copied to clipboard!"
      });
    },
    onCheckPixelCountClick(pixel) {
      this.model.loading = true
      const appUtil = new AppUtil()
      const startAndEndDates = appUtil.getFilterDates('last3')
      const formattedDates = appUtil.formatStartAndEndDates(startAndEndDates.start, startAndEndDates.end)

      this.pixelService.loadPixelRequests(pixel.pixelId, formattedDates.start, formattedDates.end).then((response) => {
        this.model.loading = false
        // let triggerCount = Number(response.data[0].requestcount)
        let triggerCount = response.data.length

        if(triggerCount > 0) {
          this.model.toast.background = 'bg-green'
          this.model.toast.message = "This pixel has fired in the last three days."
        }
        else {
          this.model.toast.background = 'bg-orange'
          this.model.toast.message = "This pixel has not fired in the last three days."
        }

      }).catch((error) => {
        console.log('Error loading pixel data with ID: ' + pixel.PIXEL_ID)
        console.log(error)
        this.model.loading = false
      })
    },
  }
}
</script>

<style>

</style>
