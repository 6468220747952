<template>
  <div class="p-4">
    <h2>Dashboard</h2>

    <div class="d-flex flex-wrap mt-4">
      <div class="card shadow-sm mx-2 my-1" style="min-width: 250px;">
        <div class="card-body">
          <div class="text-center">
            <h1 class="font-2xl font-xbold spacing-close accent-color">{{ model.customers.length }}</h1>
            <p class="card-text">
              Customers
            </p>
          </div>
        </div>
      </div>

      <div class="card shadow-sm mx-2 my-1" style="min-width: 250px;">
        <div class="card-body">
          <div class="text-center">
            <h1 class="font-2xl font-xbold spacing-close accent-color">{{ model.users.length }}</h1>
            <p class="card-text">
              Users
            </p>
          </div>
        </div>
      </div>

      <div class="card shadow-sm mx-2 my-1" style="min-width: 250px;">
        <div class="card-body">
          <div class="text-center">
            <h1 class="font-2xl font-xbold spacing-close accent-color">{{ model.markets.length }}</h1>
            <p class="card-text">
              Markets
            </p>
          </div>
        </div>
      </div>

      <div class="card shadow-sm mx-2 my-1" style="min-width: 250px;">
        <div class="card-body">
          <div class="text-center">
            <h1 class="font-2xl font-xbold spacing-close accent-color">{{ model.campaigns.length }}</h1>
            <p class="card-text">
              Campaigns
            </p>
          </div>
        </div>
      </div>

    </div>

  </div>
</template>

<script>

export default {
  name: 'Dashboard',
  data() {
    return {
      model: streetmetrics.model
    }
  }
}
</script>

<style>

</style>
