<template>
  <div id="customerFormModal" class="modal fade" tabindex="-1">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-body">

          <div class="d-flex align-items-center p-3">
            <div class="centered-circle flex-shrink-0 bg-secondary text-primary h-10 w-10">
              <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 24 24"
                   class="small-icon" fill="currentColor">
                <g data-name="Layer 2">
                  <g data-name="award">
                    <rect width="24" height="24" opacity="0"></rect>
                    <path
                        d="M19 20.75l-2.31-9A5.94 5.94 0 0 0 18 8 6 6 0 0 0 6 8a5.94 5.94 0 0 0 1.34 3.77L5 20.75a1 1 0 0 0 1.48 1.11l5.33-3.13 5.68 3.14A.91.91 0 0 0 18 22a1 1 0 0 0 1-1.25zM12 4a4 4 0 1 1-4 4 4 4 0 0 1 4-4zm.31 12.71a1 1 0 0 0-1 0l-3.75 2.2L9 13.21a5.94 5.94 0 0 0 5.92 0L16.45 19z"></path>
                  </g>
                </g>
              </svg>
            </div>
            <div class="ms-3">
              <h3 class="fs-5 spacing-close">{{actionLabel}} Customer</h3>
            </div>
          </div>

          <div v-if="errorMessage" class="alert alert-warning mt-1 mb-3" role="alert">
            {{errorMessage}}
          </div>

          <form>
            <div class="mb-3">
              <label for="customerNameInput" class="form-label">Name</label>
              <input v-model="customer.customerName" type="text" class="form-control" id="customerNameInput">
            </div>

            <div class="mb-3">
              <label class="form-label">Channel</label>
              <select class="form-select mb-2" v-model="customer.customerChannel">
                <option v-for="channelOption in channelOptions" :value="channelOption">{{ channelOption }}
                </option>
              </select>
            </div>

            <div class="mb-3">
              <label class="form-label">Subscription</label>
              <select class="form-select mb-2" v-model="customer.subscriptionType">
                <option value="MEMBERSHIP">Membership</option>
                <option value="FLEX">Flex</option>
              </select>
            </div>

            <div class="mb-3">
              <label class="form-label">Markets</label>
              <select-dropdown :list="selectableMarkets" label-field="marketName"
                               list-title="Markets"></select-dropdown>
            </div>

          </form>

        </div>

        <div class="modal-footer bg-">
          <button type="button" class="btn btn-secondary font-sm" style="min-width: 150px;" @click="onSaveClick">Save
          </button>
          <button type="button" class="btn btn-outline-primary font-sm" data-bs-dismiss="modal"
                  style="min-width: 150px;">Cancel
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SelectDropdown from "./SelectDropdown";
import CustomerService from "../services/CustomerService";

export default {
  name: 'CustomerFormModal',
  components: {SelectDropdown},
  props: {
    yesEvent: Function,
    customer: Object
  },
  data() {
    return {
      model: streetmetrics.model,
      currentModal: null,
      modalElement: null,
      channelOptions: ["AGENCY", "BIKESHARE", "BUS", "CAR", "ECONOMIC_DEVELOPMENT", "MOBILE_BILLBOARD", "PEDI_CAB",
        "RETAIL", "TRUCK_SIDE", "OTHER", "MULTI_CHANNEL"],
      selectableMarkets: [],
      service: new CustomerService(),
      errorMessage: null
    }
  },
  computed: {
    actionLabel() {
      return this.customer.customerId ? 'Edit' : 'New'
    }
  },
  mounted() {
    this.modalElement = document.getElementById('customerFormModal')
    this.currentModal = new bootstrap.Modal(this.modalElement)

    this.modalElement.addEventListener('hidden.bs.modal', () => {
      this.$emit('noEvent')
    })

    this.currentModal.show()

    this.selectableMarkets = this.model.markets.map((item) => {
      item.selected = this.customer.customerId ? this.customer.markets.includes(item.marketId) : false
      return item
    })
  },
  beforeDestroy() {
    this.modalElement.removeEventListener('hidden.bs.modal')
  },
  methods: {
    onSaveClick() {

      let marketIds = this.selectableMarkets.filter(market => (market.selected)).map(market => market.marketId)

      let request = {
        customerChannel: this.customer.customerChannel,
        customerName: this.customer.customerName,
        subscriptionType: this.customer.subscriptionType,
        markets: marketIds
      }

      let id = this.customer.customerId

      if (id) {
        request.customerId = id
      }

      this.model.loading = true
      let promise = id ? this.service.update(id, request) : this.service.create(request)

      promise.then((response) => {
        streetmetrics.stateManager.loadCustomers()
        this.model.loading = false
        this.yesHandler (request)
        this.model.toast.message = 'Customer saved'
      }).catch((error) => {
        console.log('error saving customer')
        console.log(error)
        this.errorMessage = error.message
        this.model.loading = false
      })
    },

    noHandler(e) {
      // hide the modal
      this.currentModal.hide()
    },
    yesHandler(customer) {
      // call the parent's "yesEvent"
      if (this.yesEvent) {
        this.yesEvent(customer)
      }
      // hide the modal
      this.noHandler()
    }
  }
}
</script>

<style>

</style>
