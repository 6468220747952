import RestService from './RestService';

export default class MultiplierService extends RestService {
  constructor() {
    super('multipliers');
  }

  list() {
    const url = this.baseUrl + '/v3/internal/multipliers';
    return this.executeGet(url);
  }

  add(data) {
    const url = this.baseUrl + '/v3/internal/multipliers';
    return this.create(data, url);
  }

  update(data) {
    const url = this.baseUrl + `/v3/internal/multipliers/${data.multiplerId}`;
    return super.update(
      data.multiplerId,
      { impMultiplier: data.impMultiplier },
      url,
    );
  }

  delete(multiplerId) {
    const url = this.baseUrl + `/v3/internal/multipliers/${multiplerId}`;
    return super.delete(multiplerId, url);
  }
}
