<template>
  <div>
    <div class="modal fade" id="confirmModal" tabindex="-1" role="dialog" aria-labelledby="confirmModalLabel" aria-hidden="true">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="confirmModalLabel">Confirm</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            {{ text }}
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" @click="yesHandler" >Yes</button>
            <button type="button" class="btn btn-light " @click="noHandler">No</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'ConfirmModal',
  props: {
    yesEvent: Function,
    text: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      model: streetmetrics.model,
      currentModal: null,
      modalElement: null,
    }
  },
  mounted() {
    this.modalElement = document.getElementById('confirmModal')
    this.currentModal = new bootstrap.Modal(this.modalElement)

    this.modalElement.addEventListener('hidden.bs.modal', () => {
      this.$emit('noEvent')
    })

    this.currentModal.show()
  },
  beforeDestroy() {
    this.modalElement.removeEventListener('hidden.bs.modal')
  },
  methods: {
    noHandler(e) {
      this.currentModal.hide()
    },
    yesHandler(e) {
      // call the parent's "yesEvent"
      if(this.yesEvent) {
        this.yesEvent()
      }
      // hide the modal
      this.noHandler()
    }
  }
}
</script>
