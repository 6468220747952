<template>
  <div>
    <apexchart :series="chartData" :options="chartOptions"></apexchart>
  </div>
</template>

<script>
export default {
  name: 'ApexLineChart',
  props: ['chartData', 'lineColors'],
  data () {
    return {
      model: streetmetrics.model,
      chartOptions: {
        chart: {
          type: 'line'
        },
        dataLabels: {
          enabled: false
        },
        tooltip: {
          enabled: true
        },
        colors: this.lineColors,
        legend: {
          show: true
        },
        stroke: {
          curve: 'smooth'
        },
        // plotOptions: {
        // }
      }
    }
  },
  mounted () {
  },
  methods: {
    importantMethodHere () {
    }
  }
}
</script>

<style>

</style>
