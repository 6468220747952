export default class StringUtil {
  toSnakeCase(string) {
    return string && string.trim() &&
    string.trim().match(/[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g)
      .map(x => x.toLowerCase())
      .join('_');
  }

  toUrlFriendlyId(string) {
    return string && string.trim() &&
    string.trim().match(/[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g)
      .map(x => x.toLowerCase())
      .join('-');
  }

  sortList(list) {
    list.sort(function(object1, object2) {
      let fieldA = object1.trim().toUpperCase();
      let fieldB = object2.trim().toUpperCase();

      if(fieldA === fieldB) {
        return 0
      }

      return fieldA < fieldB ? -1 : 1
    })
  }

  sortByString(list, fieldName) {
    list.sort(function(object1, object2) {
      let fieldA = object1[fieldName].trim().toUpperCase();
      let fieldB = object2[fieldName].trim().toUpperCase();

      if(fieldA === fieldB) {
        return 0
      }

      return fieldA < fieldB ? -1 : 1
    })
  }

  formatDuration(duration) {
    const totalSeconds = Math.floor(duration / 1000);
    const seconds = totalSeconds % 60;
    const totalMinutes = Math.floor(totalSeconds / 60);
    const minutes = totalMinutes % 60;
    const hours = Math.floor(totalMinutes / 60);

    if (hours > 0) {
      return `${hours}h ${minutes}m ${seconds}s`;
    } else if (minutes > 0) {
      return `${minutes}m ${seconds}s`;
    }

    return `${seconds}s`;
  }

  isValidDateString(dateString) {
    const tokens = dateString.split('-')

    if(tokens.length !== 3) {
      return false
    }

    if(tokens[0].length !== 4 || tokens[1].length !== 2 || tokens[2].length !== 2) {
      return false
    }

    if(isNaN(tokens[0]) || isNaN(tokens[1]) || isNaN(tokens[2])) {
      return false
    }

    return true
  }
}
