<template>
  <div class="content-container flex-column-center">
    <div class="col-12 col-md-4">
      <div class="mb-3 text-center">
        <streetmetrics-logo></streetmetrics-logo>
      </div>
      <div class="card shadow">
        <div class="card-body">
          <div v-if="errorMessage" class="alert alert-warning" role="alert">
            {{errorMessage}}
          </div>
          <form>
            <div class="mb-3">
              <label for="emailInput" class="form-label">Email</label>
              <input v-model="user.email"  type="email" class="form-control" id="emailInput" aria-describedby="emailHelp">
            </div>
            <div class="mb-3">
              <label for="passwordInput" class="form-label">Password</label>
              <input v-model="user.password" type="password" class="form-control" id="passwordInput">
            </div>
            <button type="submit" class="btn btn-primary btn-wide" @click.prevent="onSigninClick">Login</button>
          </form>

          <div class="mt-3">
            <a href="https://streetmetrics.com">Don't have an account? Get in touch with us.</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import UserService from "../services/UserService";
import StreetmetricsLogo from "../components/StreetmetricsLogo";

export default {
  name: 'Login',
  components: {StreetmetricsLogo},
  data() {
    return {
      // eslint-disable-next-line no-undef
      model: streetmetrics.model,
      user: {
        email: '',
        password: ''
      },
      errorMessage: null
    }
  },

  methods: {
    onSigninClick() {
      this.model.loading = true
      this.errorMessage = null
      let service = new UserService()
      service.login(this.user.email, this.user.password).then((response) => {
        this.model.authToken = response.data.token
        window.localStorage.setItem('auth.token', this.model.authToken)
        this.model.user = response.data.user
        // eslint-disable-next-line no-undef
        streetmetrics.stateManager.init()
        this.model.loading = false
        this.$router.push({name: 'dashboard'})
      }).catch((error) => {
        console.log(error)
        this.errorMessage = error.message
        this.model.loading = false
      })
    }
  }
}
</script>
