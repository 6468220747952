export default class ArrayUtil {

  find(list, itemId, fieldName) {
    for( let i = 0; i < list.length; i++ ) {
      const item = list[i]
      if(item[fieldName] === itemId) {
        return item
      }
    }
  }

  sort(list, fieldName) {
    list.sort(function(object1, object2) {
      let fieldA = object1[fieldName];
      let fieldB = object2[fieldName];

      if(fieldA === fieldB) {
        return 0
      }

      return fieldA > fieldB ? -1 : 1
    })
  }

  groupBy(list, fieldName) {
    const map = {}

    for( let i = 0; i < list.length; i++ ) {
      const item = list[i]
      const fieldValue = item[fieldName]
      if(!map[fieldValue]) {
        map[fieldValue] = []
      }

      map[fieldValue].push(item)
    }

    return map
  }

  updateItem(list, item, fieldName) {
    for( let i = 0; i < list.length; i++ ) {
      if(item[fieldName] === list[i][fieldName]) {
        list[i] = item
        break
      }
    }
  }
}
