<template>
  <div class="pt-4 px-4">

    <div class="mb-3 d-flex justify-content-between">
      <div class="d-flex align-items-center">
        <h2>Stationary Campaigns</h2>
      </div>
    </div>

    <div v-if="stationaryCampaigns.length > 0" class="table-responsive table-container scrollable-container mt-2">
      <table class="table">
        <thead class="bg-gray-500 text-uppercase">
        <tr>
          <th>Campaign</th>
          <th>Customer</th>
          <th></th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="campaign in stationaryCampaigns">
          <td>{{campaign.campaignRef}}</td>
          <td>{{campaign.customerName}}</td>
          <td>
            <router-link :to="{ name: 'run-campaign', params: {id: campaign.campaignId} }" class="btn btn-sm btn-primary">
              Run
            </router-link>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import CampaignService from "@/services/CampaignService";

export default {
  name: 'Stationary',
  props: ['prop1', 'prop2'],
  data () {
    return {
      model: streetmetrics.model
    }
  },
  computed: {
    stationaryCampaigns() {
      return this.model.campaigns.filter( (campaign) => {
        if(campaign.adGroupsMetaData && campaign.adGroupsMetaData.length > 0) {
          for (let i = 0; i < campaign.adGroupsMetaData.length; i++) {
            const adGroupMetaDatum = campaign.adGroupsMetaData[i];
            if(adGroupMetaDatum['inventoryType'] === 'STATIONARY') {
              return true
            }
          }
        }

        return false
      })
    }
  },
  mounted () {
  },
}
</script>
