<template>
  <div class="m-3 pe-3">

    <div class="my-4 d-flex justify-content-between">
      <div class="d-flex align-items-center">
      </div>

      <div>
        <button class="btn btn-secondary" @click="onNewPixelClick">New Pixel</button>
      </div>
    </div>

    <div class="card mb-3">
      <div class="card-body">

        <div class="row w-100-pct">
          <div class="mb-3 col-12 col-md-4">
            <label class="form-label">Customer</label>
            <select class="form-select" v-model="customerId" :disabled="!hasCustomers">
              <option v-for="customer in model.customers" :value="customer.customerId">{{ customer.customerName }}
              </option>
            </select>
          </div>

          <div class="mb-3 col-12 col-md-4 px-2">
            <label class="form-label">Campaign</label>
            <select class="form-select" v-model="campaignId" @change="onCampaignSelected" :disabled="!hasCampaigns">
              <option v-for="campaign in availableCampaigns" :value="campaign.campaignId">{{ campaign.campaignRef }}
              </option>
            </select>
          </div>

        </div>

        <div v-if="campaignSelected" class="my-1">
          <button class="btn btn-secondary" @click="onClearFilterClick">Clear Filters</button>
        </div>

      </div>
    </div>

    <h5 v-if="!campaignSelected" class="card-title text-info my-2">Pixels without a Campaign</h5>

    <div class="table-responsive table-container scrollable-container mt-2">
      <table class="table">
        <thead class="bg-gray-500 text-uppercase">
        <tr>
          <th>Name</th>
          <th></th>
          <th></th>
          <th></th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="pixel in pixels">
          <td>
            <div>
              <div>
                {{ pixel.metaData.page }}
              </div>
              <div v-if="pixel.metaData.displayName" class="font-sm gray-600">Name: {{ pixel.metaData.displayName }}</div>
              <div v-if="pixel.pixelUUID" class="font-sm gray-500">{{ pixel.pixelUUID }}</div>
              <div v-else class="font-sm gray-500">ID {{ pixel.pixelId }}</div>
            </div>
          </td>
          <td>
            <a href="javascript:void(0)" @click="onEditClick(pixel)" class="text-info">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                   stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                   class="feather feather-edit small-icon">
                <path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7"></path>
                <path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z"></path>
              </svg>
            </a>
          </td>
          <td>
            <a v-if="pixel.pixelUUID" href="javascript:void(0)" @click="onCopyClick(pixel)" class="text-info">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                   stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                   class="feather feather-copy small-icon">
                <rect x="9" y="9" width="13" height="13" rx="2" ry="2"></rect>
                <path d="M5 15H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h9a2 2 0 0 1 2 2v1"></path>
              </svg>
            </a>
          </td>
          <td>
            <button @click="onCheckPixelCountClick(pixel)" class="btn btn-sm btn-light">Check</button>
          </td>
        </tr>
        </tbody>
      </table>
    </div>

    <pixel-form-modal v-if="showModal" @noEvent="showModal = false" :pixel="currentPixel"
                      :yes-event="onPixelSave"></pixel-form-modal>

  </div>
</template>

<script>


import PixelService from "../services/PixelService";
import PixelFormModal from "../components/PixelFormModal";
import {v4 as uuidv4} from "uuid";
import AppUtil from "../utils/AppUtil";

export default {
  name: 'Pixels',
  components: {PixelFormModal},
  data() {
    return {
      // eslint-disable-next-line no-undef
      model: streetmetrics.model,
      service: new PixelService(),
      pixels: [],
      showModal: false,
      currentPixel: {
        pixelId: null,
        pixelUUID: uuidv4(),
        campaign: null,
        displayName: '',
        page: '',
        funnelStage: '',
        url: ''
      },
      onPixelSave: (pixelId) => {
      },
      customerId: null,
      campaignId: null,
    }
  },

  computed: {
    hasCustomers() {
      return !_.isEmpty(this.model.customers)
    },
    hasCampaigns() {
    return !_.isEmpty(this.availableCampaigns)
  },
    availableCampaigns() {
      if (this.customerId) {
        return this.model.campaigns.filter(campaign => (campaign.customerId === this.customerId))
      }

      return []
    },
    campaignSelected() {
      return this.customerId && this.campaignId ? true : false
    }
  },
  mounted() {
    this.loadPixels()
  },
  methods: {
    onCampaignSelected() {
      if (this.customerId && this.campaignId) {
        this.loadCampaignPixels()
      } else {
        this.loadPixels()
      }
    },
    onClearFilterClick() {
      this.customerId = this.campaignId = null
      this.loadPixels()
    },
    loadPixels() {
      this.model.loading = true
      this.service.getPixelsMissingCampaign().then((response) => {
        this.model.loading = false
        this.pixels = response.data
      }).catch((error) => {
        console.log('Error loading pixels wo/ campaigns')
        console.log(error)
        this.model.loading = false
      })
    },
    loadCampaignPixels() {
      this.model.loading = true
      this.service.getCampaignPixels(this.campaignId).then((response) => {
        this.model.loading = false
        this.pixels = response.data
        console.log('Loaded pixels with a campaign: ' + this.campaignId)
      }).catch((error) => {
        console.log('Error loading pixels with a campaign: ' + this.campaignId)
        console.log(error)
        this.model.loading = false
      })
    },
    onCheckPixelCountClick(pixel) {
      this.model.loading = true

      const appUtil = new AppUtil()
      const startAndEndDates = appUtil.getFilterDates('last3')
      const formattedDates = appUtil.formatStartAndEndDates(startAndEndDates.start, startAndEndDates.end)

      this.service.loadPixelRequests(pixel.pixelId, formattedDates.start, formattedDates.end).then((response) => {
        this.model.loading = false
        let triggerCount = response.data.length

        if(triggerCount > 0) {
          this.model.toast.background = 'bg-green'
          this.model.toast.message = "This pixel has fired in the last three days."
        }
        else {
          this.model.toast.background = 'bg-orange'
          this.model.toast.message = "This pixel has not fired in the last three days."
        }
      }).catch((error) => {
        console.log('Error loading pixel data with ID: ' + pixel.pixelId)
        console.log(error)
        this.model.loading = false
      })
    },
    onCopyClick(pixel) {
      const link = "https://pixel.streetmetrics.io/pixel/" + pixel.pixelUUID
      navigator.clipboard.writeText(link).then(() => {
        this.model.toast.message = "Pixel link copied to clipboard!"
      });
    },
    onNewPixelClick() {
      this.currentPixel = {
        pixelId: null,
        pixelUUID: uuidv4(),
        campaign: null,
        displayName: '',
        page: '',
        funnelStage: '',
        url: ''
      }
      this.showModal = true

      this.onPixelSave = (pixelId) => {
        const newPixel = {
          pixelId: pixelId,
          pixelUUID: this.currentPixel.pixelUUID,
          metaData: {
            page: this.currentPixel.page,
            displayName: this.currentPixel.displayName,
            funnelStage: this.currentPixel.funnelStage,
            url: this.currentPixel.url
          },
          requestcount: 0
        }

        this.pixels.push(newPixel)
      }
    },
    onEditClick(pixel) {
      console.log('onEditClick: ', pixel)
      this.currentPixel = {
        pixelId: pixel.pixelId,
        pixelUUID: pixel.pixelUUID,
        campaign: pixel.campaign,
        displayName: pixel.metaData.displayName,
        page: pixel.metaData.page,
        funnelStage: pixel.metaData.funnelStage,
        url: pixel.metaData.url
      }
      console.log('this.currentPixel: ', this.currentPixel)

      this.showModal = true

      this.onPixelSave = (pixelId) => {
        this.loadPixels()
      }
    }
  }
}
</script>

<style>

</style>
