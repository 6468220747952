<template>
  <div class="pt-4 px-4">
    <h3>Top Markets</h3>

    <div class="d-flex flex-wrap my-2" v-if="model.jobs.length">
      <market-card
        v-for="market in importantMarkets"
        :key="market.marketHash"
        :market="market"
      ></market-card>
    </div>

    <div class="my-4 d-flex align-items-center">
      <div class="d-flex align-items-center">
        <h3>Processing List</h3>
      </div>

      <div class="ms-5">Filters</div>

      <div class="mx-2">
        <select class="form-select" v-model="typeFilter">
          <option value="None">None</option>
          <option value="IMPRESSIONS">Impressions</option>
          <option value="DEMOGRAPHICS">Demographics</option>
        </select>
      </div>

      <!-- Dropdown filter for markets -->
      <div class="mx-2">
        <select class="form-select" v-model="marketFilter">
          <option value="All">All Markets</option>
          <option v-for="market in markets" :key="market">
            {{ market }}
          </option>
        </select>
      </div>
    </div>

    <div v-if="model.jobs.length > 0" class="table-responsive table-container scrollable-container">
      <table class="table">
        <thead class="bg-gray-500 text-uppercase">
          <tr>
            <th>ID</th>
            <th>Market</th>
            <th>Job Class</th>
            <th>Impressions</th>
            <th>Demographics</th>
            <th>Created</th>
            <th>View Jobs</th>
<!--            <th>Success</th>-->
<!--            <th>Start</th>-->
<!--            <th>End</th>-->
          </tr>
        </thead>
        <tbody>
          <tr v-for="job in availableJobs" :key="job.jobId">
            <td>{{ job.jobId }}</td>
            <td>{{ job.jobAOI.marketName }}</td>
            <td>{{ job.jobClass }}</td>

            <td>
              <div v-if="getAudit(job, 'IMPRESSIONS')">
                Yes
              </div>
              <div v-else>
                No
              </div>
            </td>

            <td>
              <div v-if="getAudit(job, 'DEMOGRAPHICS')">
                Yes
              </div>
              <div v-else>
                No
              </div>
            </td>


            <td>{{ formatDate(job.createdAt) }}</td>
            <td><a href="javascript:void(0)" @click="onMarketClick(job.jobAOI.marketName)">Open</a></td>
<!--            <td>-->
<!--              <span v-if="hasFirstJobAudit(job) && job.audits[0].jobType">-->
<!--                {{ job.audits[0].jobType }}-->
<!--              </span>-->
<!--            </td>-->
<!--            <td>-->
<!--              <span v-if="hasFirstJobAudit(job) && job.audits[0].jobStatus">-->
<!--                {{ job.audits[0].jobStatus.jobSuccess }}-->
<!--              </span>-->
<!--              <span v-else>&#45;&#45;</span>-->
<!--            </td>-->
<!--            <td>-->
<!--              <div v-if="isMetricsPresent(job)">-->
<!--                {{ getDateLabel(job.audits[0].jobConfig.startTime) }}-->
<!--              </div>-->
<!--            </td>-->
<!--            <td>-->
<!--              <div v-if="isMetricsPresent(job)">-->
<!--                {{ getDateLabel(job.audits[0].jobConfig.endTime) }}-->
<!--              </div>-->
<!--            </td>-->
          </tr>
        </tbody>
      </table>
    </div>
  </div>
<!--  <vue-final-modal-->
<!--    classes="modal-container"-->
<!--    content-class="modal-content"-->
<!--    v-model="shouldShowAuditModal"-->
<!--  >-->
<!--    <span class="modal__title"></span>-->
<!--    <div class="modal__content">-->
<!--      <job-details :job="selectedJob"></job-details>-->
<!--    </div>-->
<!--  </vue-final-modal>-->

  <jobs-by-market-modal v-if="showMarketModal" @noEvent="showMarketModal = false" :market="currentMarket"></jobs-by-market-modal>
</template>

<script>
import JobService from '../services/JobService';
import streetmetrics from '@/model';
import MarketCard from '@/views/jobs/MarketCard';
import JobDetails from '@/views/jobs/JobDetails';
import {DateTime} from "luxon";
import JobsByMarketModal from "@/views/jobs/JobsByMarketModal";

export default {
  name: 'AthenaJobs',
  components: {JobsByMarketModal, MarketCard, JobDetails },
  data() {
    return {
      model: streetmetrics.model,
      typeFilter: 'None',
      shouldShowAuditModal: false,
      marketFilter: 'All', // Initialize the market filter
      importantMarkets: [],
      markets: [],
      selectedJob: null,
      showMarketModal: false,
      currentMarket: null
    };
  },
  computed: {
    availableJobs() {
      if (this.typeFilter === 'None' && this.marketFilter === 'All') {
        return this.model.jobs;
      }

      return this.model.jobs.filter((job) => {
        const isSelectedMarket = job.jobAOI.marketName === this.marketFilter;
        return (
          isSelectedMarket &&
          (this.typeFilter === 'None' || job.audits.some((j) => j.jobType === this.typeFilter))
        );
      });
    },
  },
  mounted() {
    streetmetrics.model.areMarketsLoaded.then(() => {
      this.importantMarkets = streetmetrics.utils.app.getImportantMarkets();
      if (this.model.jobs.length === 0) {
        this.loadJobs();
      }
    });
  },

  methods: {
    // openAuditModal(job) {
    //   this.selectedJob = job;
    //   this.shouldShowAuditModal = true;
    // },
    loadJobs() {
      this.model.loading = true;
      let service = new JobService();
      service
        .getProcessingJobs()
        .then((response) => {
          this.model.loading = false;
          this.model.jobs = response.data;
          this.markets = this.model.jobs.map((job) => {
            return job.jobAOI.marketName;
          });
        })
        .catch((error) => {
          console.log('Error loading processing jobs');
          console.log(error);
          this.model.loading = false;
        });
    },
    getDateLabel(dateTime) {
      const dateFormatOptions = { year: 'numeric', month: 'short', day: 'numeric' };
      const jobDate = new Date(dateTime * 1000);
      return (
        jobDate.toLocaleDateString('en-us', dateFormatOptions) + ', ' + jobDate.toLocaleTimeString()
      );
    },
    formatDate(dateTime) {
      let jobDate = DateTime.fromISO(dateTime);
      return jobDate.toFormat('MM-dd-yyyy HH:mm:ss a');
    },
    getAudit(job, auditType) {
      let auditList = job.audits.filter(jobAudit => jobAudit.jobType === auditType)

      if(auditList.length > 0) {
        return auditList[0]
      }

      return null
    },
    onMarketClick(marketName) {
      this.currentMarket = this.model.markets.find( (market) => market.marketName === marketName)
      this.showMarketModal = true
    }
  },
};
</script>
